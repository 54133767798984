import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import Row from "../../components/Row";
import CatOverviewElement from "./CatOverviewElement";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { send } from "../../utils/transactions";
import airdropClaimAll from "./../../assets/bank/airdrop_claim_all.png";
import logo111 from "../../assets/ethmint/goldenFangsLogo.png";
import logo222 from "../../assets/mint222/222logo.png";
import bckLogo from "../../assets/logo/bck.png";
import dropdown from "../../assets/catoverview/dropdown.png";

const CatOverview: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [loadingActive, setLoadingActive] = useState("");
  const [hasGenesis, setHasGenesis] = useState(false);
  const [hasFamily, setHasFamily] = useState(false);
  const [hasEthFactory, setHasEthFactory] = useState(false);
  const [genesisThumbnails, setGenesisThumbnails] = useState([]);
  const [factoryThumbnails, setFactoryThumbnails] = useState([]);
  const [ethFactoryThumbnails, setEthFactoryThumbnails] = useState([]);
  const [filterOptions, setFilterOptions] = useState("all");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [claimingActive, setClaimingActive] = useState(false);
  const [airdropClaimable, setAirdropClaimable] = useState([]);
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const openCustomize = async (id: number) => {
    props.openCustomize(id);
  };

  const editClaimingActive = (active: boolean) => {
    setClaimingActive(active);
  };

  const getNftBalances = async (
    genesisLionContract: any,
    factoryContract: any,
    ethFactoryContract: any
  ) => {
    const genesisAmountNft = genesisLionContract.balanceOf(
      walletContext.activeWallet.address
    );
    const factoryAmountNft = factoryContract.balanceOf(
      walletContext.activeWallet.address
    );
    const ethFactoryAmountNft = ethFactoryContract.balanceOf(
      walletContext.activeWallet.address
    );

    return Promise.all([
      genesisAmountNft,
      factoryAmountNft,
      ethFactoryAmountNft,
    ]).then((amounts) => amounts.map((amount) => parseInt(amount)));
  };

  const checkAirdropClaimable = async () => {
    const airdropContract = await walletContext.activeWallet.contracts.get(
      "airdropCall"
    );
    const bcklContract = await walletContext.activeWallet.contracts.get(
      "bcklCall"
    );
    // find amount of nfts owned
    const amountNft = await bcklContract
      .balanceOf(walletContext.activeWallet.address)
      .then((result: any) => parseInt(result));
    // initiate an empty data array
    let dataArray = [];
    // for each nft find the tokenId
    for (let i = 0; i < amountNft; i++) {
      let tokenId = await bcklContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        i
      );
      // check if the airdrop has been claimed per tokenId
      const getHasClaimed = await airdropContract.hasClaimed(tokenId);
      // if the airdrop has not been claimed add it to the dataArray
      if (!getHasClaimed) {
        dataArray.push(tokenId);
      }
    }
    // set the array as claimable
    setAirdropClaimable(dataArray);
  };

  const loadGenesisElements = async (
    genesisLionContract: any,
    genesisAmountNft: number
  ) => {
    await checkAirdropClaimable();
    if (genesisAmountNft > 0) setGenesisThumbnails([]);
    for (let i = genesisAmountNft - 1; i >= 0; i--) {
      const location = await genesisLionContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        i
      );
      const attributeData = await fetch(
        "https://heuristic-fermat-c9d2a8.netlify.app/api/attributes/" + location
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      const element = (
        <CatOverviewElement
          series={"genesis"}
          attributes={attributeData}
          updateClaimable={checkAirdropClaimable}
          claimingActive={editClaimingActive}
          keyValue={"genesis" + attributeData.id}
        />
      );
      setGenesisThumbnails((prevState) => [...prevState, element]);
    }
  };

  const loadFactoryElements = async (
    factoryContract: any,
    factoryAmountNft: number
  ) => {
    if (factoryAmountNft > 0) setFactoryThumbnails([]);
    for (let i = factoryAmountNft - 1; i >= 0; i--) {
      let location = await factoryContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        i
      );
      const attributeData = await fetch(
        "https://heuristic-fermat-c9d2a8.netlify.app/api/nft/twohundredtwentytwo/" +
          location
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      const element = (
        <CatOverviewElement
          series={"222"}
          attributes={attributeData}
          openCustomize={openCustomize}
          keyValue={"222" + attributeData.id}
        />
      );
      setFactoryThumbnails((prevState) => [...prevState, element]);
    }
  };

  const loadEthFactoryElements = async (
    ethFactoryContract: any,
    ethFactoryAmountNft: number
  ) => {
    setEthFactoryThumbnails([]);

    for (let i = ethFactoryAmountNft - 1; i >= 0; i--) {
      let location = await ethFactoryContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        i
      );
      const attributeData = await fetch(
        `https://heuristic-fermat-c9d2a8.netlify.app/api/nft/goldenfangs${
          process.env.REACT_APP_USE === "testnet" ? "test" : ""
        }/` + location
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });

      const element = (
        <CatOverviewElement
          series={"eth222"}
          attributes={attributeData}
          openCustomize={openCustomize}
          keyValue={"eth" + attributeData.id}
        />
      );

      setEthFactoryThumbnails((p) => [...p, element]);
    }
  };

  const getThumbnails = async () => {
    const ethFactoryContract = walletContext.activeWallet.contracts.get(
      "bckEthFactoryCall"
    );
    const factoryContract = walletContext.activeWallet.contracts.get(
      "bckFactoryCall"
    );
    const genesisLionContract = walletContext.activeWallet.contracts.get(
      "bcklCall"
    );

    const [
      genesisAmountNft,
      factoryAmountNft,
      ethFactoryAmountNft,
    ] = await getNftBalances(
      genesisLionContract,
      factoryContract,
      ethFactoryContract
    );

    // if all are 0 it shows you do not have any Lions yet...
    if (!genesisAmountNft && !factoryAmountNft && !ethFactoryAmountNft) {
      const element = (
        <Row
          key={"element-no-big-cats"}
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "40px",
            height: "360px",
          }}
        >
          <StyledNoImg />
          <EmptyBoxNoLion>You do not have any Big Cats yet</EmptyBoxNoLion>
        </Row>
      );
      return setGenesisThumbnails([element]);
    }

    setHasEthFactory(ethFactoryAmountNft > 0);
    setHasFamily(factoryAmountNft > 0);
    setHasGenesis(genesisAmountNft > 0);

    if (ethFactoryAmountNft > 0) {
      setLoadingActive("Golden Fangs");
      await loadEthFactoryElements(ethFactoryContract, ethFactoryAmountNft);
    }
    if (factoryAmountNft > 0) {
      setLoadingActive("The Family");
      await loadFactoryElements(factoryContract, factoryAmountNft);
    }
    if (genesisAmountNft > 0) {
      setLoadingActive("Genesis");
      await loadGenesisElements(genesisLionContract, genesisAmountNft);
    }

    setLoadingActive("");
  };

  const claimAllAirdrops = async () => {
    const airdropContract = walletContext.activeWallet.contracts.get("airdrop");
    const hash = await send(
      walletContext.activeWallet.provider,
      () => airdropContract.claimAll(airdropClaimable),
      dispatchTx
    );
    setTxHash(hash);
  };

  // on address change empty all thumbnails and get the new ones.
  useEffect(() => {
    if (
      walletContext.activeWallet.address &&
      walletContext.activeWallet.contracts
    ) {
      setFilterOptions("all");
      setGenesisThumbnails([]);
      setFactoryThumbnails([]);
      setEthFactoryThumbnails([]);
      getThumbnails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    walletContext.activeWallet.address,
    walletContext.activeWallet.contracts,
  ]);

  // verander als er meer functies op de pagina komen
  // after transaction, which currently can only be claim airdrop, reload all genesis thumbnails and claimables
  useEffect(() => {
    if (tx?.status === "completed") {
      setAirdropClaimable([]);
      setGenesisThumbnails([]);
      getThumbnails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tx]);

  return (
    <Column
      style={{
        position: "relative",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      {((hasGenesis && hasFamily) ||
        (hasGenesis && hasEthFactory) ||
        (hasFamily && hasEthFactory)) && (
        <div style={{ position: "absolute", top: "-140px", right: "0px" }}>
          <DropDownBox onClick={() => setDropdownOpen(!dropdownOpen)}>
            <DropDownFilterName>
              {filterOptions === "all"
                ? "ALL CATS"
                : filterOptions === "genesis"
                ? "GENESIS CATS"
                : filterOptions === "family"
                ? "FAMILY CATS"
                : "ETH CATS"}
            </DropDownFilterName>
            <DropDownV src={dropdown} />
            {dropdownOpen && (
              <DropDownOptionContainer>
                {filterOptions !== "all" && (
                  <DropDownOptionBox
                    onClick={() => {
                      setFilterOptions("all");
                      setDropdownOpen(false);
                    }}
                  >
                    <DropDownOptionName>ALL CATS</DropDownOptionName>
                  </DropDownOptionBox>
                )}

                {filterOptions !== "genesis" && hasGenesis && (
                  <DropDownOptionBox
                    onClick={() => {
                      setFilterOptions("genesis");
                      setDropdownOpen(false);
                    }}
                  >
                    <DropDownOptionName>GENESIS CATS</DropDownOptionName>
                  </DropDownOptionBox>
                )}

                {filterOptions !== "family" && hasFamily && (
                  <DropDownOptionBox
                    onClick={() => {
                      setFilterOptions("family");
                      setDropdownOpen(false);
                    }}
                  >
                    <DropDownOptionName>FAMILY CATS</DropDownOptionName>
                  </DropDownOptionBox>
                )}
                {filterOptions !== "eth" && hasEthFactory && (
                  <DropDownOptionBox
                    onClick={() => {
                      setFilterOptions("eth");
                      setDropdownOpen(false);
                    }}
                  >
                    <DropDownOptionName>ETH CATS</DropDownOptionName>
                  </DropDownOptionBox>
                )}
              </DropDownOptionContainer>
            )}
          </DropDownBox>
        </div>
      )}

      {(filterOptions === "all" || filterOptions === "eth") &&
        ethFactoryThumbnails}
      {(filterOptions === "all" || filterOptions === "family") &&
        factoryThumbnails}
      {(filterOptions === "all" || filterOptions === "genesis") &&
        genesisThumbnails}
      {loadingActive !== "" && (
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "40px",
            height: "360px",
          }}
        >
          <StyledNoImg flickering={true}>Loading...</StyledNoImg>
          <EmptyBoxLoading>
            {loadingActive === "Golden Fangs" && <Logo111 src={logo111} />}
            {loadingActive === "The Family" && <Logo222 src={logo222} />}
            {loadingActive === "Genesis" && <LogoBck src={bckLogo} />}
          </EmptyBoxLoading>
        </Row>
      )}
      {airdropClaimable.length > 1 && (
        <ClaimAllLink
          disabled={tx?.status === "pending" || claimingActive}
          src={airdropClaimAll}
          onClick={() => claimAllAirdrops()}
        />
      )}
    </Column>
  );
};

const DropDownBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 158px;
  height: 12px;
  padding: 5px 8px;
  margin-right: 17.5vw;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  border: 1px solid #f98c1f;
  cursor: pointer;
`;

const DropDownFilterName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  width: 150px;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #f98c1f;
  margin-top: 3px;
  text-align: left;
`;

const DropDownV = styled.img`
  width: 8px;
  height: 4px;
  margin-top: -1px;
  color: #f98c1f;
`;

const DropDownOptionContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 0px;
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 80px;
`;

const DropDownOptionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 158px;
  height: 14px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid #f98c1f;
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: pointer;
`;
const DropDownOptionName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  width: 150px;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #f98c1f;
  margin-top: 3px;
  text-align: left;
`;

const ClaimAllLink = styled.img<{
  disabled?: boolean;
}>`
  height: 50px;
  width: 240px;
  margin-left: 58px;
  border: ${(props) =>
    props.disabled
      ? "2px solid rgba(249, 140, 31, 0.5)"
      : "2px solid rgba(249, 140, 31, 1)"};
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0px 0px 20px #f98c1f"};
  }
`;

const Logo111 = styled.img`
  position: absolute;
  top: 10%;
  left: 15.25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 80%;
  border-radius: 16px;
  opacity: 0.175;
`;

const Logo222 = styled.img`
  position: absolute;
  top: 25%;
  left: 7.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35vw;
  height: 50%;
  border-radius: 16px;
  opacity: 0.1;
`;

const LogoBck = styled.img`
  position: absolute;
  top: 30%;
  left: 19.25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 40%;
  border-radius: 16px;
  opacity: 0.175;
`;

const StyledNoImg = styled.div<{
  flickering?: boolean;
}>`
  animation: ${(props) => (props.flickering ? "flicker 2s infinite" : "none")};
  flex-direction: column;
  text-align: center;
  width: 360px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  background-color: #40364a;
  padding-top: 160px;
  margin-right: 40px;
  border-radius: 16px;
`;

const EmptyBoxNoLion = styled.div`
  flex-direction: column;
  text-align: center;
  width: 45.5vw;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  background-color: #40364a;
  border-radius: 16px;
  padding-top: 160px;
  box-sizing: border-box;
`;

const EmptyBoxLoading = styled.div`
  position: relative;
  animation: flicker 2s infinite;
  flex-direction: column;
  text-align: center;
  width: 45.5vw;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  background-color: #40364a;
  border-radius: 16px;
  box-sizing: border-box;
`;

export default CatOverview;
