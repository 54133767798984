import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Footer from "./../containers/Footer/Footer";
import General from "../containers/Info/General";
import Roadmap from "../containers/Info/Roadmap";
import TokenPage from "../containers/Info/TokenPage";
import Column from "./../components/Column";
import Scrollbar from "./../components/Scrollbar";

const Info: React.FC<any> = (props: any) => {
  const [subMenu, setSubMenu] = useState("general");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Column style={{ height: "100%" }}>
      <SubMenu loaded={loaded}>
        <SubMenuLink
          current={subMenu === "general"}
          onClick={() => setSubMenu("general")}
        >
          GENERAL
        </SubMenuLink>
        <SubMenuLink
          current={subMenu === "roadmap"}
          onClick={() => setSubMenu("roadmap")}
        >
          ROADMAP
        </SubMenuLink>
        <SubMenuLink
          current={subMenu === "token"}
          onClick={() => setSubMenu("token")}
          style={{ marginRight: "80px" }}
        >
          TOKEN
        </SubMenuLink>
      </SubMenu>
      <Scrollbar
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            height: "100%",
            backgroundColor: "#261B32",
          }}
        >
          {subMenu === "general" && (
            <Column
              style={{
                alignItems: "center",
                marginBottom: "calc(10vh - 40px)",
              }}
            >
              <General
                tokenLink={() => setSubMenu("token")}
                updateBankMenu={props.updateBankMenu}
              />
            </Column>
          )}
          {subMenu === "roadmap" && (
            <Column
              style={{
                alignItems: "center",
                marginBottom: "calc(10vh - 40px)",
              }}
            >
              <Roadmap tokenLink={() => setSubMenu("token")} />
            </Column>
          )}
          {subMenu === "token" && (
            <div>
              <TokenPage />
            </div>
          )}
          <Footer />
        </div>
      </Scrollbar>
    </Column>
  );
};

const SubMenu = styled.div<{
  loaded: boolean;
}>`
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 56px;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  background-color: #2d2237;
  transition: opacity 0.1s ease-in-out;
`;

const SubMenuLink = styled.div<{
  current?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  height: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2px;
  margin-left: 24px;
  color: ${(props) => (props.current ? "#f58b2f" : "#bf6e32")};
  cursor: pointer;
  &:hover {
    color: #f58b2f;
  }
  transition: color 0.2s ease-in-out;
`;

export default Info;
