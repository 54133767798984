import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { BigNumber } from "@ethersproject/bignumber";
import { JsonRpcProvider } from "@ethersproject/providers";
import styled from "styled-components";
import useWalletProvider from "../hooks/useWalletProvider";
import useTransaction from "../hooks/useTransaction";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { weiToUnit } from "../utils/conversion";
import serializeNumber from "../utils/serializeNumber";
import { send } from "./../utils/transactions";
import whitelist from "../utils/whitelist";
import { switchToChain } from "../utils/web3";
import CountDown from "../containers/CountDown/CountDown";
import Footer from "./../containers/Footer/Footer";
import Column from "./../components/Column";
import Row from "./../components/Row";
import FlipCard from "../components/FlipCard";
import mintLogo from "../assets/ethmint/goldenFangsLogo.png";
import rarities from "../assets/ethmint/rarities.png";
import configEth from "../config/configEth";

const rpc = new JsonRpcProvider(configEth.rpc);

const Mint: React.FC<any> = (props: any) => {
  const history = useHistory();
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [loadWeb3Modal] = useWeb3Modal();
  const [showOneCard, setShowOneCard] = useState(true);
  const [lastCardId, setLastCardId] = useState(0);
  const [lastFiveCardIds, setLastFiveCardIds] = useState([0, 0, 0, 0, 0]);
  const [lastCardImage, setLastCardImage] = useState("");
  const [lastFiveCardImages, setLastFiveCardImages] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [lastCardRarity, setLastCardRarity] = useState(0);
  const [lastFiveCardRarities, setLastFiveCardRarities] = useState([
    0,
    0,
    0,
    0,
    0,
  ]);
  const [pageState, setPageState] = useState("preMint");
  const [showLeft, setShowLeft] = useState(false);
  const [showTransactionFailed, setShowTransactionFailed] = useState(false);
  const [amountOfImagesLoaded, setAmountOfImagesLoaded] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [checksDone, setChecksDone] = useState(false);
  const [chainIdWarning, setChainIdWarning] = useState(false);
  const [whitelistActive, setWhitelistActive] = useState(false);
  const [whitelistWarning, setWhitelistWarning] = useState(false);
  const [total222TigersLeft, setTotal222TigersLeft] = useState(0);
  const [countdownFinished, setCountdownFinished] = useState(false);
  const [mintingActive, setMintingActive] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const rarityNumber = (_name: string) => {
    if (_name === "Common") return 1;
    if (_name === "Uncommon") return 2;
    if (_name === "Rare") return 3;
    if (_name === "Legendary") return 4;
    if (_name === "Exotic/Honorary") return 5;
    // if (_name === "Honorary") return 5;
  };

  const clearCards = () => {
    setLastCardId(0);
    setLastCardImage("");
    setLastCardRarity(0);
    setLastFiveCardIds([0, 0, 0, 0, 0]);
    setLastFiveCardImages(["", "", "", "", ""]);
    setLastFiveCardRarities([0, 0, 0, 0, 0]);
  };

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const checkTotalTigersLeft = useCallback(
    async function () {
      if (walletContext.activeWallet.contracts.size === 0) return;
      const mint222Contract = walletContext.activeWallet.contracts.get(
        "bckEth222MinterCall"
      );
      const lionsLeftForSale = await mint222Contract.totalTokensLeft();
      setTotal222TigersLeft(lionsLeftForSale.toNumber());
      setShowLeft(true);
    },
    [tx, walletContext.activeWallet.contracts]
  );

  const checkTotalEth = useCallback(async () => {
    const ethBalance = walletContext.activeWallet.address
      ? await rpc.getBalance(walletContext.activeWallet.address)
      : BigNumber.from(0);
    await setEthBalance(weiToUnit(ethBalance));
  }, [tx, walletContext.activeWallet.address]);

  const checkWhiteListStatus = useCallback(() => {
    if (!walletContext.activeWallet.address) return;
    // const ethFactory = await walletContext.activeWallet.contracts.get(
    //   "bckEthFactoryCall"
    // );
    setWhitelistActive(false);
    setWhitelistWarning(false);
    // const onWhitelist = whitelist.includes(walletContext.activeWallet.address);
    // if not on whitelist, whitelistwarning
    // if (!onWhitelist) {
    //   setWhitelistWarning(true);
    //   return setChecksDone(true);
    // }
    // if (onWhitelist) {
    //   const amountOfNfts = await ethFactory.balanceOf(
    //     walletContext.activeWallet.address
    //   );
    //   // if on whitelist, but already has NFT whitelistwarning
    //   if (amountOfNfts > 0) {
    //     setWhitelistWarning(true);
    //     return setChecksDone(true);
    //   }
    // }
    return setChecksDone(true);
  }, [
    tx,
    walletContext.activeWallet.contracts,
    walletContext.activeWallet.address,
  ]);

  const mintTiger = async () => {
    setShowTransactionFailed(false);
    setMintingActive(true);
    clearCards();
    const mint222Contract = walletContext.activeWallet.contracts.get(
      "bckEth222Minter"
    );
    const mintPrice = await mint222Contract.mintPrice();
    const packPrice = await mint222Contract.packPrice();

    if (showOneCard) {
      try {
        const hash = await send(
          walletContext.activeWallet.provider,
          () =>
            mint222Contract.buyTiger({
              value: mintPrice,
              gasLimit: 500000,
            }),
          dispatchTx
        );
        setPageState("minting");
        setTxHash(hash);
      } catch (err) {
        console.error(err);
        setPageState("preMint");
        setMintingActive(false);
      }
    } else {
      try {
        const hash = await send(
          walletContext.activeWallet.provider,
          () =>
            mint222Contract.buyPack({
              value: packPrice,
              gasLimit: 1200000,
            }),
          dispatchTx
        );
        setPageState("minting");
        setTxHash(hash);
      } catch (err) {
        console.error(err);
        setPageState("preMint");
        setMintingActive(false);
      }
    }
  };

  const dataFetch = async (token: number) => {
    const returnData = await fetch(
      `https://bigcatklub.com/api/nft/goldenfangs${
        process.env.REACT_APP_USE === "testnet" ? "test" : ""
      }/` + token
    )
      .then((res) => {
        return res.json();
      })
      .then((jsonResult) => {
        return jsonResult;
      });
    return returnData;
  };

  const displayMintedCards = useCallback(async () => {
    const ethFactoryContract = walletContext.activeWallet.contracts.get(
      "bckEthFactoryCall"
    );
    const amountOfEthTigers = await ethFactoryContract.balanceOf(
      walletContext.activeWallet.address
    );
    if (showOneCard) {
      const tokenId = await ethFactoryContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        amountOfEthTigers - 1
      );
      const data = await dataFetch(tokenId);
      setLastCardRarity(rarityNumber(data.attributes[11].value));
      setLastCardImage(
        `https://${
          process.env.REACT_APP_USE === "testnet"
            ? "splendorous-figolla-904393.netlify.app"
            : "bigcatklub.com/"
        }/assets/goldenfangs/512/bck${serializeNumber(data.id)}.png`
      );
      setLastCardId(tokenId.toNumber());
    } else {
      const tokenArray = [];
      const imageArray = [];
      const rarityArray = [];
      for (let i = 0; i < 5; i++) {
        const tokenId = await ethFactoryContract.tokenOfOwnerByIndex(
          walletContext.activeWallet.address,
          amountOfEthTigers - (i + 1)
        );
        tokenArray[i] = tokenId.toNumber();
        const data = await dataFetch(tokenId);
        imageArray[i] = `https://${
          process.env.REACT_APP_USE === "testnet"
            ? "splendorous-figolla-904393.netlify.app"
            : "bigcatklub.com/"
        }/assets/goldenfangs/512/bck${serializeNumber(data.id)}.png`;
        rarityArray[i] = rarityNumber(data.attributes[11].value);
      }
      setLastFiveCardRarities(rarityArray);
      setLastFiveCardImages(imageArray);
      setLastFiveCardIds(tokenArray);
    }
  }, [
    showOneCard,
    walletContext.activeWallet.address,
    walletContext.activeWallet.contracts,
  ]);

  const MintButtonElement: React.FC<any> = () => {
    if (!walletContext.activeWallet.address) {
      return <MintButton onClick={() => loadWeb3Modal()}>CONNECT</MintButton>;
    }
    if (chainIdWarning) {
      return (
        <MintButton
          onClick={() =>
            switchToChain(
              walletContext.activeWallet.provider,
              parseInt(configEth.chainId)
            )
          }
        >
          SWITCH TO ETH
        </MintButton>
      );
    }
    if (pageState === "preMint" && checksDone && total222TigersLeft === 0) {
      return <MintButton disabled={true}>MINTED OUT</MintButton>;
    }
    if (
      pageState === "preMint" &&
      checksDone &&
      !showOneCard &&
      total222TigersLeft < 5
    ) {
      return (
        <MintButton disabled={true}>NOT ENOUGH TIGERS TO BUY PACK</MintButton>
      );
    }
    if (pageState === "preMint" && checksDone && whitelistWarning) {
      return <MintButton disabled={true}>NOT WHITELISTED</MintButton>;
    }
    if (
      pageState === "preMint" &&
      checksDone &&
      (showOneCard ? ethBalance < 0.111 : ethBalance < 0.5)
    ) {
      return <MintButton disabled={true}>NOT ENOUGH ETH</MintButton>;
    }
    if (pageState === "minting" || pageState === "loadingImages") {
      return (
        <MintButton
          className="mint-button"
          disabled={true}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            width: "200px",

            animation: "blink 2s infinite alternate",
          }}
        >
          <span></span>
        </MintButton>
      );
    }

    return (
      <MintButton
        disabled={!checksDone || mintingActive}
        onClick={() => mintTiger()}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "200px",
        }}
      >
        MINT x {showOneCard ? 1 : 5}
      </MintButton>
    );
  };

  const removeCountdown = () => {
    setCountdownFinished(true);
  };

  const setImageLoaded = () => {
    setPageState("imagesLoaded");
  };

  const checkAllImagesLoaded = () => {
    setAmountOfImagesLoaded((p) => {
      if (p + 1 === 5) {
        setPageState("imagesLoaded");
        return 0;
      } else {
        return p + 1;
      }
    });
  };

  useEffect(() => {
    if (walletContext.activeWallet.chainId !== configEth.supportedChains[0]) {
      setChainIdWarning(true);
    }
    if (walletContext.activeWallet.chainId === configEth.supportedChains[0]) {
      setChainIdWarning(false);
    }
  }, [walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (walletContext.activeWallet.address) {
      checkTotalEth();
      checkWhiteListStatus();
    }
  }, [
    walletContext.activeWallet.address,
    walletContext.activeWallet.contracts,
    checkTotalEth,
    checkWhiteListStatus,
    transaction,
  ]);

  useEffect(() => {
    checkTotalTigersLeft();
  }, [tx, checkTotalTigersLeft]);

  // start loading the data when the transaction is completed
  useEffect(() => {
    if (tx?.status === "completed" && tx?.transaction?.status === 1) {
      displayMintedCards();
    }
    if (tx?.status === "completed" && tx?.transaction?.status === 0) {
      setShowTransactionFailed(true);
      setMintingActive(false);
      setPageState("preMint");
    }
  }, [tx?.status, displayMintedCards]);

  // start loading images when the states have been filled
  useEffect(() => {
    if (
      tx?.status === "completed" &&
      lastCardId !== 0 &&
      lastCardImage !== "" &&
      lastCardRarity !== 0
    ) {
      setPageState("loadingImages");
      setMintingActive(false);
    }
  }, [lastCardId, lastCardRarity, tx?.status]);

  // start loading images when the states have been filled
  useEffect(() => {
    if (
      tx?.status === "completed" &&
      lastFiveCardImages[4] !== "" &&
      lastFiveCardIds[4] !== 0 &&
      lastFiveCardRarities[4] !== 0
    ) {
      setPageState("loadingImages");
      setMintingActive(false);
    }
  }, [lastFiveCardIds, lastFiveCardRarities, tx?.status]);

  return (
    <ContentAndFooterWrapper>
      <ContentWrapper>
        {!countdownFinished && <CountDown removeCountdown={removeCountdown} />}

        {countdownFinished && pageState !== "imagesLoaded" && (
          <Column
            style={{
              alignItems: "center",
              marginLeft: "8vw",
              marginRight: "8vw",
            }}
          >
            <Logo src={mintLogo} />
            <YellowLine>BCK GOLDEN FANGS COLLECTION</YellowLine>
            <PriceLine>0.{showOneCard ? 111 : 5} ETH</PriceLine>
            <MintButtonElement />
            {whitelistActive && (
              <WhiteLine top={true}>
                You can only buy once during whitelist
              </WhiteLine>
            )}
            {whitelistActive && (
              <WhiteLine>
                You can choose to buy 1 or buy 5 and get a guaranteed rare
              </WhiteLine>
            )}
            <MintAmountToggle
              disabled={mintingActive || pageState !== "preMint"}
              onClick={() => {
                if (!mintingActive && pageState === "preMint") {
                  setShowOneCard(!showOneCard);
                }
              }}
            >
              {!showOneCard ? "MINT x 1" : "MINT x 5 [GUARANTEED RARE]"}
            </MintAmountToggle>
            {(pageState === "minting" || pageState === "loadingImages") && (
              <RedLine>
                DO NOT REFRESH THIS PAGE BEFORE MINT IS REVEALED
              </RedLine>
            )}
            {showTransactionFailed && (
              <RedLine>
                Transaction failed, if you keep encountering problems contact us
                on{" "}
                <a
                  href="https://discord.gg/AyZUmG7Mkg"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "red" }}
                >
                  Discord
                </a>
              </RedLine>
            )}
            {showLeft &&
              pageState !== "minting" &&
              pageState !== "loadingImages" && (
                <GreenLine>{total222TigersLeft}/222 LEFT</GreenLine>
              )}
            <LoreContainer>
              <PinkLine>NEXT UP IN THE KLUB...</PinkLine>
              <PinkLine>
                INTRODUCING THE FEROCIOUS, THE MYSTERIOUS, THE NOTORIOUS - THE
                GOLDEN FANGS!
              </PinkLine>
              <PinkLine>
                This pack ain't your regular house cats - they're a crew of
                human-tiger hybrids,
              </PinkLine>
              <PinkLine>
                carving their territory right in the middle of the BCK universe.
              </PinkLine>
            </LoreContainer>
          </Column>
        )}
        {countdownFinished &&
          (pageState === "loadingImages" || pageState === "imagesLoaded") && (
            <DisplayCardContainer visible={pageState === "imagesLoaded"}>
              <img
                src={rarities}
                alt="rarities"
                style={{ width: "400px", marginBottom: "12px" }}
              />
              {showOneCard && (
                <FlipCard
                  rarity={lastCardRarity}
                  image={lastCardImage}
                  tokenId={lastCardId}
                  imageLoaded={setImageLoaded}
                />
              )}
              {!showOneCard && (
                <Row>
                  <FlipCard
                    rarity={lastFiveCardRarities[0]}
                    image={lastFiveCardImages[0]}
                    tokenId={lastFiveCardIds[0]}
                    imageLoaded={checkAllImagesLoaded}
                  />
                  <FlipCard
                    rarity={lastFiveCardRarities[1]}
                    image={lastFiveCardImages[1]}
                    tokenId={lastFiveCardIds[1]}
                    imageLoaded={checkAllImagesLoaded}
                  />
                  <FlipCard
                    rarity={lastFiveCardRarities[2]}
                    image={lastFiveCardImages[2]}
                    tokenId={lastFiveCardIds[2]}
                    imageLoaded={checkAllImagesLoaded}
                  />
                  <FlipCard
                    rarity={lastFiveCardRarities[3]}
                    image={lastFiveCardImages[3]}
                    tokenId={lastFiveCardIds[3]}
                    imageLoaded={checkAllImagesLoaded}
                  />
                  <FlipCard
                    rarity={lastFiveCardRarities[4]}
                    image={lastFiveCardImages[4]}
                    tokenId={lastFiveCardIds[4]}
                    imageLoaded={checkAllImagesLoaded}
                  />
                </Row>
              )}
              <div
                style={{
                  width: "100vw",
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <InformationLine
                  style={{ color: "whitesmoke" }}
                  onClick={() => history.push("/klub")}
                >
                  Go to my Cat overview
                </InformationLine>
              </div>
            </DisplayCardContainer>
          )}
      </ContentWrapper>
      <Footer />
    </ContentAndFooterWrapper>
  );
};

const ContentAndFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #261b32;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8vw;
  margin-right: 8vw;
  height: 100%;
`;

const DisplayCardContainer = styled.div<{
  visible: boolean;
}>`
  position: ${(props) => (props.visible ? "relative" : "absolute")};
  offset: ${(props) => (props.visible ? "none" : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8vw;
  margin-right: 8vw;
  margin-top: 40px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const Logo = styled.img`
  height: 300px;
`;

const YellowLine = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffe358;
  cursor: default;
`;

const MintAmountToggle = styled.div<{
  disabled?: boolean;
}>`
  margin-top: 24px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  border: 1px solid white;
  border-radius: 5px 6px 3px 6px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #f98c1f;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "rgba(255, 255, 255, 0.15)"
        : "rgba(255, 255, 255, 0.25)"};
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0 0 11px rgba(157, 157, 157, 0.8)"};
  }
  transition: background-color 0.3s ease-in-out;
`;

const GreenLine = styled.div`
  margin-top: 24px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #33ff88;
  cursor: default;
`;

const WhiteLine = styled.div<{
  top?: boolean;
}>`
  margin-top: ${(props) => (props.top ? "18px" : "4px")};
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: white;
  cursor: default;
`;

const RedLine = styled.div`
  margin-top: 24px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: red;
  cursor: default;
`;

const LoreContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
`;

const PinkLine = styled.div<{}>`
  margin-top: 6px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffb8e2;
  cursor: default;
`;

const PriceLine = styled.div`
  margin-top: 36px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 56px;
  /* identical to box height, or 100% */
  text-align: center;
  color: white;
  cursor: default;
`;

const InformationLine = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1px 3px;
  border: 1px solid white;
  border-radius: 4px;
  margin-bottom: 24px;
  cursor: pointer;
`;

const MintButton = styled.button<{
  disabled?: boolean;
}>`
  font-family: "Catamaran", sans-serif;
  height: 60px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  justify-content: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 11px;
  border: 2px solid #f98c1f;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: rgba(249, 140, 31, 1);
  margin-top: 32px;
  color: #271b32;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: color 0.5s ease-out;
  transition: background-color 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: rgba(249, 140, 31, 0);
    color: rgba(249, 140, 31, 1);
    box-shadow: 0 0 16px rgba(249, 140, 31, 1);
  }
`;

export default Mint;
