import React from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import bckLogo from "../../assets/logo/bck.png";
import discordLogo from "../../assets/logo/discord32x32.png";
import telegramLogo from "../../assets/logo/telegram32x32.svg";
import twitterLogo from "../../assets/logo/twitter32x32.png";
import ftmScanLogo from "../../assets/logo/ftmscan32x32.png";
import nftKeyLogo from "../../assets/logo/nftkey32x32.png";
import etherScanLogo from "../../assets/logo/etherScanWhite.webp";
import openSeaLogo from "../../assets/logo/opensea.svg";
import paintSwapLogo from "../../assets/logo/paintswap32x32.png";
import spookySwapLogo from "../../assets/logo/spookyswap32x32.png";
import { useHistory } from "react-router-dom";

const WebLogo: React.FC<any> = (props: any) => {
  return (
    <WebLogoElement
      onClick={props.onClick}
      style={props.style}
      src={props.src}
    />
  );
};

type LinkWrapperTemp = {
  href: string;
  src: any;
  style?: object;
};

const LinkWrapper: React.FC<LinkWrapperTemp> = ({ href, src, style }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: "none",
      }}
    >
      <WebLogo src={src} style={style} />
    </a>
  );
};

const General: React.FC<any> = (props: any) => {
  const history = useHistory();

  // @ts-ignore
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        height: "100%",
        backgroundColor: "#261B32",
      }}
    >
      <Column
        style={{
          alignItems: "center",
          marginLeft: "8vw",
          marginRight: "8vw",
          marginTop: "46px",
        }}
      >
        <BckLogo src={bckLogo} />
        <BackgroundBox>
          <RowWrapper>
            <FirstWhiteText>
              Big Cat Klub{" "}
              <span
                style={{
                  color: "#f58b2f",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                {" "}
                333{" "}
              </span>{" "}
              Genesis Collection:
            </FirstWhiteText>
            <LogoWrapper>
              <LinkWrapper
                href={
                  "https://paintswap.finance/marketplace/fantom/collections/big-cat-klub-genesis"
                }
                src={paintSwapLogo}
              />
              <LinkWrapper
                href={"https://nftkey.app/collections/bigcatklub/"}
                src={nftKeyLogo}
              />
              <LinkWrapper
                href={
                  "https://ftmscan.com/token/0x916320bba0c956181b7caca0fe01ef7635efd411"
                }
                src={ftmScanLogo}
              />
            </LogoWrapper>
          </RowWrapper>
          <RowWrapper>
            <FirstWhiteText>
              Big Cat Klub{" "}
              <span
                style={{
                  color: "#f58b2f",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                222
              </span>
              Traitshop Collection:
            </FirstWhiteText>
            <LogoWrapper>
              <LinkWrapper
                href={
                  "https://paintswap.finance/marketplace/fantom/collections/big-cat-klub"
                }
                src={paintSwapLogo}
              />
              <LinkWrapper
                href={"https://nftkey.app/collections/bckbigcatklub/"}
                src={nftKeyLogo}
              />
              <LinkWrapper
                href={
                  "https://ftmscan.com/token/0xdcd75728136282bc3b23df6917169386d26eca82"
                }
                src={ftmScanLogo}
              />
            </LogoWrapper>
          </RowWrapper>
          <RowWrapper>
            <FirstWhiteText>
              Big Cat Klub{" "}
              <span
                style={{
                  color: "#f58b2f",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                222
              </span>
              Golden Fangs Collection:
            </FirstWhiteText>
            <LogoWrapper>
              <LinkWrapper
                href={"https://opensea.io/collection/bigcatklub/"}
                src={openSeaLogo}
              />
              <LinkWrapper
                href={
                  "https://etherscan.io/token/0x916320bba0c956181b7caca0fe01ef7635efd411#code"
                }
                src={etherScanLogo}
                style={{ borderRadius: "4px" }}
              />
            </LogoWrapper>
          </RowWrapper>
        </BackgroundBox>
        <BackgroundBox>
          <RowWrapper>
            <FirstWhiteText>Socials:</FirstWhiteText>
            <LogoWrapper>
              <LinkWrapper
                href={"https://discord.gg/AyZUmG7Mkg"}
                src={discordLogo}
              />
              <LinkWrapper
                href={"https://t.me/BigCatKlub"}
                src={telegramLogo}
              />
              <LinkWrapper
                href={"https://twitter.com/bigcatklub"}
                src={twitterLogo}
              />
            </LogoWrapper>
          </RowWrapper>
        </BackgroundBox>
        <BackgroundBox>
          <RowWrapper>
            <FirstWhiteText>Big Cat Koin Info:</FirstWhiteText>
            <LogoWrapper>
              <LinkWrapper
                href={
                  "https://info.spooky.fi/#/pair/0xe4c657a79eeb9d40f25da7a787fdfa645c321683"
                }
                src={spookySwapLogo}
              />
              <WebLogo
                src={bckLogo}
                style={{ cursor: "pointer", marginBottom: "8px" }}
                onClick={() => props.tokenLink()}
              />
              <LinkWrapper
                href={
                  "https://ftmscan.com/token/0x38a37ee5cabf5d01acb67c9e12fea421f9ef2694"
                }
                src={ftmScanLogo}
              />
            </LogoWrapper>
          </RowWrapper>
          <RowWrapper>
            <FirstWhiteText>Big Cat Koin Trading:</FirstWhiteText>
            <LogoWrapper>
              <WebLogo
                src={bckLogo}
                style={{ cursor: "pointer", marginBottom: "8px" }}
                onClick={() => {
                  props.updateBankMenu("trade");
                  history.push("/bank");
                }}
              />
              <LinkWrapper
                href={
                  "https://spooky.fi/#/swap?inputCurrency=FTM&outputCurrency=0x38a37ee5cabf5d01acb67c9e12fea421f9ef2694"
                }
                src={spookySwapLogo}
              />
            </LogoWrapper>
          </RowWrapper>
          <RowWrapper>
            <FirstWhiteText>Big Bank:</FirstWhiteText>
            <LogoWrapper>
              <WebLogo
                src={bckLogo}
                style={{ cursor: "pointer", marginBottom: "8px" }}
                onClick={() => {
                  history.push("/bank");
                }}
              />
              <LinkWrapper
                href={
                  "https://ftmscan.com/address/0x3574b1fc4aa2c5f988cb373691e3e777f5cd64c5#code"
                }
                src={ftmScanLogo}
              />
            </LogoWrapper>
          </RowWrapper>
        </BackgroundBox>
      </Column>
    </div>
  );
};

const BckLogo = styled.img`
  height: 92px;
  width: 121px;
  margin-bottom: 12px;
`;

const BackgroundBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 12px;
  margin-top: 48px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 6px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const WebLogoElement = styled.img`
  height: 32px;
  width: 32px;
  margin-top: 8px;
  margin-left: 12px;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s ease-in-out;
`;
const FirstWhiteText = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

export default General;
