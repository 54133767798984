import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Row from "../../components/Row";
import Column from "../../components/Column";
import { send } from "../../utils/transactions";
import { unitToWei, weiToUnit } from "../../utils/conversion";
import mintBackground from "../../assets/mint222/bgsmall.png";
import trait1 from "../../assets/mint222/trait1.png";
import trait2 from "../../assets/mint222/trait2.png";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import cross from "./../../assets/img/icons/X.png";
import { BigNumber } from "@ethersproject/bignumber";

const CustomizeMint: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [leftSelected, setLeftSelected] = useState(0);
  const [rightSelected, setRightSelected] = useState(0);
  const [boxActive, setBoxActive] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [baseId, setBaseId] = useState("");
  const [bckBalance, setBckBalance] = useState(0);
  const [bckAllowance, setBckAllowance] = useState(BigNumber.from(0));
  const [txType, setTxType] = useState("");
  const [buyingActive, setBuyingActive] = useState(false);
  const [currentLeft, setCurrentLeft] = useState("");
  const [displayLeft, setDisplayLeft] = useState("");
  const [currentRight, setCurrentRight] = useState("");
  const [displayRight, setDisplayRight] = useState("");
  const [leftOptions, setLeftOptions] = useState([
    ["", "", 0 as any],
    ["", "", 0 as any],
    ["", "", 0 as any],
  ]);
  const [rightOptions, setRightOptions] = useState([
    ["", "", 0 as any],
    ["", "", 0 as any],
  ]);
  const [rarity, setRarity] = useState(0);
  const [image, setImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const prices = [555, 1111, 1515, 2222];
  const rarities = ["uncommon", "rare", "legendary", "unique"];

  const setActiveBoxes = async (
    rarity: number,
    left: string,
    right: string
  ) => {
    const leftSide =
      rarity === 2
        ? [true, true, true]
        : rarity === 6
        ? [true, false, false]
        : [true, true, false];
    const rightSide =
      rarity === 3
        ? [true, false, false]
        : rarity === 4 || rarity === 5
        ? [true, true, false]
        : [false, false, false];
    const selected = [false, false, false];

    if (left !== "D" && right !== "D") {
      return setBoxActive(selected.concat(selected));
    }
    if (props.tokenId === "420") {
      return setBoxActive(selected.concat(selected));
    }
    if (left !== "D") {
      return setBoxActive(selected.concat(rightSide));
    }
    if (right !== "D") {
      return setBoxActive(leftSide.concat(selected));
    }
    return setBoxActive(leftSide.concat(rightSide));
  };

  const getBckData = async () => {
    const bckMockToken = await walletContext.activeWallet.contracts.get("bck");
    const mint222Contract = await walletContext.activeWallet.contracts.get(
      "bck222"
    );
    const allowance = await bckMockToken.allowance(
      walletContext.activeWallet.address,
      mint222Contract.address
    );
    const balance = await bckMockToken.balanceOf(
      walletContext.activeWallet.address
    );
    setBckAllowance(allowance);
    setBckBalance(weiToUnit(balance));
  };

  const getData = async () => {
    const mint222Contract = await walletContext.activeWallet.contracts.get(
      "bck222"
    );
    const options: any = await fetch(
      "https://heuristic-fermat-c9d2a8.netlify.app/api/options/twohundredtwentytwo/" +
        props.tokenId
    )
      .then((res) => {
        return res.json();
      })
      .then((jsonResult) => {
        return jsonResult;
      });
    getBckData();
    setImage("https://bigcatklub.com/assets/512/" + options.current + ".png");
    setBaseId(options.id);
    setCurrentLeft(options.currentLeft);
    setCurrentRight(options.currentRight);
    setDisplayLeft(options.currentLeft);
    setDisplayRight(options.currentRight);
    const getRarity = await mint222Contract.getRarityNumber(props.tokenId);
    setActiveBoxes(
      getRarity.toNumber(),
      options.currentLeft,
      options.currentRight
    );
    setRarity(getRarity.toNumber());
    if (getRarity.toNumber() === 1) {
      const keys = Object.keys(options.trait1);
      setLeftOptions([
        [keys[1], options.trait1[keys[1]], 0],
        [keys[2], options.trait1[keys[2]], 1],
        ["", "", 0],
      ]);
      setRightOptions([
        ["", "", 0],
        ["", "", 0],
      ]);
    }
    if (getRarity.toNumber() === 2) {
      if (props.tokenId === "420") {
        setLeftOptions([
          ["", "", 0],
          ["", "", 0],
          ["", "", 0],
        ]);
        setRightOptions([
          ["", "", 0],
          ["", "", 0],
        ]);
      } else {
        const keys = Object.keys(options.trait1);
        setLeftOptions([
          [keys[1], options.trait1[keys[1]], 0],
          [keys[2], options.trait1[keys[2]], 1],
          [keys[3], options.trait1[keys[3]], 2],
        ]);
        setRightOptions([
          ["", "", 0],
          ["", "", 0],
        ]);
      }
    }

    if (getRarity.toNumber() === 3) {
      const keys = Object.keys(options.trait1);
      const rKeys = Object.keys(options.trait2);
      setLeftOptions([
        [keys[1], options.trait1[keys[1]], 1],
        [keys[2], options.trait1[keys[2]], 2],
        ["", "", 0],
      ]);
      setRightOptions([
        [rKeys[1], options.trait2[rKeys[1]], 1],
        ["", "", 0],
      ]);
    }
    if (getRarity.toNumber() === 4) {
      const keys = Object.keys(options.trait1);
      const rKeys = Object.keys(options.trait2);
      setLeftOptions([
        [keys[1], options.trait1[keys[1]], 2],
        [keys[2], options.trait1[keys[2]], 3],
        ["", "", 0],
      ]);
      setRightOptions([
        [rKeys[1], options.trait2[rKeys[1]], 2],
        [rKeys[2], options.trait2[rKeys[2]], 3],
      ]);
    }
    if (getRarity.toNumber() === 5) {
      const keys = Object.keys(options.trait1);
      const rKeys = Object.keys(options.trait2);
      setLeftOptions([
        [keys[1], options.trait1[keys[1]], 2],
        [keys[2], options.trait1[keys[2]], 3],
        ["", "", 0],
      ]);
      setRightOptions([
        [rKeys[1], options.trait2[rKeys[1]], 2],
        [rKeys[2], options.trait2[rKeys[2]], 3],
      ]);
    }
    if (getRarity.toNumber() === 6) {
      const keys = Object.keys(options.trait1);
      setLeftOptions([
        [keys[1], options.trait1[keys[1]], 3],
        ["", "", 0],
        ["", "", 0],
      ]);
      setRightOptions([
        ["", "", 0],
        ["", "", 0],
      ]);
    }
  };

  const approveBuyOptions = async () => {
    setBuyingActive(true);
    setTxType("approve");
    const mint222Contract = await walletContext.activeWallet.contracts.get(
      "bck222"
    );
    const bckTokenContract = await walletContext.activeWallet.contracts.get(
      "bck"
    );
    const maxAmount = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );
    let provider = walletContext.activeWallet.provider;
    try {
      const hash = await send(
        provider,
        () => bckTokenContract.approve(mint222Contract.address, maxAmount),
        dispatchTx
      );
      setTxHash(hash);
    } catch {
      setTxType("");
      setBuyingActive(false);
    }
  };

  const buyOptionsFunc = async () => {
    setBuyingActive(true);
    setTxType("buy");
    const mint222Contract = await walletContext.activeWallet.contracts.get(
      "bck222"
    );
    let provider = walletContext.activeWallet.provider;
    const leftInput =
      currentLeft !== "D"
        ? currentLeft
        : leftSelected
        ? leftOptions[leftSelected - 1][0]
        : "D";
    const rightInput =
      currentRight !== "D"
        ? currentRight
        : rightSelected
        ? rightOptions[rightSelected - 1][0]
        : "D";

    try {
      const hash = await send(
        provider,
        () => mint222Contract.buyOptions(props.tokenId, leftInput, rightInput),
        dispatchTx
      );
      setTxHash(hash);
      setLeftSelected(0);
      setRightSelected(0);
    } catch {
      setTxType("");
      setBuyingActive(false);
    }
  };

  const ItemDetails: React.FC<any> = ({ align, number }) => {
    const index = number - 1;
    if (!boxActive[index]) {
      return <DetailColumns />;
    }
    // TODO Purple beanie no longer a thing
    if (align === "right") {
      const formatName =
        leftOptions[index][1].substr(0, 11) === "Beanie Mane"
          ? "Purple Beanie"
          : leftOptions[index][1].substr(0, 12) === "Flatcap Mane"
          ? "Brown Flatcap"
          : leftOptions[index][1];
      return (
        <DetailColumns
          style={{
            alignItems: "end",
          }}
        >
          <Name style={{ textAlign: align }}>{formatName.toUpperCase()}</Name>
          <Rarity>{rarities[leftOptions[index][2]].toUpperCase()}</Rarity>
          <Price>{prices[leftOptions[index][2]]} BCK</Price>
        </DetailColumns>
      );
    }
    if (align === "left") {
      const rightIndex = index - 3;
      const formatName =
        rightOptions[rightIndex][1].substr(0, 11) === "Beanie Mane"
          ? "Purple Beanie"
          : rightOptions[rightIndex][1].substr(0, 12) === "Flatcap Mane"
          ? "Brown Flatcap"
          : rightOptions[rightIndex][1];
      return (
        <DetailColumns
          style={{
            alignItems: "start",
          }}
        >
          <Name style={{ textAlign: align }}>{formatName.toUpperCase()}</Name>
          <Rarity>{rarities[rightOptions[rightIndex][2]].toUpperCase()}</Rarity>
          <Price>{prices[rightOptions[rightIndex][2]]} BCK</Price>
        </DetailColumns>
      );
    }
  };

  const ImageBlock: React.FC<any> = ({ side, number }) => {
    const index = number - 1;
    if (!boxActive[index]) {
      if (side === "left") {
        return (
          <ImageBoxes>
            <TraitLockedImage src={trait1} />
          </ImageBoxes>
        );
      }

      if (side === "right") {
        return (
          <ImageBoxes>
            <TraitLockedImage src={trait2} />
          </ImageBoxes>
        );
      }
    }
    if (side === "left") {
      const itemName = leftOptions[index][1];
      const formatName = itemName.replaceAll(" ", "-");
      const formatCheckName =
        formatName.substr(0, 11) === "Beanie-Mane"
          ? "Purple-Beanie"
          : formatName.substr(0, 12) === "Flatcap-Mane"
          ? "Brown-Flatcap"
          : formatName;
      const imageLocation =
        "https://bigcatklub.com/assets/items/" + formatCheckName + ".png";
      return (
        <ImageBoxes
          active={true}
          selected={leftSelected === number}
          onClick={() => {
            if (leftSelected === number) {
              setLeftSelected(0);
              setDisplayLeft("D");
              setImage(
                "https://bigcatklub.com/assets/512/" +
                  baseId +
                  "D" +
                  displayRight +
                  ".png"
              );
            }
            if (leftSelected !== number) {
              setLeftSelected(number);
              setDisplayLeft(leftOptions[index][0]);
              setImage(
                "https://bigcatklub.com/assets/512/" +
                  baseId +
                  leftOptions[index][0] +
                  displayRight +
                  ".png"
              );
            }
          }}
        >
          <ItemImage src={imageLocation} selected={leftSelected === number} />
        </ImageBoxes>
      );
    }
    if (side === "right") {
      const rightNumber = number - 3;
      const rightIndex = index - 3;
      const itemName = rightOptions[rightIndex][1];
      const formatName = itemName.replaceAll(" ", "-");
      const formatCheckName =
        formatName.substr(0, 13) === "Purple-Beanie"
          ? "Purple-Beanie"
          : formatName.substr(0, 13) === "Brown-Flatcap"
          ? "Brown-Flatcap"
          : formatName;
      const imageLocation =
        "https://bigcatklub.com/assets/items/" + formatCheckName + ".png";
      return (
        <ImageBoxes
          active={true}
          selected={rightSelected === rightNumber}
          onClick={() => {
            if (rightSelected === rightNumber) {
              setRightSelected(0);
              setDisplayRight("D");
              setImage(
                "https://bigcatklub.com/assets/512/" +
                  baseId +
                  displayLeft +
                  "D" +
                  ".png"
              );
            }
            if (rightSelected !== rightNumber) {
              setRightSelected(rightNumber);
              setDisplayRight(rightOptions[rightIndex][0]);
              setImage(
                "https://bigcatklub.com/assets/512/" +
                  baseId +
                  displayLeft +
                  rightOptions[rightIndex][0] +
                  ".png"
              );
            }
          }}
        >
          <ItemImage
            src={imageLocation}
            selected={rightSelected === rightNumber}
          />
        </ImageBoxes>
      );
    }

    // if (side === "right") {
    //   const itemName = rightOptions[index - 3][1];
    //   const formatName = itemName.replaceAll(" ", "-");
    //   const imageLocation =
    //     "https://bigcatklub.com/assets/items/" + formatName + ".jpg";
    //   return (
    //     <ImageBoxes
    //       active={true}
    //       selected={rightSelected === number - 3}
    //       onClick={() =>
    //         setRightSelected(rightSelected === number - 3 ? 0 : number - 3)
    //       }
    //     >
    //       <ItemImage src={imageLocation} selected={rightSelected === number} />
    //     </ImageBoxes>
    //   );
    // }
  };

  const PriceTotalElement: React.FC<any> = () => {
    const leftPrice =
      leftSelected === 0 ? 0 : prices[leftOptions[leftSelected - 1][2]];
    if (rightOptions) {
      // console.log(rightOptions[rightSelected - 1]);
      // console.log(rightOptions[rightSelected - 1][2]);
    }
    const rightPrice =
      rightSelected === 0 ? 0 : prices[rightOptions[rightSelected - 1][2]];
    const totalPrice = leftPrice + rightPrice;
    const lowBalance = totalPrice <= bckBalance ? false : true;
    const lowAllowance = unitToWei(totalPrice.toString()).gt(bckAllowance);
    const disabled =
      totalPrice === 0 ? true : buyingActive ? true : lowBalance ? true : false;
    if (boxActive[0] === true || boxActive[3] === true) {
      return (
        <BottomLinePriceColumn>
          <PriceTotal disabled={lowBalance}>{totalPrice} BCK</PriceTotal>
          <BuyButton
            disabled={disabled}
            onClick={() =>
              lowAllowance ? approveBuyOptions() : buyOptionsFunc()
            }
          >
            {lowAllowance
              ? txType === "approve"
                ? "APPROVING..."
                : "APPROVE"
              : buyingActive
              ? "BUYING..."
              : "BUY"}
          </BuyButton>
        </BottomLinePriceColumn>
      );
    } else {
      return (
        <BottomLinePriceColumn>
          <PriceTotal disabled={false}>
            {props.tokenId === "420"
              ? "You are too baked for options!"
              : "Trait shopping done!"}
          </PriceTotal>
        </BottomLinePriceColumn>
      );
    }
  };

  // const fetchAttributes = async (tokenIndex: string) => {
  // const data = await fetch(
  //   "https://heuristic-fermat-c9d2a8.netlify.app/api/nft/twohundredtwentytwo/" +
  //     tokenIndex
  // )
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .then((jsonResult) => {
  //     return jsonResult;
  //   });
  //   console.log(data);
  //   return setAttributes({ ...data, tokenIndex: tokenIndex });
  // };
  useEffect(() => {
    if (props.tokenId > 0) {
      getData();
    }
  }, [props.tokenId]);

  useEffect(() => {
    setLoaded(false);
  }, [
    walletContext.activeWallet.address,
    walletContext.activeWallet.contracts,
  ]);

  useEffect(() => {
    if (tx?.status === "completed") {
      setBuyingActive(false);
      if (txType === "approve") {
        getBckData();
      }
      if (txType === "buy") {
        getData();
      }
    }
  }, [transaction]);

  return (
    <Column
      style={{
        alignItems: "center",
        marginLeft: "8vw",
        marginRight: "8vw",
        marginTop: "96px",
        marginBottom: "64px",
      }}
    >
      <DummyBox>
        <CrossBox onClick={() => props.closeCustomize()}>
          <StyledCross src={cross} />
        </CrossBox>
        <Column
          style={{
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Row
            style={{
              height: "416px",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <SideColumns>
              <ItemDetails align={"right"} number={1} />
              <ItemDetails align={"right"} number={2} />
              <ItemDetails align={"right"} number={3} />
            </SideColumns>
            <SideColumns>
              <ImageBlock side={"left"} number={1} />
              <ImageBlock side={"left"} number={2} />
              <ImageBlock side={"left"} number={3} />
            </SideColumns>
            <MainImageBox>
              {image !== "" && (
                <CatNumber>
                  BIG CAT{" "}
                  <span style={{ color: "white" }}>#{props.tokenId}</span>
                </CatNumber>
              )}
              {image !== "" && (
                <CatRarity rarity={rarity}>
                  {rarity === 1
                    ? "COMMON"
                    : rarity === 2
                    ? "UNCOMMON"
                    : rarity === 3
                    ? "RARE"
                    : rarity === 4
                    ? "LEGENDARY"
                    : rarity === 6
                    ? "HONORARY"
                    : ""}
                </CatRarity>
              )}
              {image !== "" && <MainImage src={image} />}
            </MainImageBox>
            <SideColumns>
              <ImageBlock side={"right"} number={4} />
              <ImageBlock side={"right"} number={5} />
              <ImageBlock side={"right"} number={6} />
            </SideColumns>
            <SideColumns>
              <ItemDetails align={"left"} number={4} />
              <ItemDetails align={"left"} number={5} />
              <ItemDetails align={"left"} number={6} />
            </SideColumns>
          </Row>
          <Row
            style={{
              height: "128px",
              justifyContent: "space-between",
            }}
          >
            <BottomLineInfoBox>
              <TimeLeft>IMPORTANT!</TimeLeft>
              <TimeLeftExplanation>
                TRAIT SHOP WILL BE LOCKED <br></br>1 WEEK AFTER COLLECTION{" "}
                <br></br>MINTS OUT
              </TimeLeftExplanation>
            </BottomLineInfoBox>
            <PriceTotalElement />
            <BottomLineInfoBox>
              <RightBoxExplanation>
                YOU CAN ONLY BUY ONCE <br />
                PER TRAIT
                <br />
                <br />
                POSSIBLE TO PICK UP 2 TRAITS <br />
              </RightBoxExplanation>
            </BottomLineInfoBox>
          </Row>
        </Column>
      </DummyBox>
    </Column>
  );
};

const DummyBox = styled.div`
  position: relative;
  height: 632px;
  width: 1023px;
  background-image: url(${mintBackground});
  border-radius: 16px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

const CrossBox = styled.div`
  position: absolute;
  right: 24px;
  top: 0px;
  width: 17px;
  cursor: pointer;
`;

const StyledCross = styled.img`
  justify-content: center;
  align-items: center;
  width: 17px;
`;

const SideColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 416px;
  width: 128px;
`;

const DetailColumns = styled.div`
  display: flex;
  flex-direction: column;
  height: 128px;
  width: 128px;
`;

const ImageBoxes = styled.div<{
  active?: boolean;
  selected?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  background: rgba(64, 54, 74, 0.5);
  border: ${(props) =>
    props.selected ? "2px solid rgba(51, 255, 136, 0.5)" : "none"};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.active ? "pointer" : "cursor")};
`;

const TraitLockedImage = styled.img`
  height: 128px;
  width: 128px;
  border-radius: 6px;
`;

const ItemImage = styled.img<{
  selected?: boolean;
}>`
  height: ${(props) => (props.selected ? "124px" : "128px")};
  width: ${(props) => (props.selected ? "124px" : "128px")};
  border-radius: 6px;
`;

const MainImageBox = styled.div`
  position: relative;
  width: 416px;
  height: 416px;
  background: rgba(64, 54, 74, 0.5);
  border-radius: 8px;
`;

const CatNumber = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
`;

const CatRarity = styled.div<{
  rarity?: number;
}>`
  position: absolute;
  right: 12px;
  top: 12px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: ${(props) =>
    props.rarity === 1
      ? "#FFFFFF"
      : props.rarity === 2
      ? "#00ef81"
      : props.rarity === 3
      ? "#01d8ff"
      : props.rarity === 4
      ? "#ffe401"
      : "#f001ff"};
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
`;

const MainImage = styled.img`
  width: 416px;
  height: 416px;
  border-radius: 8px;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  color: #ffe358;
`;

const PriceTotal = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: ${(props) => (props.disabled ? "#FF0000" : "#ffe358")};
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
`;

const Rarity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 16px;
  color: white;
`;

const BottomLineInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 264px;
  height: 128px;
  background: rgba(51, 255, 136, 0.02);
  border-radius: 4px;
`;

const TimeLeft = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #4eba9d;
`;
const TimeLeftExplanation = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #9affc4;
`;

const RightBoxExplanation = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

const BottomLinePriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 264px;
  height: 128px;
`;

const BuyButton = styled.button<{
  disabled?: boolean;
}>`
  padding: 0px 40px;
  height: 48px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 12px;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.5)" : "rgba(51, 255, 136, 1)"};
  margin-top: 16px;
  color: #271b32;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  transition: background-color 0.2s ease-in-out;
  &&:hover {
    background-color: ${(props) =>
      props.disabled ? "rgba(51, 255, 136, 0.5)" : "rgba(51, 255, 136, 0.7)"};
  }
`;

export default CustomizeMint;
