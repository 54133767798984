import React from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import InfoBlock from "../../components/Roadmap/InfoBlock";
import genesis from "./../../assets/roadmap/01_genesis.jpg";
import hatparty from "./../../assets/roadmap/02_hatparty.jpg";
import token from "./../../assets/roadmap/03_token.jpg";
import family from "./../../assets/roadmap/04_family.jpg";
import tiger_eth from "../../assets/roadmap/05_tiger_eth.jpg";
import tiger_ftm from "./../../assets/roadmap/05_tiger_ftm.jpg";
import gamefi from "./../../assets/roadmap/06_gamefi.jpg";
import nextCat from "./../../assets/roadmap/07_next.jpg";

const Roadmap: React.FC<any> = (props: any) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        height: "100%",
        backgroundColor: "#261B32",
      }}
    >
      <Column
        style={{
          alignItems: "center",
          width: "84vw",
          marginLeft: "8vw",
          marginRight: "8vw",
          marginTop: "16px",
        }}
      >
        <Year>2021</Year>
        <InfoBlock
          complete={100}
          img={genesis}
          title={"RELEASE OF 333 GENESIS COLLECTION"}
          info={
            "Big Cat Klub first collection of 333 lions released on FTM. Whitelisted 1 mint per address was available for a month to ensure good evenly spread supply."
          }
          quantity={333}
          releaseDate={"11.11.21"}
          linkType={"marketplace"}
          linkOne={["NFTKEY", "https://nftkey.app/collections/bigcatklub/"]}
          linkTwo={[
            "PAINTSWAP",
            "https://paintswap.finance/marketplace/fantom/collections/big-cat-klub-genesis",
          ]}
        />
        <Line />
        <InfoBlock
          complete={100}
          img={hatparty}
          title={"BIG HAT PARTY"}
          info={
            "During the Hatparty all 333 of Genesis lions with a bald trait were able to buy different hats that were added to the NFTs. Additionally special design hoodies were available to purchase."
          }
          releaseDate={"12.25.21"}
          linkType={"none"}
        />
        <Year>2022</Year>
        <InfoBlock
          complete={100}
          img={token}
          title={"RELEASE OF $BCK TOKEN ALONG WITH WEBSITE BANK SECTION"}
          info={
            "Fully functional and custom built Smart ATM that enables trading, providing liquidity, farming and interacting with the $BCK token."
          }
          releaseDate={"22.02.22"}
          linkOne={[
            "FTMSCAN",
            "https://ftmscan.com/token/0x38a37ee5cabf5d01acb67c9e12fea421f9ef2694",
          ]}
          internalLink={["BANK", "/bank"]}
          tokenLink={props.tokenLink}
        />
        <Line />
        <InfoBlock
          complete={100}
          img={family}
          title={"THE FAMILY 222 COLLECTION WITH TRAITSHOP"}
          info={
            "Additional 222 lions mint was supported by original concept of a fully functional real-time NFT Trait Shop which utilized our own native $BCK token."
          }
          quantity={222}
          releaseDate={"07.06.22"}
          linkType={"marketplace"}
          linkOne={["NFTKEY", "https://nftkey.app/collections/bckbigcatklub/"]}
          linkTwo={[
            "PAINTSWAP",
            "https://paintswap.finance/marketplace/fantom/collections/big-cat-klub",
          ]}
        />
        <Year>2023</Year>
        <InfoBlock
          complete={100}
          img={tiger_eth}
          title={"RELEASE OF 222 GOLDEN FANGS COLLECTION"}
          info={
            "Big Cat Klub first ETH release. All BCK NFT holders will be eligible to get into whitelist."
          }
          quantity={222}
          releaseDate={"25.08.2023"}
          linkType={"marketplace"}
          linkOne={["OPENSEA", "https://opensea.io/collection/bigcatklub"]}
          internalLink={["MINT", "/mint"]}
        />
        <Line />
        {/*<InfoBlock*/}
        {/*  complete={35}*/}
        {/*  img={tiger_ftm}*/}
        {/*  title={"TIGERS COLLECTION ON FTM"}*/}
        {/*  info={*/}
        {/*    "Tigers will be dropping also on FTM sometime after the ETH mint."*/}
        {/*  }*/}
        {/*  quantity={"Unknown"}*/}
        {/*  releaseDate={"Q3 2023"}*/}
        {/*  linkType={"none"}*/}
        {/*/>*/}
        {/*<Line />*/}
        <InfoBlock
          complete={55}
          img={gamefi}
          title={"GAMEFI UTILIZING $BCK TOKEN"}
          info={"First BCK GameFi release."}
          releaseDate={"Q3 2023"}
          linkType={"none"}
        />
        <Line />
        <InfoBlock
          complete={0}
          img={nextCat}
          title={"NEXT BIG CAT COLLECTION ON ETH AND FTM"}
          info={
            "Big Cat Klub collection release. Which cat will it be this time?"
          }
          quantity={"Unknown"}
          releaseDate={"Q4 2023"}
          linkType={"none"}
        />
        <BottomContainer>
          <PinkLine>
            OUR ROADMAP IS ALWAYS EVOLVING, INCLUDING FREQUENT
          </PinkLine>
          <PinkLine>SMALLER GOALS ACHIEVED OFF-ROADMAP.</PinkLine>
          <PinkLine>
            STAY UPDATED JOIN OUR{" "}
            <a
              href="https://discord.gg/AyZUmG7Mkg"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#01fd88", textDecoration: "none" }}
            >
              DISCORD
            </a>
          </PinkLine>
        </BottomContainer>
      </Column>
    </div>
  );
};

const Line = styled.div`
  display: flex;
  width: 16px;
  height: 0px;
  margin-top: 16px;
  margin-bottom: 18px;

  border: 2px solid rgba(255, 255, 255, 0.25);
  transform: rotate(90deg);
`;

const Year = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin: 40px;
  color: #ffffff;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 32px;
`;

const PinkLine = styled.div<{}>`
  margin-top: 6px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffb8e2;
  cursor: default;
`;

export default Roadmap;
