import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import back from "./../../assets/ethmint/fangs256x356.jpg";
import styled from "styled-components";

const rarityBackground = [
  "0 0 0px rgba(0, 0, 0, 0)",
  "0 0 18px rgba(197, 197, 197, 0.8)",
  "0 0 18px rgba(50, 205, 50, 0.8)",
  "0 0 18px rgba(0, 255, 255, 0.8)",
  "0 0 18px rgba(249, 191, 59, 0.8)",
  "0 0 18px rgba(191, 85, 236, 0.8)",
];

type FlipCardInput = {
  rarity: number;
  image: any;
  tokenId: number;
  imageLoaded: () => void;
};

const FlipCard: React.FC<FlipCardInput> = ({
  rarity,
  image,
  tokenId,
  imageLoaded,
}) => {
  const [flip, setFlip] = useState(false);
  const [imageFullyLoaded, setImageFullyLoaded] = useState(false);

  useEffect(
    function () {
      if (imageFullyLoaded) {
        imageLoaded();
      }
    },
    [imageFullyLoaded]
  );

  let bgImg = new Image();
  bgImg.onload = function () {
    setImageFullyLoaded(true);
  };
  bgImg.src = image;

  return (
    <ReactCardFlip infinite={false} isFlipped={flip} flipDirection="horizontal">
      <div
        style={{
          width: "256px",
          height: "356px",
          margin: "0 10px",
          perspective: "100px",
        }}
        onClick={() => {
          if (imageFullyLoaded) {
            setFlip(!flip);
          }
        }}
      >
        <BackImage
          rarity={rarity}
          image={back}
          imageLoaded={imageFullyLoaded}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: "256px",
          height: "356px",
          margin: "0 10px",
          perspective: "100px",
        }}
      >
        <FrontImage rarity={rarity} image={image} />
        <CardNumber>#{tokenId}</CardNumber>
      </div>
    </ReactCardFlip>
  );
};

const FrontImage = styled.div<{
  rarity: number;
  image: any;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 1s;
  border-color: rgb(218, 218, 218);
  background-image: url(${(props) => props.image});

  background-size: cover; /* scale the image to the largest size such that both its width and height can fit inside the content area */
  background-position: center; /* center the image */
  background-repeat: no-repeat; /* don't repeat the image */
  border-radius: 10px; /* set the border radius to 10px */
  box-shadow: ${(props) => rarityBackground[props.rarity]};
`;

const BackImage = styled.div<{
  rarity: number;
  image: any;
  imageLoaded: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 1s;
  border-color: rgb(218, 218, 218);
  background-image: url(${(props) => props.image});

  background-size: cover; /* scale the image to the largest size such that both its width and height can fit inside the content area */
  background-position: center; /* center the image */
  background-repeat: no-repeat; /* don't repeat the image */
  border-radius: 10px; /* set the border radius to 10px */
  box-shadow: 0 0 11px rgba(97, 97, 97, 0.8);
  &:hover {
    box-shadow: ${(props) => rarityBackground[props.rarity]};
  }
  transition: box-shadow 0.25s ease-in-out;
  cursor: ${(props) => (props.imageLoaded ? "pointer" : "cursor")};
`;

const CardNumber = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  height: 32px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  opacity: 0.8;
  border-radius: 8px;
  text-align: right;
`;
export default FlipCard;
