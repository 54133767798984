import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { Header } from "../../components";
import Row from "../../components/Row";
import WalletSelector from "../../components/WalletSelector";
import logoImg from "../../assets/logo/Frame.png";
import ftmLogo from "../../assets/logo/Fantom.png";

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const StyledHashLink = styled(HashLink)`
  text-decoration: none;
`;

const TopBar: React.FC<any> = (props: any) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const RouterLink: React.FC<any> = ({
    name,
    path,
    currentPath,
    setCurrentPath,
  }) => {
    if (currentPath === "/" && path === "/mint") {
      currentPath = "/mint";
    }
    const isActive = path === currentPath;

    return (
      <StyledNavLink to={path} onClick={() => setCurrentPath(path)}>
        <StyledRouterLink current={isActive}>{name}</StyledRouterLink>
      </StyledNavLink>
    );
  };

  // const RouterLinkImage: React.FC<any> = ({
  //   name,
  //   path,
  //   currentPath,
  //   setCurrentPath,
  // }) => {
  //   if (currentPath === "/" && path === "/mint") {
  //     currentPath = "/mint";
  //   }
  //   const isActive = path === currentPath;
  //
  //   return (
  //     <StyledNavLink to={path} onClick={() => setCurrentPath(path)}>
  //       <StyledRouterImage
  //         active={isActive}
  //         src={bckToken}
  //       />
  //     </StyledNavLink>
  //   );
  // };

  const HashRouterLink: React.FC<any> = ({
    name,
    path,
    currentPath,
    setCurrentPath,
  }) => {
    // if (currentPath === "/" && path === "/mint") {
    //   currentPath = "/mint";
    // }
    const isActive = path === currentPath;

    return (
      <StyledHashLink smooth to={path} onClick={() => setCurrentPath(path)}>
        <StyledRouterLink current={isActive}>{name}</StyledRouterLink>
      </StyledHashLink>
    );
  };

  return (
    <Header>
      <Row style={{ alignItems: "center" }}>
        <StyledNavLink to={"/"} onClick={() => setCurrentPath("/")}>
          <Logo src={logoImg} />
        </StyledNavLink>
        <Line />
        <FantomLogo src={ftmLogo} />
        <FtmTxt>Powered by Fantom blockchain</FtmTxt>
      </Row>
      <Row style={{ alignItems: "center" }}>
        <RouterLink
          name="INFO"
          path="/info"
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
        />
        <RouterLink
          name="BANK"
          path="/bank"
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
        />
        <RouterLink
          name="KLUB"
          path="/klub"
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
        />
        <HashRouterLink
          name="MINT"
          path="/mint"
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
        />
        <WalletSelector />
      </Row>
    </Header>
  );
};

const Logo = styled.img`
  align-items: center;
  line-height: 24px;
  text-align: center;
  height: 24px;
  margin-left: 80px;
`;

const FantomLogo = styled.img`
  align-items: center;
  line-height: 24px;
  text-align: center;
  height: 24px;
`;

const FtmTxt = styled.div`
  font-family: "Catamaran", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  height: 16px;
  margin-left: 8px;
  color: #ffffff;
  cursor: default;
`;

const Line = styled.div`
  width: 32px;
  height: 0px;
  border: 1px solid #ffffff;
  transform: rotate(90deg);
`;

// const SocialLink = styled.img<{
//   hovering?: boolean;
// }>`
//   align-items: center;
//   line-height: 24px;
//   text-align: center;
//   height: 20px;
//   margin-left: 24px;
//   opacity: ${(props) => (props.hovering ? 1 : 0.5)};
//   cursor: pointer;
// `;

const StyledRouterLink = styled.div<{
  current?: boolean;
}>`
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  height: 24px;
  margin-left: 24px;
  color: ${(props) => (props.current ? "#ffffff" : "#b3afb7")};
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  transition: color 0.2s ease-in-out;
`;

// const StyledRouterImage = styled.img<{
//   active?: boolean;
// }>`
//   align-items: center;
//   line-height: 24px;
//   height: 24px;
//   color: ${(props) => (props.active ? "#ffffff" : "#b3afb7")};
//   cursor: pointer;
//   &:hover {
//     color: #ffffff;
//   }
// `;

export default TopBar;
