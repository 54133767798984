import erc20 from "./abis/erc20.json";
import pair from "./abis/pair.json";
import auman from "./abis/auman.json";
import bcklions from "./abis/bcklionsprod.json";
import bcklionfactory from "./abis/BCKLionFactory.json";
import bckethfactory from "./abis/BckEthFactory.json";
import bckmocktoken from "./abis/BCKMockToken.json";
import bck222 from "./abis/BCKTwoHundredTwentyTwo.json";
import bcketh222 from "./abis/BCK222TigerMinter.json";
import whitelist from "./abis/BCKWhitelist.json";
import spookyfactory from "./abis/spookyfactory.json";
import spookyrouter from "./abis/spookyrouter.json";
import rewarder from "./abis/rewarder.json";
import hatstore from "./abis/hatstore.json";
import airdrop from "./abis/airdrop.json";
import bck from "./abis/bck.json";
import xbck from "./abis/xbck.json";
import xfarm from "./abis/xFarm.json";
import wftm from "./abis/wftm.json";
import beets from "./abis/beets.json";
import mchef from "./abis/mchef.json";
import sim1 from "./abis/sim1.json";
import sim3 from "./abis/sim3.json";

export default {
  erc20,
  pair,
  auman,
  bcklions,
  bcklionfactory,
  bckethfactory,
  bckmocktoken,
  bck222,
  bcketh222,
  whitelist,
  spookyfactory,
  spookyrouter,
  rewarder,
  hatstore,
  airdrop,
  bck,
  xbck,
  xfarm,
  wftm,
  beets,
  mchef,
  sim1,
  sim3,
};
