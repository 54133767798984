import React, { useState } from "react";
import styled from "styled-components";
import useWalletProvider from "../../hooks/useWalletProvider";
import serializeNumber from "../../utils/serializeNumber";
import Row from "../../components/Row";
import Column from "../../components/Column";
import FlipToggle from "../../components/FlipToggle";
import airdrop from "./../../assets/bank/airdrop.png";
import background111 from "../../assets/catoverview/BG_111.jpg";

const Search: React.FC<any> = () => {
  const { walletContext } = useWalletProvider();
  const [searchIndex, setSearchIndex] = useState("");
  const [searchHigh, setSearchHigh] = useState(0);
  const [chainActive, setChainActive] = useState("ftm");
  const [attributes, setAttributes] = useState({
    attributes: [
      { trait_type: "Background", value: "?" },
      { trait_type: "Body", value: "?" },
      { trait_type: "Head", value: "?" },
      { trait_type: "Eyes", value: "?" },
      { trait_type: "Mouth", value: "?" },
      { trait_type: "Clothing", value: "?" },
      { trait_type: "Chains", value: "?" },
      { trait_type: "Rings", value: "?" },
      { trait_type: "Eyewear", value: "?" },
      { trait_type: "Accessories", value: "?" },
      { trait_type: "Collection", value: "?" },
      { trait_type: "Rarity Type", value: "?" },
    ],
    image: "",
    tokenIndex: "",
  });
  // const set1Attributes = attributes.attributes.slice(0, 4);
  const [claimed, setClaimed] = useState(true);

  const fetchAttributesEth = async (tokenIndex: string) => {
    setClaimed(true);
    if (parseInt(tokenIndex) > 222 || parseInt(tokenIndex) < 1) {
      setAttributes({
        attributes: [
          { trait_type: "Background", value: "?" },
          { trait_type: "Body", value: "?" },
          { trait_type: "Head", value: "?" },
          { trait_type: "Eyes", value: "?" },
          { trait_type: "Mouth", value: "?" },
          { trait_type: "Clothing", value: "?" },
          { trait_type: "Chains", value: "?" },
          { trait_type: "Rings", value: "?" },
          { trait_type: "Eyewear", value: "?" },
          { trait_type: "Accessories", value: "?" },
          { trait_type: "Collection", value: "?" },
          { trait_type: "Rarity Type", value: "?" },
        ],
        image: "",
        tokenIndex: "",
      });
      return setSearchHigh(3);
    }
    // process.env.REACT_APP_BCK_API
    if (parseInt(tokenIndex) < 223 && parseInt(tokenIndex) > 0) {
      const data = await fetch(
        `https://heuristic-fermat-c9d2a8.netlify.app/api/nft/goldenfangs${
          process.env.REACT_APP_USE === "testnet" ? "test" : ""
        }/` + tokenIndex
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      if (data.status === "not yet minted") {
        setAttributes({
          attributes: [
            { trait_type: "Background", value: "?" },
            { trait_type: "Body", value: "?" },
            { trait_type: "Head", value: "?" },
            { trait_type: "Eyes", value: "?" },
            { trait_type: "Mouth", value: "?" },
            { trait_type: "Clothing", value: "?" },
            { trait_type: "Chains", value: "?" },
            { trait_type: "Rings", value: "?" },
            { trait_type: "Eyewear", value: "?" },
            { trait_type: "Accessories", value: "?" },
            { trait_type: "Collection", value: "?" },
            { trait_type: "Rarity Type", value: "?" },
          ],
          image: "",
          tokenIndex: "",
        });
        return setSearchHigh(1);
      }
      if (data.status !== "Out of bound") {
        return setAttributes({
          attributes: data.attributes,
          image: `https://${
            process.env.REACT_APP_USE === "testnet"
              ? "splendorous-figolla-904393.netlify.app"
              : "bigcatklub.com/"
          }/assets/goldenfangs/512/bck${serializeNumber(data.id)}.png`,
          tokenIndex: data.id,
        });
      }
    }
  };

  const fetchAttributesFtm = async (tokenIndex: string) => {
    if (parseInt(tokenIndex) < 334 && parseInt(tokenIndex) > 0) {
      const airdropContract = walletContext.activeWallet.contracts.get(
        "airdropCall"
      );
      const getClaimed = await airdropContract.hasClaimed(tokenIndex);
      setClaimed(getClaimed);
    }
    if (parseInt(tokenIndex) > 555 || parseInt(tokenIndex) < 1) {
      setAttributes({
        attributes: [
          { trait_type: "Background", value: "?" },
          { trait_type: "Body", value: "?" },
          { trait_type: "Head", value: "?" },
          { trait_type: "Eyes", value: "?" },
          { trait_type: "Mouth", value: "?" },
          { trait_type: "Clothing", value: "?" },
          { trait_type: "Chains", value: "?" },
          { trait_type: "Rings", value: "?" },
          { trait_type: "Eyewear", value: "?" },
          { trait_type: "Accessories", value: "?" },
          { trait_type: "Collection", value: "?" },
          { trait_type: "Rarity Type", value: "?" },
        ],
        image: "",
        tokenIndex: "",
      });
      return setSearchHigh(2);
    }
    // process.env.REACT_APP_BCK_API
    if (parseInt(tokenIndex) < 334 && parseInt(tokenIndex) > 0) {
      const data = await fetch(
        "https://heuristic-fermat-c9d2a8.netlify.app/api/attributes/" +
          tokenIndex
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      if (data.status !== "Out of bound") {
        return setAttributes({ ...data, tokenIndex: tokenIndex });
      }
      setAttributes({
        attributes: [
          { trait_type: "Background", value: "?" },
          { trait_type: "Body", value: "?" },
          { trait_type: "Head", value: "?" },
          { trait_type: "Eyes", value: "?" },
          { trait_type: "Mouth", value: "?" },
          { trait_type: "Clothing", value: "?" },
          { trait_type: "Chains", value: "?" },
          { trait_type: "Rings", value: "?" },
          { trait_type: "Eyewear", value: "?" },
          { trait_type: "Accessories", value: "?" },
          { trait_type: "Collection", value: "?" },
          { trait_type: "Rarity Type", value: "?" },
        ],
        image: "",
        tokenIndex: "",
      });
      return setSearchHigh(1);
    }

    if (parseInt(tokenIndex) < 556 && parseInt(tokenIndex) > 333) {
      const data = await fetch(
        "https://heuristic-fermat-c9d2a8.netlify.app/api/nft/twohundredtwentytwo/" +
          tokenIndex
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      if (data.status !== "not yet minted") {
        return setAttributes({ ...data, tokenIndex: tokenIndex });
      }
      setAttributes({
        attributes: [
          { trait_type: "Background", value: "?" },
          { trait_type: "Body", value: "?" },
          { trait_type: "Head", value: "?" },
          { trait_type: "Eyes", value: "?" },
          { trait_type: "Mouth", value: "?" },
          { trait_type: "Clothing", value: "?" },
          { trait_type: "Chains", value: "?" },
          { trait_type: "Rings", value: "?" },
          { trait_type: "Eyewear", value: "?" },
          { trait_type: "Accessories", value: "?" },
          { trait_type: "Collection", value: "?" },
          { trait_type: "Rarity Type", value: "?" },
        ],
        image: "",
        tokenIndex: "",
      });
      return setSearchHigh(1);
    }
  };

  return (
    <Column
      style={{
        alignItems: "center",
        marginLeft: "8vw",
        marginRight: "8vw",
        marginTop: "72px",
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "calc(50vw + 400px)",
          marginBottom: "40px",
        }}
      >
        <SearchWrapper>
          <SearchInput
            placeholder="Search for Big Cat number..."
            value={searchIndex}
            onChange={(event) => setSearchIndex(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                chainActive === "ftm"
                  ? fetchAttributesFtm(searchIndex)
                  : fetchAttributesEth(searchIndex);
              }
            }}
          />
          <ChainToggleBox>
            <ChainToggleText
              activated={chainActive === "ftm"}
              clickable={chainActive === "eth"}
              onClick={() => setChainActive("ftm")}
            >
              FTM
            </ChainToggleText>
            <ChainToggleText activated={true} clickable={false}>
              /
            </ChainToggleText>
            <ChainToggleText
              activated={chainActive === "eth"}
              clickable={chainActive === "ftm"}
              onClick={() => setChainActive("eth")}
            >
              ETH
            </ChainToggleText>
            <div
              onClick={() => {
                chainActive === "ftm"
                  ? setChainActive("eth")
                  : setChainActive("ftm");
              }}
            >
              <FlipToggle flipped={chainActive === "ftm" ? false : true} />
            </div>
          </ChainToggleBox>
        </SearchWrapper>

        <SearchButton
          onClick={() => {
            chainActive === "ftm"
              ? fetchAttributesFtm(searchIndex)
              : fetchAttributesEth(searchIndex);
          }}
        >
          SEARCH
        </SearchButton>
      </Row>
      {attributes.image === "" ? (
        <DummyBox>
          {searchHigh === 1 && <WarningText>Lion not yet minted</WarningText>}
          {searchHigh === 2 && (
            <WarningText>
              There are 555 fantom cats total, pick a number between 1 and 555
            </WarningText>
          )}
          {searchHigh === 3 && (
            <WarningText>
              There are 111 ethereum cats total, pick a number between 1 and 111
            </WarningText>
          )}
        </DummyBox>
      ) : (
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "40px",
            position: "relative",
          }}
        >
          <CatNumber>
            BIG CAT{" "}
            <span style={{ color: "white" }}>#{attributes.tokenIndex}</span>
          </CatNumber>
          {!claimed && <TailorLink src={airdrop} />}
          <StyledImg src={attributes.image} />
          <EmptyBox>
            <EmptyBox111BackGround />
            <Row style={{ height: "30%", marginBottom: "5%" }}>
              {attributes.attributes.slice(0, 4).map((trait) => {
                return (
                  <AttributeColumn>
                    <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                    <AttVal>{trait.value}</AttVal>
                    <RarityValue>
                      {(trait as any)?.frequency
                        ? "Rarity Score: " + (trait as any).frequency
                        : "Rarity Score: ?"}
                    </RarityValue>
                  </AttributeColumn>
                );
              })}
            </Row>
            <Row style={{ height: "30%", marginBottom: "5%" }}>
              {attributes.attributes.slice(4, 8).map((trait) => {
                return (
                  <AttributeColumn>
                    <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                    <AttVal>{trait.value}</AttVal>
                    <RarityValue>
                      {(trait as any)?.frequency
                        ? "Rarity Score: " + (trait as any).frequency
                        : "Rarity Score: ?"}
                    </RarityValue>
                  </AttributeColumn>
                );
              })}
            </Row>
            <Row style={{ height: "30%" }}>
              {attributes.attributes.map((trait, index) => {
                const getColor = (value: string) => {
                  if (value === "Common" || value === "?") return;
                  if (value === "Uncommon") return "#00ef81";
                  if (value === "Rare") return "#01d8ff";
                  if (value === "Legendary") return "#ffe401";
                  if (value === "Exotic") return "#f001ff";
                  if (value === "Honorary") return "#f001ff";
                };
                if (index >= 8) {
                  return (
                    <AttributeColumn>
                      <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                      <AttVal
                        style={{
                          color:
                            trait.trait_type === "Rarity Type" &&
                            getColor(trait.value),
                        }}
                      >
                        {trait.value}
                      </AttVal>
                      <RarityValue>
                        {(trait as any)?.frequency
                          ? "Rarity Score: " + (trait as any).frequency
                          : "Rarity Score: ?"}
                      </RarityValue>
                    </AttributeColumn>
                  );
                }
              })}
            </Row>
          </EmptyBox>
        </Row>
      )}
    </Column>
  );
};

const WarningText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 154px;
`;

const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;
  width: 23%;
  margin-left: 2%;
`;

const AttInd = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.05em;
`;

const AttVal = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
`;

const RarityValue = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const EmptyBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45.5vw;
  border-radius: 16px;
  padding-top: 2.5vw;
  padding-bottom: 1.5vw;
  padding-left: 3.75vw;
  padding-right: 0.75vw;
`;

const EmptyBox111BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45.5vw;
  height: 100%;
  background-image: url(${background111});
  background-size: cover;
  border-radius: 16px;
  padding-left: 3.75vw;
  padding-right: 0.75vw;
  opacity: 0.07;
`;

const StyledImg = styled.img`
  width: 360px;
  margin-right: 40px;
  border-radius: 16px;
`;

const TailorLink = styled.img`
  position: absolute;
  left: 12px;
  bottom: 12px;
  line-height: 60px;
  text-align: center;
  height: 50px;
  border: 2px solid rgba(249, 140, 31, 1);
  border-radius: 8px;
`;

const CatNumber = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
`;

const DummyBox = styled.div`
  height: 360px;
  width: calc(50vw + 400px);
  background-color: #40364a;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
`;

const ChainToggleBox = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  flex-direction: row;
`;

const ChainToggleText = styled.div<{
  activated: boolean;
  clickable: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  height: 20px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => (props.activated ? "#f98c1f" : "#bf6e32")};
  margin-top: 20px;
  margin-right: 10px;
  text-align: left;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #40364a;
  border-radius: 18px;
  width: 55vw;
  height: 72px;
`;

const SearchInput = styled.input`
  background-color: #40364a;
  border-radius: 18px;
  width: 90%;
  font-family: "Catamaran", sans-serif;
  font-size: 24px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
  }
  color: rgba(255, 255, 255, 0.5);
  border: 0;
  outline: none;
  margin-left: 24px;
  height: 72px;
`;

const SearchButton = styled.button`
  font-family: "Catamaran", sans-serif;
  height: 72px;
  width: 264px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  border-radius: 12px;
  background-color: #f98c1f;
  color: #271b32;
  cursor: pointer;
  &:hover {
    background-color: #bf6e32;
  }
  transition: background-color 0.2s ease-in-out;
`;

export default Search;
