import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import ethLogo from "./../../assets/logo/Ethereum.png";
import ftmLogo from "./../../assets/logo/Fantom.png";
import styled from "styled-components";

const CardFront: React.FC<any> = (props) => {
  return <FrontImage image={ethLogo} />;
};

const CardBack: React.FC<any> = () => {
  return <BackImage image={ftmLogo} />;
};

const FlipToggle: React.FC<any> = (props) => {
  const [flip, setFlip] = useState(false);

  const setFlipFromOutside = async (flipped: boolean) => {
    setFlip(flipped);
  };

  useEffect(() => {
    setFlipFromOutside(props.flipped);
  }, [props.flipped]);

  return (
    <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
      <div
        style={{
          width: "56px",
          height: "56px",
          perspective: "1000px",
        }}
      >
        <CardBack image={ftmLogo}></CardBack>
      </div>
      <div
        style={{
          width: "56px",
          height: "56px",
          perspective: "1000px",
        }}
      >
        <CardFront image={ethLogo}></CardFront>
      </div>
    </ReactCardFlip>
    // </div>
  );
};

const FrontImage = styled.div<{
  image: any;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 1s;
  border-color: rgb(218, 218, 218);
  background-image: url(${(props) => props.image});

  background-size: cover; /* scale the image to the largest size such that both its width and height can fit inside the content area */
  background-position: center; /* center the image */
  background-repeat: no-repeat; /* don't repeat the image */
  border-radius: 10px; /* set the border radius to 10px */
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  transition: opacity 0.2s ease-in-out;
`;

const BackImage = styled.div<{
  image: any;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 1s;
  border-color: rgb(218, 218, 218);
  background-image: url(${(props) => props.image});

  background-size: cover; /* scale the image to the largest size such that both its width and height can fit inside the content area */
  background-position: center; /* center the image */
  background-repeat: no-repeat; /* don't repeat the image */
  border-radius: 10px; /* set the border radius to 10px */
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  transition: opacity 0.2s ease-in-out;
`;
export default FlipToggle;
