import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import Row from "../../components/Row";
import { weiToUnit } from "../../utils/conversion";
import bckToken from "../../assets/logo/bcktoken.png";
import { JsonRpcProvider } from "@ethersproject/providers";
import { NavLink, useLocation } from "react-router-dom";
import { Contract } from "@ethersproject/contracts";

// @ts-ignore
import { abis } from "@space-tigers/contracts";

// here we always load main net because there is no need to show token details from testnet
const config = {
  supportedChains: [250],
  // Opera chain id
  chainId: "0xfa",
  // JSON-RPC endpoint
  rpc: "https://rpc.fantom.network/",
  // used in links pointing to fantom explorer
  explorerUrl: "https://ftmscan.com/",
  // used in links pointing to fantom explorer's transaction detail
  explorerTransactionPath: "transactions",
};
const rpc = new JsonRpcProvider(config.rpc);

const TokenPage: React.FC<any> = (props: any) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [totalSupply, setTotalSupply] = useState("0");

  const checkTotalSupplies = async () => {
    const bckTokenContract = new Contract(
      "0x38a37Ee5cAbf5D01aCB67c9E12feA421F9EF2694",
      abis.bck,
      rpc
    );
    const bckTotalSupply = await bckTokenContract.totalSupply();
    const numberWithDots = (x: number) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    const totalSupplyInt = Math.round(weiToUnit(bckTotalSupply));
    const totalSupplyDisplay = numberWithDots(totalSupplyInt);
    setTotalSupply(totalSupplyDisplay);
  };

  useEffect(() => {
    checkTotalSupplies();
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        height: "100%",
        backgroundColor: "#261B32",
      }}
    >
      <Column
        style={{
          alignItems: "center",
          marginLeft: "8vw",
          marginRight: "8vw",
          marginTop: "32px",
        }}
      >
        <BckToken src={bckToken} />
        <FirstWhiteText>Chain: Fantom</FirstWhiteText>
        <FirstWhiteText>Final Total supply: 3 333 333 tokens</FirstWhiteText>
        <FirstWhiteText>
          Current Total supply: {totalSupply} tokens
        </FirstWhiteText>
        <FirstWhiteText>Emission time: 2 years</FirstWhiteText>
        <FirstWhiteText>Daily emission*: 5 000 BCK</FirstWhiteText>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <StyledNavLink to={"/bank"} onClick={() => setCurrentPath("/bank")}>
            <GreenButton onClick={() => setCurrentPath("/bank")}>
              <ButtonText>TRADE</ButtonText>
            </GreenButton>
          </StyledNavLink>
          <a
            href="src/containers/Info/token#/swap?inputCurrency=FTM&outputCurrency=0x38a37ee5cabf5d01acb67c9e12fea421f9ef2694"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <GreenButton>
              <ButtonText>SPOOKYSWAP</ButtonText>
            </GreenButton>
          </a>
        </Row>
        <SecondWhiteText>
          Our development plans currently include GameFi and DAO and
        </SecondWhiteText>
        <SecondWhiteText>
          those are the aspects of our ecosystem that will have utility and
          usage for BCK token.
        </SecondWhiteText>
        <SecondWhiteText>We are open to form partnerships.</SecondWhiteText>
        <Column
          style={{
            marginTop: "24px",
            marginBottom: "24px",
            alignItems: "center",
          }}
        >
          <BoldGrayText>Launch premint allocation</BoldGrayText>
          <BoldGrayText>Airdrop to 333 holders: 211 875 (6.36%)</BoldGrayText>
          <BoldGrayText>Team: 88 888 (2.66%)</BoldGrayText>
          <BoldGrayText>Vault reserve: 10 000 (0.300%)</BoldGrayText>
          <BoldGrayText>Prize pool: 22 570 (0.67%)</BoldGrayText>
          <BoldGrayText>Marketing: 61 111 (1.83%)</BoldGrayText>
          <BoldGrayText>Initial liquidity: 50 000(1.5%)</BoldGrayText>
          <BoldGrayText>
            Launch premint total: 444 444 (13.46% of BCK total supply)
          </BoldGrayText>
        </Column>
        <SmallGrayText>
          * 20% from daily emission goes to development fund which will be used
          to cover upkeep costs,
        </SmallGrayText>
        <SmallGrayText>
          outsource smaller developments and to expand BCK team in the future
        </SmallGrayText>
      </Column>
    </div>
  );
};

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const BckToken = styled.img`
  height: 112px;
  width: 112px;
  margin-bottom: 24px;
`;

const GreenButton = styled.div<{}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  height: 24px;
  background: #01fd88;
  border-radius: 16px;
  margin-left: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonText = styled.div`
  display: flex;
  height: 24px;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #261b32;
`;

const FirstWhiteText = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const SecondWhiteText = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const BoldGrayText = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff50;
`;

const SmallGrayText = styled.div`
  position: relative;
  display: flex;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

export default TokenPage;
