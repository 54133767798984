import React, { useState, useEffect } from "react";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { JsonRpcProvider } from "@ethersproject/providers";
import { unitToWei, weiToUnit } from "../../utils/conversion";
import { BigNumber } from "@ethersproject/bignumber";
import { send } from "../../utils/transactions";
import styled from "styled-components";
import bckCoin from "./../../assets/bank/BCK.png";
import swap from "./../../assets/bank/swap.png";
import ftmCoin from "./../../assets/logo/Fantom.png";
import config from "../../config/config";

const Trade: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [wftm, setWftm] = useState(false);
  const [ftmBck, setFtmBck] = useState(true);
  const [exactToken, setExactToken] = useState("ftm");
  const [bckBalance, setBckBalance] = useState(0);
  const [ftmBalance, setFtmBalance] = useState(0);
  const [wftmBalance, setWftmBalance] = useState(0);
  const [bckAllowance, setBckAllowance] = useState(unitToWei("0"));
  const [ftmAllowance, setFtmAllowance] = useState(unitToWei("0"));
  const [bckTradeAmount, setBckTradeAmount] = useState("");
  const [ftmTradeAmount, setFtmTradeAmount] = useState("");
  const [ftmToBckValue, setFtmToBckValue] = useState(0);
  const [bckToFtmValue, setBckToFtmValue] = useState(0);
  const [slippage, setSlippage] = useState("0.5");
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const rpc = new JsonRpcProvider(config.rpc);

  const setBalances = async () => {
    const bckContract = walletContext.activeWallet.contracts.get("bckCall");
    const ftmContract = walletContext.activeWallet.contracts.get("wftmCall");
    const routerContract = walletContext.activeWallet.contracts.get(
      "sproutCall"
    );

    const bckBalance = await bckContract.balanceOf(
      walletContext.activeWallet.address
    );
    const wftmBalance = await ftmContract.balanceOf(
      walletContext.activeWallet.address
    );
    const ftmBalance = await rpc.getBalance(walletContext.activeWallet.address);

    // const bckFtm = await calculateFtmLiquidity("1");
    // const ftmBck = await calculateBckLiquidity("1");

    const getFtmAllowance = await ftmContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );
    const getBckAllowance = await bckContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );
    setFtmAllowance(getFtmAllowance);
    setBckAllowance(getBckAllowance);
    setFtmBalance(weiToUnit(ftmBalance));
    setBckBalance(weiToUnit(bckBalance));
    setWftmBalance(weiToUnit(wftmBalance));
  };

  const approveTrade = async () => {
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const slipUse = parseFloat(slippage) * 100;

    if (ftmBck) {
      let amountWei = unitToWei(ftmTradeAmount);
      if (exactToken === "bck") {
        amountWei = amountWei.mul(10000 + slipUse).div(10000);
      }
      const hash = await send(
        walletContext.activeWallet.provider,
        () => wftmContract.approve(routerContract.address, amountWei),
        dispatchTx
      );
      props.editTxStatus(true);
      setTxHash(hash);
    }
    if (!ftmBck) {
      let amountWei = unitToWei(bckTradeAmount);
      if (exactToken === "ftm") {
        amountWei = amountWei.mul(10000 + slipUse).div(10000);
      }
      const hash = await send(
        walletContext.activeWallet.provider,
        () => bckContract.approve(routerContract.address, amountWei),
        dispatchTx
      );
      props.editTxStatus(true);
      setTxHash(hash);
    }
  };

  const approveMax = async () => {
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const maxAmount = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );

    if (ftmBck) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () => wftmContract.approve(routerContract.address, maxAmount),
        dispatchTx
      );
      props.editTxStatus(true);
      return setTxHash(hash);
    }
    if (!ftmBck) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () => bckContract.approve(routerContract.address, maxAmount),
        dispatchTx
      );
      props.editTxStatus(true);
      return setTxHash(hash);
    }
  };

  const setTradeValues = async () => {
    const routerContract = walletContext.activeWallet.contracts.get(
      "sproutCall"
    );
    const pairContract = walletContext.activeWallet.contracts.get("splpCall");
    const reserves = await pairContract.getReserves();
    const amountIn = unitToWei("1");
    const exactBckforFtm = await routerContract.getAmountOut(
      amountIn,
      reserves[1],
      reserves[0]
    );
    const nr1 = weiToUnit(exactBckforFtm);
    setBckToFtmValue(nr1);

    const exactFtmForBck = await routerContract.getAmountOut(
      amountIn,
      reserves[0],
      reserves[1]
    );
    const nr2 = weiToUnit(exactFtmForBck);
    setFtmToBckValue(nr2);
  };

  const setOtherSide = async (amount: string, from: string) => {
    const routerContract = walletContext.activeWallet.contracts.get(
      "sproutCall"
    );
    const pairContract = walletContext.activeWallet.contracts.get("splpCall");
    const reserves = await pairContract.getReserves();
    const amountIn = unitToWei(amount);
    if (amount === "" || amount === "0") {
      if (from === "bck") {
        return setFtmTradeAmount("");
      }
      if (from === "ftm") {
        return setBckTradeAmount("");
      }
    }
    if (!ftmBck) {
      if (from === "bck") {
        const exactBckforFtm = await routerContract.getAmountOut(
          amountIn,
          reserves[1],
          reserves[0]
        );
        const nr = weiToUnit(exactBckforFtm);
        return setFtmTradeAmount(nr.toString());
      }
      if (from === "ftm") {
        const bckForExactFtm = await routerContract.getAmountIn(
          amountIn,
          reserves[1],
          reserves[0]
        );
        const nr = weiToUnit(bckForExactFtm);
        return setBckTradeAmount(nr.toString());
      }
    }

    if (ftmBck) {
      if (from === "bck") {
        const ftmForExactBck = await routerContract.getAmountIn(
          amountIn,
          reserves[0],
          reserves[1]
        );
        const nr = weiToUnit(ftmForExactBck);
        return setFtmTradeAmount(nr.toString());
      }
      if (from === "ftm") {
        const exactFtmForBck = await routerContract.getAmountOut(
          amountIn,
          reserves[0],
          reserves[1]
        );
        const nr = weiToUnit(exactFtmForBck);
        return setBckTradeAmount(nr.toString());
      }
    }
  };

  const swapToken = async () => {
    if (bckTradeAmount === "" || bckTradeAmount === "0") {
      return;
    }
    const pairContract = walletContext.activeWallet.contracts.get("splp");
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const ftmContract = walletContext.activeWallet.contracts.get("wftm");

    const reserves = await pairContract.getReserves();
    const slipUse = parseFloat(slippage ? slippage : "0") * 100;

    const bckReserve = reserves[1];
    const ftmReserve = reserves[0];
    const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

    if (ftmBck) {
      if (exactToken === "ftm") {
        if (wftm) {
          const resp = await routerContract.getAmountOut(
            unitToWei(ftmTradeAmount),
            ftmReserve,
            bckReserve
          );

          const minDesiredReturn = resp.mul(10000 - slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapExactTokensForTokens(
                unitToWei(ftmTradeAmount),
                minDesiredReturn,
                [ftmContract.address, bckContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
        if (!wftm) {
          const resp = await routerContract.getAmountOut(
            unitToWei(ftmTradeAmount),
            ftmReserve,
            bckReserve
          );
          const minDesiredReturn = resp.mul(10000 - slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapExactETHForTokens(
                minDesiredReturn,
                [ftmContract.address, bckContract.address],
                walletContext.activeWallet.address,
                deadline,
                { value: unitToWei(ftmTradeAmount) }
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
      }
      if (exactToken === "bck") {
        if (wftm) {
          const resp = await routerContract.getAmountIn(
            unitToWei(bckTradeAmount),
            ftmReserve,
            bckReserve
          );
          const maxDesiredInput = resp.mul(10000 + slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapTokensForExactTokens(
                unitToWei(bckTradeAmount),
                maxDesiredInput,
                [ftmContract.address, bckContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }

        if (!wftm) {
          const resp = await routerContract.getAmountIn(
            unitToWei(bckTradeAmount),
            ftmReserve,
            bckReserve
          );
          const maxDesiredInput = resp.mul(10000 + slipUse).div(10000);

          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapETHForExactTokens(
                unitToWei(bckTradeAmount),
                [ftmContract.address, bckContract.address],
                walletContext.activeWallet.address,
                deadline,
                { value: maxDesiredInput }
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
      }
    }

    if (!ftmBck) {
      if (exactToken === "ftm") {
        if (wftm) {
          const resp = await routerContract.getAmountIn(
            unitToWei(ftmTradeAmount),
            bckReserve,
            ftmReserve
          );
          const maxDesiredInput = resp.mul(10000 + slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapTokensForExactTokens(
                unitToWei(ftmTradeAmount),
                maxDesiredInput,
                [bckContract.address, ftmContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
        if (!wftm) {
          const resp = await routerContract.getAmountIn(
            unitToWei(ftmTradeAmount),
            bckReserve,
            ftmReserve
          );
          const maxDesiredInput = resp.mul(10000 + slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapTokensForExactETH(
                unitToWei(ftmTradeAmount),
                maxDesiredInput,
                [bckContract.address, ftmContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
      }
      if (exactToken === "bck") {
        if (wftm) {
          const resp = await routerContract.getAmountOut(
            unitToWei(bckTradeAmount),
            bckReserve,
            ftmReserve
          );
          const minDesiredReturn = resp.mul(10000 - slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapExactTokensForTokens(
                unitToWei(bckTradeAmount),
                minDesiredReturn,
                [bckContract.address, ftmContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }

        if (!wftm) {
          const resp = await routerContract.getAmountOut(
            unitToWei(bckTradeAmount),
            bckReserve,
            ftmReserve
          );
          const minDesiredReturn = resp.mul(10000 - slipUse).div(10000);
          const hash = await send(
            walletContext.activeWallet.provider,
            () =>
              routerContract.swapExactTokensForETH(
                unitToWei(bckTradeAmount),
                minDesiredReturn,
                [bckContract.address, ftmContract.address],
                walletContext.activeWallet.address,
                deadline
              ),
            dispatchTx
          );
          props.editTxStatus(true);
          setTxHash(hash);
        }
      }
    }

    setBckTradeAmount("");
    setFtmTradeAmount("");
  };

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      props.setTopBalances();
      setBalances();
      setTradeValues();
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (tx?.status === "completed") {
      props.setTopBalances();
      props.editTxStatus(false);
      setBalances();
      setTradeValues();
    }
  }, [tx]);

  useEffect(() => {
    setOtherSide(
      exactToken === "ftm" ? ftmTradeAmount : bckTradeAmount,
      exactToken
    );
  }, [ftmBck]);

  return (
    <LiquidityColumn>
      <Header>
        TRADE -{" "}
        {ftmBck
          ? wftm
            ? "wFTM for BCK"
            : "FTM for BCK"
          : wftm
          ? "BCK for wFTM"
          : "BCK for FTM"}
      </Header>
      {ftmBck ? (
        <BoxRow>
          <Box>
            <TopRow>
              <TokenRow>
                <FtmLogo src={ftmCoin} />
                <TokenName>{wftm ? "w" : ""}FTM</TokenName>
              </TokenRow>
              <PercentageRow>
                <Percentages
                  onClick={() => {
                    setFtmTradeAmount(
                      !wftm
                        ? (ftmBalance * 0.25).toString()
                        : (wftmBalance * 0.25).toString()
                    );
                    setExactToken("ftm");
                    setOtherSide(
                      !wftm
                        ? (ftmBalance * 0.25).toString()
                        : (wftmBalance * 0.25).toString(),
                      "ftm"
                    );
                  }}
                >
                  25%
                </Percentages>
                <Percentages
                  onClick={() => {
                    setFtmTradeAmount(
                      !wftm
                        ? (ftmBalance * 0.5).toString()
                        : (wftmBalance * 0.5).toString()
                    );
                    setExactToken("ftm");
                    setOtherSide(
                      !wftm
                        ? (ftmBalance * 0.5).toString()
                        : (wftmBalance * 0.5).toString(),
                      "ftm"
                    );
                  }}
                >
                  50%
                </Percentages>
                <Percentages
                  onClick={() => {
                    setFtmTradeAmount(
                      !wftm ? ftmBalance.toString() : wftmBalance.toString()
                    );
                    setExactToken("ftm");
                    setOtherSide(
                      !wftm ? ftmBalance.toString() : wftmBalance.toString(),
                      "ftm"
                    );
                  }}
                >
                  MAX
                </Percentages>
              </PercentageRow>
            </TopRow>
            <InputBox>
              <AmountToken
                placeholder="0"
                value={ftmTradeAmount.substring(0, 11)}
                onChange={(event) => {
                  setFtmTradeAmount(event.target.value.replace(",", "."));
                  setExactToken("ftm");
                  setOtherSide(event.target.value.replace(",", "."), "ftm");
                }}
              />
            </InputBox>
            <ToggleBalanceRow>
              <ToggleBox onClick={() => setWftm(!wftm)}>
                {!wftm ? "w" : ""}FTM
              </ToggleBox>
              <BalanceBox>
                BALANCE:{" "}
                {(wftm ? wftmBalance : ftmBalance).toString().substring(0, 9)}
              </BalanceBox>
            </ToggleBalanceRow>
          </Box>
          <Switch onClick={() => setFtmBck(!ftmBck)}>
            <SwitchImage src={swap} />
          </Switch>
          <Box>
            <TopRow>
              <TokenRow>
                <BckLogo src={bckCoin} />
                <TokenName>BCK</TokenName>
              </TokenRow>
              <PercentageRow></PercentageRow>
            </TopRow>
            <InputBox>
              <AmountToken
                placeholder="0"
                value={bckTradeAmount.substring(0, 11)}
                onChange={(event) => {
                  setBckTradeAmount(event.target.value.replace(",", "."));
                  setExactToken("bck");
                  setOtherSide(event.target.value.replace(",", "."), "bck");
                }}
              />
            </InputBox>
            <ToggleBalanceRow>
              <ToggleBox></ToggleBox>
              <BalanceBox>BALANCE: {bckBalance}</BalanceBox>
            </ToggleBalanceRow>
          </Box>
        </BoxRow>
      ) : (
        <BoxRow>
          <Box>
            <TopRow>
              <TokenRow>
                <BckLogo src={bckCoin} />
                <TokenName>BCK</TokenName>
              </TokenRow>
              <PercentageRow>
                <Percentages
                  onClick={() => {
                    setBckTradeAmount(
                      bckBalance ? (bckBalance * 0.25).toString() : "0"
                    );
                    setExactToken("bck");
                    setOtherSide((bckBalance * 0.25).toString(), "bck");
                  }}
                >
                  25%
                </Percentages>
                <Percentages
                  onClick={() => {
                    setBckTradeAmount(
                      bckBalance ? (bckBalance * 0.5).toString() : "0"
                    );
                    setExactToken("bck");
                    setOtherSide((bckBalance * 0.5).toString(), "bck");
                  }}
                >
                  50%
                </Percentages>
                <Percentages
                  onClick={() => {
                    setBckTradeAmount(bckBalance ? bckBalance.toString() : "0");
                    setExactToken("bck");
                    setOtherSide(bckBalance.toString(), "bck");
                  }}
                >
                  MAX
                </Percentages>
              </PercentageRow>
            </TopRow>
            <InputBox>
              <AmountToken
                placeholder="0"
                value={bckTradeAmount.substring(0, 11)}
                onChange={(event) => {
                  setBckTradeAmount(event.target.value.replace(",", "."));
                  setExactToken("bck");
                  setOtherSide(event.target.value.replace(",", "."), "bck");
                }}
              />
            </InputBox>
            <ToggleBalanceRow>
              <ToggleBox></ToggleBox>
              <BalanceBox>BALANCE: {bckBalance}</BalanceBox>
            </ToggleBalanceRow>
          </Box>
          <Switch onClick={() => setFtmBck(!ftmBck)}>
            <SwitchImage src={swap} />
          </Switch>
          <Box>
            <TopRow>
              <TokenRow>
                <FtmLogo src={ftmCoin} />
                <TokenName>{wftm ? "w" : ""}FTM</TokenName>
              </TokenRow>
              <PercentageRow></PercentageRow>
            </TopRow>
            <InputBox>
              <AmountToken
                placeholder="0"
                value={ftmTradeAmount.substring(0, 11)}
                onChange={(event) => {
                  setFtmTradeAmount(event.target.value.replace(",", "."));
                  setExactToken("ftm");
                  setOtherSide(event.target.value.replace(",", "."), "ftm");
                }}
              />
            </InputBox>
            <ToggleBalanceRow>
              <ToggleBox onClick={() => setWftm(!wftm)}>
                {!wftm ? "w" : ""}FTM
              </ToggleBox>
              <BalanceBox>
                BALANCE:{" "}
                {(wftm ? wftmBalance : ftmBalance).toString().substring(0, 9)}
              </BalanceBox>
            </ToggleBalanceRow>
          </Box>
        </BoxRow>
      )}
      <BottomRow>
        <PriceInfo>
          1 FTM = {ftmToBckValue} BCK
          <br />1 BCK = {bckToFtmValue} FTM
        </PriceInfo>
        {(ftmBck
          ? exactToken === "ftm"
            ? !wftm || ftmAllowance.gte(unitToWei(ftmTradeAmount))
            : !wftm ||
              ftmAllowance.gte(
                unitToWei(ftmTradeAmount)
                  .mul(10000 + parseFloat(slippage ? slippage : "0") * 100)
                  .div(10000)
              )
          : exactToken === "ftm"
          ? bckAllowance.gte(
              unitToWei(bckTradeAmount)
                .mul(10000 + parseFloat(slippage ? slippage : "0") * 100)
                .div(10000)
            )
          : bckAllowance.gte(unitToWei(bckTradeAmount))) && (
          <ApproveColumn>
            <ApproveButton
              onClick={() => swapToken()}
              disabled={
                tx?.status === "pending" ||
                (!ftmBck &&
                  (exactToken === "bck"
                    ? parseFloat(bckTradeAmount)
                    : parseFloat(bckTradeAmount) *
                      (parseFloat(slippage) / 100 + 1)) > bckBalance) ||
                (ftmBck &&
                  (wftm
                    ? (exactToken === "ftm"
                        ? parseFloat(ftmTradeAmount)
                        : parseFloat(ftmTradeAmount) *
                          (parseFloat(slippage) / 100 + 1)) > wftmBalance
                    : (exactToken === "ftm"
                        ? parseFloat(ftmTradeAmount)
                        : parseFloat(ftmTradeAmount) *
                          (parseFloat(slippage) / 100 + 1)) > ftmBalance))
              }
            >
              TRAD{tx?.status === "pending" ? "ING..." : "E"}
            </ApproveButton>
          </ApproveColumn>
        )}

        {(ftmBck
          ? exactToken === "ftm"
            ? wftm && ftmAllowance.lt(unitToWei(ftmTradeAmount))
            : wftm &&
              ftmAllowance.lt(
                unitToWei(ftmTradeAmount)
                  .mul(10000 + parseFloat(slippage ? slippage : "0") * 100)
                  .div(10000)
              )
          : exactToken === "ftm"
          ? bckAllowance.lt(
              unitToWei(bckTradeAmount)
                .mul(10000 + parseFloat(slippage ? slippage : "0") * 100)
                .div(10000)
            )
          : bckAllowance.lt(unitToWei(bckTradeAmount))) && (
          <ApproveColumn>
            <ApproveButton
              onClick={() => approveTrade()}
              disabled={tx?.status === "pending"}
            >
              APPROV{tx?.status === "pending" ? "ING..." : "E"}
            </ApproveButton>

            {(!ftmBck || wftm) && (
              <ApproveMax
                onClick={() => approveMax()}
                disabled={tx?.status === "pending"}
              >
                MAX
              </ApproveMax>
            )}
          </ApproveColumn>
        )}

        <SlippageRow>
          <SlippageText>Slippage tolerance</SlippageText>
          <SlippageInputBox>
            <SlippageInput
              value={slippage}
              onChange={(event) => {
                setSlippage(event.target.value.replace(",", "."));
              }}
            />
          </SlippageInputBox>
          <SlippageText>%</SlippageText>
        </SlippageRow>
      </BottomRow>
    </LiquidityColumn>
  );
};

const LiquidityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 280px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 32px;
  background: rgba(51, 255, 136, 0.1);
  border-radius: 2px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #9affc4;
  cursor: default;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 144px;
  margin-bottom: 24px;
`;

const Switch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 46px;
  border: 1px solid rgba(51, 255, 136, 0.4);
  background-color: rgba(51, 255, 136, 0.15);
  cursor: pointer;
  box-shadow: none;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &&:hover {
    background-color: rgba(51, 255, 136, 0.25);
    box-shadow: 0px 0px 8px #33FF88;
`;

const SwitchImage = styled.img`
  height: 28px;
  width: 28px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 232px;
  height: 120px;
  padding: 12px 16px 11px 16px;
  background: rgba(51, 255, 136, 0.1);
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;
`;

const TokenRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
`;

const FtmLogo = styled.img`
  height: 24px;
  widht: 24px;
  margin-right: 8px;
`;

const BckLogo = styled.img`
  height: 26.55px;
  widht: 26.55px;
  margin-right: 8px;
`;

const TokenName = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #9affc4;
  cursor: default;
`;

const PercentageRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
`;

const Percentages = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  text-transform: uppercase;
  color: #33ff88;
  cursor: pointer;
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 196px;
  padding: 18px;
  margin-bottom: 11px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const AmountToken = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 26px;
  width: 196px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  text-transform: uppercase;
  color: #9affc4;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9affc4;
    opacity: 1; /* Firefox */
  }
`;

const ToggleBalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  color: #33ff88;
  cursor: pointer;
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const BalanceBox = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #9affd0;
  cursor: default;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  width: 210px;
  cursor: default;
`;

const SlippageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 14px;
  width: 210px;
`;

const SlippageText = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
  color: #9affc4;
  cursor: default;
`;

const SlippageInputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 16px;
  margin: 0px 8px;
  background: rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(51, 255, 136, 0.25);
  border-radius: 4px;
`;

const SlippageInput = styled.input`
  width: 24px;
  height: 14px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #33ff88;
`;

const ApproveColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApproveButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  width: 208px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  margin-bottom: 8px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.25)"}
`;

const ApproveMax = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

export default Trade;
