import React, { useState, useEffect } from "react";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { unitToWei, weiToUnit } from "../../utils/conversion";
import { BigNumber } from "@ethersproject/bignumber";
import { send } from "../../utils/transactions";
import styled from "styled-components";
import farmLogoBck from "./../../assets/bank/FF_logo_green.png";
import config from "../../config/config";

const FamFarm: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [liqAllowance, setLiqAllowance] = useState(unitToWei("0"));
  const [liquidityBalance, setLiquidityBalance] = useState(0);
  const [farmAllowance, setFarmAllowance] = useState(unitToWei("0"));
  const [farmBalance, setFarmBalance] = useState(0);
  const [stakedInFarm, setStakedInFarm] = useState(0);
  const [pendingBck, setPendingBck] = useState(0);
  const [swapInput, setSwapInput] = useState("");
  const [stakeInput, setStakeInput] = useState("");
  const [bckPerDay, setBckPerDay] = useState(0);
  const [txHash, setTxHash] = useState(null);
  const [txType, setTxType] = useState("");
  const [box, setBox] = useState("");
  const tx = transaction[txHash];

  const getPendingBck = async () => {
    const masterChefContract = await walletContext.activeWallet.contracts.get(
      "mchefCall"
    );
    const getPendingBck = await masterChefContract.pendingBck(
      2,
      walletContext.activeWallet.address
    );
    setPendingBck(weiToUnit(getPendingBck));
  };

  const setBalances = async (allowance: boolean) => {
    const spookyLpContract = walletContext.activeWallet.contracts.get(
      "splpCall"
    );
    const famFarmContract = walletContext.activeWallet.contracts.get(
      "famFarmCall"
    );
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchefCall"
    );

    const getLiqAllowance = await spookyLpContract.allowance(
      walletContext.activeWallet.address,
      famFarmContract.address
    );

    const getFarmAllowance = await famFarmContract.allowance(
      walletContext.activeWallet.address,
      masterChefContract.address
    );

    setLiqAllowance(getLiqAllowance);
    setFarmAllowance(getFarmAllowance);

    if (allowance) return;

    const liqBalance = await spookyLpContract.balanceOf(
      walletContext.activeWallet.address
    );
    const getFarmBalance = await famFarmContract.balanceOf(
      walletContext.activeWallet.address
    );

    const userInfo = await masterChefContract.userInfo(
      2,
      walletContext.activeWallet.address
    );

    const getPendingBck = await masterChefContract.pendingBck(
      2,
      walletContext.activeWallet.address
    );

    setLiquidityBalance(weiToUnit(liqBalance));
    setFarmBalance(weiToUnit(getFarmBalance));
    setStakedInFarm(weiToUnit(userInfo.amount));
    setPendingBck(weiToUnit(getPendingBck));
    calculateDailyBck();
  };

  const calculateDailyBck = async () => {
    const famFarmContract = walletContext.activeWallet.contracts.get(
      "famFarmCall"
    );
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchefCall"
    );
    // const rewarderReward = await rewarderContract.rewardPerSecond();
    const mchefReward = await masterChefContract.bckPerBlock();
    const poolPercentage = await masterChefContract.POOL_PERCENTAGE();
    const dailySeconds = 86400;
    const dailyPoolTokens = mchefReward
      .mul(poolPercentage)
      .div(1000)
      .mul(dailySeconds);
    const totalAllocationPoints = await masterChefContract.totalAllocPoint();
    const allocationPointFamFarm = await masterChefContract
      .poolInfo(2)
      .then((result: any) => {
        return result.allocPoint;
      });
    const dailyDistributionFamFarm = dailyPoolTokens
      .mul(allocationPointFamFarm)
      .div(totalAllocationPoints);

    const userAmountInFamFarmPool = await masterChefContract
      .userInfo(2, walletContext.activeWallet.address)
      .then((result: any) => {
        return result.amount;
      });

    const totalAmountInFamFarmPool = await famFarmContract.balanceOf(
      masterChefContract.address
    );
    const totalFamFarmUserDaily = dailyDistributionFamFarm
      .mul(userAmountInFamFarmPool)
      .div(totalAmountInFamFarmPool);
    setBckPerDay(weiToUnit(totalFamFarmUserDaily));
  };

  const approveLiquidity = async (max: boolean) => {
    const pairContract = walletContext.activeWallet.contracts.get("splp");
    const famFarmContract = walletContext.activeWallet.contracts.get("famFarm");
    const maxAmount = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );

    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        pairContract.approve(
          famFarmContract.address,
          max ? maxAmount : unitToWei(swapInput)
        ),
      dispatchTx
    );
    props.editTxStatus(true);
    setBox("top");
    setTxType("approve");
    return setTxHash(hash);
  };

  const approveFarmToken = async (max: boolean) => {
    // approve masterchef for famFarm
    const famFarmContract = walletContext.activeWallet.contracts.get("famFarm");
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );
    const maxAmount = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );

    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        famFarmContract.approve(
          masterChefContract.address,
          max ? maxAmount : unitToWei(stakeInput)
        ),
      dispatchTx
    );
    props.editTxStatus(true);
    setBox("bottom");
    setTxType("approve");
    return setTxHash(hash);
  };

  const swapLiqForFarm = async () => {
    if (swapInput === "" || swapInput === "0") {
      return;
    }
    const famFarmContract = walletContext.activeWallet.contracts.get("famFarm");
    const hash = await send(
      walletContext.activeWallet.provider,
      () => famFarmContract.deposit(unitToWei(swapInput)),
      dispatchTx
    );
    setSwapInput("");
    props.editTxStatus(true);
    setTxType("deposit");
    return setTxHash(hash);
  };

  const depositFarmTokenToFarm = async () => {
    if (stakeInput === "" || stakeInput === "0") {
      return;
    }
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );

    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        masterChefContract.deposit(
          2,
          unitToWei(stakeInput),
          walletContext.activeWallet.address
        ),
      dispatchTx
    );
    setStakeInput("");
    props.editTxStatus(true);
    setTxType("farm");
    return setTxHash(hash);
  };

  const harvestBck = async () => {
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );

    const hash = await send(
      walletContext.activeWallet.provider,
      () => masterChefContract.harvest(2, walletContext.activeWallet.address),
      dispatchTx
    );
    props.editTxStatus(true);
    setTxType("harvest");
    return setTxHash(hash);
  };

  const numberWithDots = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const interval = setInterval(getPendingBck, 20000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      props.setTopBalances();
      setBalances(false);
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (tx?.status === "completed") {
      props.editTxStatus(false);
    }
    if (tx?.status === "completed" && txType === "approve") {
      setBalances(true);
      setBox("");
      setTxType("");
    }
    if (tx?.status === "completed" && txType === "deposit") {
      setBalances(false);
      setTxType("");
    }
    if (tx?.status === "completed" && txType === "farm") {
      setBalances(false);
      setTxType("");
      props.calculateAPR();
    }
    if (tx?.status === "completed" && txType === "harvest") {
      setBalances(false);
      setTxType("");
      props.setTopBalances();
    }
  }, [tx]);

  return (
    <LiquidityColumn>
      <HeaderRow>
        <AprEtc>
          APR:{" "}
          {numberWithDots(
            props.famFarmApr >= 1000
              ? Math.floor(props.famFarmApr)
              : props.famFarmApr
          )}
          % TVL: {numberWithDots(props.famFarmTvl)} USD
        </AprEtc>
        <NameRow>
          <FarmLogo src={farmLogoBck} />
          <FarmName>BIG CAT FAMILY FARM</FarmName>
        </NameRow>
      </HeaderRow>
      <BlockRow>
        <StakeColumn>
          <StakeBlock>
            <StakeBlockTitleRow>
              <StakeBlockTitle>bckLP for bckFF</StakeBlockTitle>
              <MaxButton
                onClick={() => setSwapInput(liquidityBalance.toString())}
              >
                MAX
              </MaxButton>
            </StakeBlockTitleRow>
            <StakeRow>
              <StakeInputBackground>
                <StakeInput
                  placeholder="0"
                  value={swapInput.substring(0, 9)}
                  onChange={(event) => {
                    setSwapInput(event.target.value.replace(",", "."));
                  }}
                />
              </StakeInputBackground>
              {liqAllowance.gte(unitToWei(swapInput)) && (
                <StakeButton
                  onClick={() => swapLiqForFarm()}
                  disabled={
                    unitToWei(swapInput).gt(
                      unitToWei(liquidityBalance.toString())
                    ) || txType === "deposit"
                  }
                  style={{ fontSize: "16px" }}
                >
                  DEPOSIT
                </StakeButton>
              )}
              {liqAllowance.lt(unitToWei(swapInput)) && (
                <StakeButton
                  onClick={() => approveLiquidity(false)}
                  disabled={txType === "approve" && box === "top"}
                  style={{ fontSize: "16px" }}
                >
                  APPROVE
                </StakeButton>
              )}
            </StakeRow>
            <BalanceRow>
              <BalanceValue>
                Balance: {liquidityBalance.toString().substring(0, 7)}
              </BalanceValue>

              {liqAllowance.gte(unitToWei(swapInput)) && (
                <FarmForLP>
                  1 bckFF = {props.ratio.toString().substring(0, 6)} LP
                </FarmForLP>
              )}
              {liqAllowance.lt(unitToWei(swapInput)) && (
                <MaxApproveButton
                  onClick={() => approveLiquidity(true)}
                  disabled={txType === "approve" && box === "top"}
                >
                  APPROVE MAX
                </MaxApproveButton>
              )}
            </BalanceRow>
          </StakeBlock>
          <StakeBlock>
            <StakeBlockTitleRow>
              <StakeBlockTitle>STAKE bckFF</StakeBlockTitle>
              <MaxButton onClick={() => setStakeInput(farmBalance.toString())}>
                MAX
              </MaxButton>
            </StakeBlockTitleRow>
            <StakeRow>
              <StakeInputBackground>
                <StakeInput
                  placeholder="0"
                  value={stakeInput.substring(0, 9)}
                  onChange={(event) => {
                    setStakeInput(event.target.value.replace(",", "."));
                  }}
                />
              </StakeInputBackground>
              {farmAllowance.gte(unitToWei(stakeInput)) && (
                <StakeButton
                  onClick={() => depositFarmTokenToFarm()}
                  disabled={
                    unitToWei(stakeInput).gt(
                      unitToWei(farmBalance.toString())
                    ) || txType === "farm"
                  }
                >
                  STAKE
                </StakeButton>
              )}
              {farmAllowance.lt(unitToWei(stakeInput)) && (
                <StakeButton
                  onClick={() => approveFarmToken(false)}
                  disabled={txType === "approve" && box === "bottom"}
                  style={{ fontSize: "16px" }}
                >
                  APPROVE
                </StakeButton>
              )}
            </StakeRow>
            <BalanceRow>
              <BalanceValue>
                Balance: {farmBalance.toString().substring(0, 8)}
              </BalanceValue>
              {farmAllowance.gte(unitToWei(stakeInput)) && <div></div>}
              {farmAllowance.lt(unitToWei(stakeInput)) && (
                <MaxApproveButton
                  onClick={() => approveFarmToken(true)}
                  disabled={txType === "approve" && box === "bottom"}
                >
                  APPROVE MAX
                </MaxApproveButton>
              )}
            </BalanceRow>
          </StakeBlock>
        </StakeColumn>
        <FarmInfoColumn>
          <NumberRow>
            <NumberBox>
              <NumberAmount>
                {stakedInFarm >= 10000
                  ? Math.floor(stakedInFarm).toString()
                  : stakedInFarm.toString().substring(0, 6)}
              </NumberAmount>
            </NumberBox>
            <NumberBox>
              <NumberAmount>
                {bckPerDay.toString().substring(0, 6)}
              </NumberAmount>
            </NumberBox>
          </NumberRow>
          <DescriptionRow>
            <DescriptionValue>STAKED bckFF</DescriptionValue>
            <DescriptionValue>BCK in 24h</DescriptionValue>
          </DescriptionRow>
          <HarvestableAmountRow>
            <HarvestableAmount>
              {pendingBck.toString().substring(0, 6)} BCK earned
            </HarvestableAmount>
          </HarvestableAmountRow>
          <HarvestButton
            onClick={() => harvestBck()}
            disabled={txType === "harvest"}
          >
            HARVEST
          </HarvestButton>
          <UnstakeRow>
            <UnstakeButton onClick={() => props.setView("withdraw", "famFarm")}>
              UNSTAKE FARM
            </UnstakeButton>
          </UnstakeRow>
        </FarmInfoColumn>
      </BlockRow>
    </LiquidityColumn>
  );
};

const LiquidityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 262px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 18px 32px 32px 32px;
  background: rgba(51, 255, 136, 0.1);
  border-radius: 2px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`;

const AprEtc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  height: 20px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  color: #00ffd8;
  text-align: left;
  cursor: default;
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const FarmLogo = styled.img`
  height: 24px;
  width: 24px;
  margin-top: -4px;
`;

const FarmName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  width: 207px;
  height: 20px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  color: #9affc4;
  text-align: left;
  margin-left: 8px;
  cursor: default;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 608px;
  height: 224px;
`;

const StakeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
  height: 224px;
`;

const StakeBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  height: 84px;
  padding: 12px 20px 8px 20px;
  background: rgba(51, 255, 136, 0.1);
`;

const StakeBlockTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 18px;
  margin-bottom: 6px;
`;

const StakeBlockTitle = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: #9affc4;
  cursor: default;
`;

const MaxButton = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
  cursor: pointer;
  transition: text-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const MaxApproveButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  text-align: right;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: text-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const StakeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 36px;
  margin-bottom: 8px;
`;

const StakeInputBackground = styled.div`
  display: flex;
  flex-direction: row;
  width: 160px;
  height: 36px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const StakeInput = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 160px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #9affc4;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9affc4;
    opacity: 1; /* Firefox */
`;

const StakeButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 34px;
  width: 88px;
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border-radius: 2px;
  margin-bottom: 8px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
  }
`;

const BalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 16px;
`;

const BalanceValue = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #9affd0;
  cursor: default;
`;

const FarmForLP = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #9affd0;
  cursor: default;
`;

const FarmInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 204px;
  padding: 12px 20px 8px 20px;
  background: rgba(51, 255, 136, 0.1);
`;

const NumberRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 64px;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 118px;
  height: 64px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const NumberAmount = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  color: #9affc4;
  cursor: default;
`;

const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 16px;
  margin-top: 8px;
`;

const DescriptionValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 118px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9affc4;
  cursor: default;
`;

const HarvestableAmountRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 56px;
`;

const HarvestableAmount = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #ffd864;
  text-shadow: 0px 0px 4px #ffd864;
  cursor: default;
`;

const HarvestButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 36px;
  width: 256px;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  font-family: Catamaran;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
  }
`;

const UnstakeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 256px;
  height: 16px;
  margin-top: 8px;
`;

const UnstakeButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #00ffd8;
  cursor: pointer;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #00ffd8;
  }
`;

// 0xa5b041125d5660af3566d40866f70f24b14ae6aa bckToken
// 0x2A449C80C4858bb0A493C4106d2Bef6f5d2f138f devAddress
// 0x105fb85c1574FE04c61a1Ed36d3E692e0b47C9e4 treasuryAddress
// 0x7380912dC13ca8e5ADf51d9B9CB8231d52B1ca4d MasterChef
// 0xd867553e75f2c957689a558d3bda778f037a7311 Rewarder
// 30000000000000000 tokensPerSecond

export default FamFarm;
