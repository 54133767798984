/**
 * See all ids below
 * https://ethereum.stackexchange.com/questions/17051/how-to-select-a-network-id-or-is-there-a-list-of-network-ids
 */
export const MAINNET_ID = 1;
export const SEPOLIA_ID = 11155111;
export const FANTOM_MAIN_ID = 250;
export const FANTOM_TEST_ID = 4002;

export default {
  [FANTOM_MAIN_ID]: {
    contracts: {
      spookyfactory: "0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3",
      spookyrouter: "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
      splp: "0xE4C657a79eEb9D40f25da7A787FDFA645C321683",
      mchef: "0x3574b1Fc4aA2C5f988cb373691E3e777F5Cd64c5",
      drivein: "0xE4bCc3E7417E8d2CC55B2b9741Fe8D5A6eb185D0",
      midterm: "0x4Aea6a2e9aE9b6f11B3758CE44A07d403Ba38452",
      famfarm: "0x70C855929e1F70E8a79E609A0fa3f9174bc02dDf",
      xbck: "0x9a166BF54c3A81F62f1AC6ff9E3C93515EcFfFB3",
      rarity: "0xe9eCC77A3fAcd03c6c0C3eB80Eb0cBfF97609f4B",
      airdrop: "0x0a6CeCbBD2CfbefCd15192Ad857C3E2Bd90E83B5",
      whitelist: "0x768A7978027fa04D653968c9CFE3c5Dc2eb6E4cd",
      bcklionfactory: "0xDcd75728136282Bc3B23dF6917169386D26Eca82",
      bck222: "0x8b58dE44791446F05Cf5087fc2d94bFcb0D3F44F",
    },
    tokens: {
      AUMAN: "0x5dbc2a8b01b7e37dfd7850237a3330c9939d6457",
      BUMAN: "0x8c2fcd5d857ee9aa19d1a27ae81ab1129385e3ac",
      BCK: "0x38a37Ee5cAbf5D01aCB67c9E12feA421F9EF2694",
      BCKL: "0x916320bBA0C956181b7cAcA0fe01ef7635eFD411",
      BCKM: "0x44d52b5af0551c678e6e2039e2b249a9d8cdb375",
      WFTM: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    },
  },
  [FANTOM_TEST_ID]: {
    contracts: {
      spookyfactory: "0x5D479c2a7FB79E12Ac4eBBAeDB0322B4d5F9Fd02",
      spookyrouter: "0xcCAFCf876caB8f9542d6972f87B5D62e1182767d",
      splp: "0x6b59ebfbf6ef6aceC6F30D61B8DC9594E4aCf9b9",
      mchef: "0xF3ABf185a80A3a0B8a4Dd554Bc6965F6d4c231cE",
      drivein: "0xd741329B9F696DBe7b5a0a43C82eb1345d5aA56D",
      midterm: "0xdB1BF91dBecd5facd7Be9101891DB5B22C307BA7",
      famfarm: "0x48a4F2d4eDD465761e8FD42aC4321e123591558e",
      xbck: "0x9a166BF54c3A81F62f1AC6ff9E3C93515EcFfFB3",
      rarity: "0x9d7958f4B63B325Cd0Fd98d3633e9F62904c45a0",
      airdrop: "0x343D672BC51227049ae241E2b54dDf082D7082a3",
      whitelist: "0x73d3e4146454b90dc3f149696324905219e055d0",
      bcklionfactory: "0xfed29E59C2B68E4373Cb9C773210DA0DD5bc7c7E",
      bck222: "0x89836bb1D45F6770beC4B897490D0EeB2847820F",
    },
    tokens: {
      BCK: "0x369F1b7fdccA3992464b44b017A9d5D6c00f6213",
      BCKL: "0x5bbb0a75134078673aa93f0884815c51a41b3def",
      BCKM: "0x44d52b5af0551c678e6e2039e2b249a9d8cdb375",
      WFTM: "0xf1277d1Ed8AD466beddF92ef448A132661956621",
    },
  },
  [SEPOLIA_ID]: {
    contracts: {
      ethfactory: "0xF99fD19853806704AAf626CA75967165575DE736",
      tigerminter: "0xE74606154f000787A13d195904BC7187E86771b8",
    },
  },
  [MAINNET_ID]: {
    contracts: {
      ethfactory: "0x916320bBA0C956181b7cAcA0fe01ef7635eFD411",
      tigerminter: "0x7b2837560DE5924FFb2A83284a6dF629685bB90D",
    },
  },
};
