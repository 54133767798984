import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Column from "./../components/Column";
import Scrollbar from "./../components/Scrollbar";
import Footer from "./../containers/Footer/Footer";
import CatOverview from "../containers/NftOverview/CatOverview";
import Search from "../containers/Search/Search";
import Vault from "../containers/Vault/Vault";
import CustomizeMint from "../containers/CustomizeMint/CustomizeMint";
import useWalletProvider from "./../hooks/useWalletProvider";
import useWeb3Modal from "./../hooks/useWeb3Modal";
const { hashMessage } = require("@ethersproject/hash");
const { verifyMessage } = require("@ethersproject/wallet");

const Klub: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const [loadWeb3Modal] = useWeb3Modal();
  const [subMenu, setSubMenu] = useState("");
  const [tokenId, setTokenId] = useState(0);
  const [hasNft, setHasNft] = useState(false);

  const checkAmountNft = () => {
    const genesisContract = walletContext.activeWallet.contracts.get(
      "bcklCall"
    );
    const factoryContract = walletContext.activeWallet.contracts.get(
      "bckFactoryCall"
    );
    const ethContract = walletContext.activeWallet.contracts.get(
      "bckEthFactoryCall"
    );

    const amountOfGenesisNftsPromise = genesisContract.balanceOf(
      walletContext.activeWallet.address
    );
    const amountOfFactoryNftsPromise = factoryContract.balanceOf(
      walletContext.activeWallet.address
    );
    const amountOfEthNftsPromise = ethContract.balanceOf(
      walletContext.activeWallet.address
    );

    Promise.all([
      amountOfGenesisNftsPromise,
      amountOfFactoryNftsPromise,
      amountOfEthNftsPromise,
    ]).then(([amountOfGenesisNfts, amountOfFactoryNfts, amountOfEthNfts]) => {
      if (amountOfGenesisNfts > 0) return setHasNft(true);
      if (amountOfFactoryNfts > 0) return setHasNft(true);
      if (amountOfEthNfts > 0) return setHasNft(true);
    });
  };

  const closeCustomize = () => {
    setSubMenu("cats");
    setTokenId(0);
  };

  const openCustomize = async (id: number) => {
    if (props.seeLastMinted) {
      props.updateLastMinted(false);
      const factoryContract = walletContext.activeWallet.contracts.get(
        "bckFactory"
      );
      const factoryAmountNft = await factoryContract
        .balanceOf(walletContext.activeWallet.address)
        .then((result: any) => parseInt(result));
      const getTokenId = await factoryContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        factoryAmountNft - 1
      );
      setTokenId(getTokenId.toNumber());
    } else {
      setTokenId(id);
    }
    setSubMenu("customize");
  };

  // TODO fix dat het niet meer checkt of de wallet op de goede chain zit en of dat gelijk is aan supported chains.
  // Voeg ook meer contracten toe waarom gecheckt wordt of er nft's zijn (factory en eth)
  // submenu moet weer zichtbaar zijn
  useEffect(() => {
    if (walletContext.activeWallet.address) {
      checkAmountNft();
    }
  }, [walletContext.activeWallet.address]);

  useEffect(() => {
    if (props.seeLastMinted) {
      openCustomize(tokenId);
    } else {
      setSubMenu("cats");
    }
  }, [props.seeLastMinted]);

  return (
    <Column style={{ height: "100%" }}>
      <SubMenu loaded={hasNft}>
        <SubMenuLink
          current={subMenu === "cats"}
          onClick={() => setSubMenu("cats")}
        >
          MY CATS
        </SubMenuLink>
        <SubMenuLink
          current={subMenu === "search"}
          onClick={() => setSubMenu("search")}
        >
          SEARCH
        </SubMenuLink>
        <SubMenuLink
          current={subMenu === "vault"}
          onClick={() => setSubMenu("vault")}
          style={{ marginRight: "80px" }}
        >
          BCK VAULT
        </SubMenuLink>
      </SubMenu>
      <Scrollbar style={{ width: "100%", justifyContent: "space-between" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            height: "100%",
            backgroundColor: "#261B32",
          }}
        >
          {subMenu === "cats" && (
            <Column
              style={{
                position: "relative",
                width: "100%",
                alignItems: "center",
                marginBottom: "calc(10vh - 40px)",
              }}
            >
              <OrangeHeader
                style={{ marginBottom: "8vh" }}
                onClick={async () => {
                  const signedTx = await walletContext.activeWallet.signer.signMessage(
                    "Yoyoyo"
                  );
                  const verifyAddress = verifyMessage("Yoyoyo", signedTx);
                  console.log(verifyAddress);
                  console.log(
                    verifyAddress === walletContext.activeWallet.address
                      ? "verified"
                      : "not verified"
                  );

                  // console.log(
                  //   hashMessage("\x19Ethereum Signed Message:\\nYoyoyo")
                  // );
                  // console.log(signedTx);
                }}
              >
                YOUR BIG CATS
              </OrangeHeader>

              {walletContext.activeWallet.address && (
                <CatOverview openCustomize={openCustomize} />
              )}

              {!walletContext.activeWallet.address && (
                <SubText>Connect to see your Cats</SubText>
              )}
              {!walletContext.activeWallet.address && (
                <ConnectButton
                  onClick={() => {
                    loadWeb3Modal();
                  }}
                >
                  CONNECT
                </ConnectButton>
              )}
            </Column>
          )}
          {subMenu === "search" && (
            <Column
              style={{
                alignItems: "center",
                marginBottom: "calc(10vh - 40px)",
              }}
            >
              <OrangeHeader>CAT SEARCH</OrangeHeader>
              <Search />
            </Column>
          )}
          {subMenu === "vault" && (
            <div>
              <OrangeHeader>BIG CAT VAULT</OrangeHeader>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SubText style={{ marginTop: "15vh" }}>Coming soon...</SubText>
                <Vault />
              </div>
            </div>
          )}
          {subMenu === "customize" && (
            <CustomizeMint tokenId={tokenId} closeCustomize={closeCustomize} />
          )}
          <Footer />
        </div>
      </Scrollbar>
    </Column>
  );
};

const OrangeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #f98c1f;
  margin-top: 6vh;
`;
const SubMenu = styled.div<{
  loaded: boolean;
}>`
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 56px;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  background-color: #2d2237;
  transition: opacity 0.1s ease-in-out;
`;

const SubMenuLink = styled.div<{
  current: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  height: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2px;
  margin-left: 24px;
  color: ${(props) => (props.current ? "#f58b2f" : "#bf6e32")};
  cursor: pointer;
  &:hover {
    color: #f58b2f;
  }
  transition: color 0.2s ease-in-out;
`;

const SubText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 2vh;
  width: 48.75vw;
`;

const ConnectButton = styled.button`
  font-family: "Catamaran", sans-serif;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  border-radius: 12px;
  background-color: #f98c1f;
  color: #271b32;
  cursor: pointer;
  &:hover {
    background-color: #bf6e32;
  }
  transition: background-color 0.2s ease-in-out;
`;

export default Klub;
