import React, { useState, useEffect } from "react";
import styled from "styled-components";
import countdownBackground from "../../assets/countdown/countdown_tiger.png";

const calculateTimeLeft = () => {
  const now = Date.now();

  // let difference = 1687852200000 - now; // test
  let difference = 1692982800000 - now; // prod
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const formatTime = (time: number) => {
  const formattedTime = time < 10 ? "0" + time.toString() : time.toString();
  let index = formattedTime.indexOf("2");
  if (index === -1) {
    return <StyledNumber>{formattedTime}</StyledNumber>;
  }
  let numberArray = formattedTime.split("");
  if (formattedTime === "22") {
    return (
      <StyledNumber>
        <OrangeSpan>{numberArray[0]}</OrangeSpan>
        <OrangeSpan>{numberArray[1]}</OrangeSpan>
      </StyledNumber>
    );
  }
  if (index === 0) {
    return (
      <StyledNumber>
        <OrangeSpan>{numberArray[0]}</OrangeSpan>
        {numberArray[1]}
      </StyledNumber>
    );
  }
  if (index === 1) {
    return (
      <StyledNumber>
        {numberArray[0]}
        <OrangeSpan>{numberArray[1]}</OrangeSpan>
      </StyledNumber>
    );
  }
};

const CountDown: React.FC<any> = (props: any) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const now = Date.now();
      // let difference = 1687852200000 - now; // test
      let difference = 1692982800000 - now; // prod
      if (difference < 0) {
        props.removeCountdown();
      }
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <StyledCountdownBackground>
      <OrangeHeader>COUNTDOWN TO MINT</OrangeHeader>
      <TimerRow>
        <StyledBox>
          {formatTime(timeLeft.days)}
          <StyledUnit>DAYS</StyledUnit>
        </StyledBox>
        <StyledBox2>:</StyledBox2>
        <StyledBox>
          {formatTime(timeLeft.hours)}
          <StyledUnit>HOURS</StyledUnit>
        </StyledBox>
        <StyledBox2>:</StyledBox2>
        <StyledBox>
          {formatTime(timeLeft.minutes)}
          <StyledUnit>MINUTES</StyledUnit>
        </StyledBox>
        <StyledBox2>:</StyledBox2>
        <StyledBox>
          {formatTime(timeLeft.seconds)}
          <StyledUnit>SECONDS</StyledUnit>
        </StyledBox>
      </TimerRow>
    </StyledCountdownBackground>
  );
};
const OrangeSpan = styled.span`
  color: #f98c1f;
`;

const OrangeHeader = styled.div`
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #f98c1f;
  margin-top: 52px;
  margin-bottom: -10px;
`;

const StyledCountdownBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 320px;
  justify-content: start;
  font-family: Catamaran;
  border-radius: 12px;
  background-image: url(${countdownBackground});
  margin-bottom: 40px;
`;

const TimerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 220px;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 12%;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  color: white;
`;
const StyledBox2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 5%;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 100px;
  color: white;
`;

const StyledNumber = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 100px;
  text-align: center;
  margin-bottom: -20px;
`;

const StyledUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #f98c1f;
`;

export default CountDown;
