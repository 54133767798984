// eslint-disable-next-line import/no-anonymous-default-export

const config = {
  supportedChains: [250],
  // Opera chain id
  chainId: "0xfa",
  // JSON-RPC endpoint
  rpc: "https://rpc.ftm.tools",
  // used in links pointing to fantom explorer
  explorerUrl: "https://ftmscan.com/",
  // used in links pointing to fantom explorer's transaction detail
  explorerTransactionPath: "transactions",
};

if (process.env.REACT_APP_USE === "testnet") {
  console.warn("BCK app is using TESTNET!");
  config.supportedChains = [4002];
  config.chainId = "0xfa2";
  config.rpc = "https://rpc.ankr.com/fantom_testnet";
  config.explorerUrl = "https://testnet.ftmscan.com/";
}

// config.rpc = "https://xapi.testnet.fantom.network/lachesis";
export default config;
