import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Mint from "./mint";
import Row from "../components/Row";
import Column from "../components/Column";
import Scrollbar from "../components/Scrollbar";
import lionBackGround from "./../assets/img/backgrounds/webpromo_tiger.png";

const Home: React.FC<any> = (props: any) => {
  return (
    <Scrollbar style={{ width: "100%" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          height: "100%",
          backgroundColor: "#261B32",
        }}
      >
        <TopBlock>
          <TextBlock>
            <Column style={{ marginLeft: "10vw" }}>
              <FirstDrop>
                Golden Fangs <span style={{ color: "#f98c1f" }}>222</span> ETH
                collection is coming...
              </FirstDrop>
              <p>
                <DropSubText>
                  Big Cat Klub next collection is almost here!
                  <br />
                  {/*Mint your new tiger now or get one from{" "}*/}
                  {/*<StyledDropSubTextLink*/}
                  {/*  href="https://opensea.io/collection/bigcatklub"*/}
                  {/*  target="_blank"*/}
                  {/*>*/}
                  {/*  OpenSea*/}
                  {/*</StyledDropSubTextLink>*/}
                  {/*!*/}
                  {/*<br />*/}
                  Follow us on{" "}
                  <StyledDropSubTextLink
                    href="https://twitter.com/BigCatKlub"
                    target="_blank"
                  >
                    Twitter
                  </StyledDropSubTextLink>{" "}
                  or join our{" "}
                  <StyledDropSubTextLink
                    href="https://discord.gg/AyZUmG7Mkg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discord
                  </StyledDropSubTextLink>{" "}
                  to stay updated.
                </DropSubText>
              </p>
              <Row style={{ justifyContent: "start", alignItems: "center" }}>
                <OrangeDiscordButton
                  onClick={() =>
                    window.open("https://discord.gg/AyZUmG7Mkg", "_blank")
                  }
                >
                  Join
                </OrangeDiscordButton>
                <StyledNavLink to={"/klub"}>
                  <WhiteDiscordButton>Already a member</WhiteDiscordButton>
                </StyledNavLink>
              </Row>
            </Column>
          </TextBlock>
          <GradientBlock />
        </TopBlock>
        <Mint updateLastMinted={props.updateLastMinted} />
      </div>
    </Scrollbar>
  );
};

const TopBlock = styled.div`
  position: relative;
  height: 95vh;
  background-image: url(${lionBackGround});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 95% 100%;
  margin-bottom: 56px;
`;

const TextBlock = styled.div`
  height: 95vh;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  letter-spacing: 0rem;
  text-align: left;
  color: #ffffff;
`;

const FirstDrop = styled.div`
  width: 35vw;
  min-width: 500px;
  font-size: 4.4rem;
  line-height: 4.4rem;
  font-weight: 600;
  margin-top: 10vw;
  cursor: default;
`;

const DropSubText = styled.div`
  align-items: start;
  width: 27vw;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 300;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  cursor: default;
`;

const StyledDropSubTextLink = styled.a`
  color: #f98c1f;
  text-decoration: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0 0 4px #f98c1f;
  }
`;

const OrangeDiscordButton = styled.button`
  font-family: "Catamaran", sans-serif;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 1vw;
  border: none;
  border-radius: 12px;
  background-color: #f98c1f;
  color: #271b32;
  cursor: pointer;
  &:hover {
    background-color: #bf6e32;
  }
  transition: background-color 0.3s ease-in-out;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: start;
`;

const WhiteDiscordButton = styled.button`
  font-family: "Catamaran", sans-serif;
  height: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
  margin-right: 1vw;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  transition: background-color 0.3s ease-in-out;
`;

const GradientBlock = styled.div`
  position: absolute;
  bottom: 0;
  height: 10vh;
  width: 100vw;
  background: linear-gradient(180deg, #1a1221 0%, rgba(26, 18, 33, 0) 100%);
  transform: rotate(-180deg);
  z-index: 1000;
`;

export default Home;
