import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import ModalProvider from "./context/ModalProvider";
import { ActiveWalletProvider } from "./context/ActiveWalletProvider";
import { TransactionProvider } from "./context/TransactionProvider";
import useDetectResolutionType from "./hooks/useDetectResolutionType";
import Klub from "./views/klub";
import Bank from "./views/bank";
import Home from "./views/home";
import Mint from "./views/mint";
import Info from "./views/info";
import TokenPage from "./containers/Info/TokenPage";
import TopBar from "./containers/TopBar/TopBar";
import { Body, Heading1, Heading3 } from "./components";
import Column from "./components/Column";
import Spacer from "./components/Spacer";
import lionBackGround from "./assets/img/backgrounds/backGroundTiger.png";
import "./App.css";

function App() {
  const resolutionType = useDetectResolutionType();
  const [seeLastMinted, setSeeLastMinted] = useState(false);
  const [bankSubMenu, setBankSubMenu] = useState("");

  return (
    <Providers>
      <Body>
        {resolutionType === "mobile" || resolutionType === "tablet" ? (
          <ResolutionNotSupported width="100%">
            <Spacer />
            <Heading1
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "30%",
              }}
            >
              Resolution is not supported
            </Heading1>
            <Spacer size="sm" />
            <Heading3 style={{ color: "white" }}>Please use desktop</Heading3>
          </ResolutionNotSupported>
        ) : (
          <Column style={{ width: "100vw", minHeight: "100vh" }}>
            <Router>
              <TopBar />
              <Switch>
                <Route path="/klub">
                  <Klub
                    seeLastMinted={seeLastMinted}
                    updateLastMinted={setSeeLastMinted}
                  />
                </Route>
                <Route path="/bank">
                  <Bank
                    bankSubMenu={bankSubMenu}
                    updateBankMenu={setBankSubMenu}
                  />
                </Route>
                <Route path="/info">
                  <Info
                    seeLastMinted={seeLastMinted}
                    updateLastMinted={setSeeLastMinted}
                    updateBankMenu={setBankSubMenu}
                  />
                </Route>
                <Route path="/mint">
                  <Mint updateLastMinted={setSeeLastMinted} />
                </Route>
                <Route path="/token">
                  <TokenPage updateLastMinted={setSeeLastMinted} />
                </Route>
                <Route path="/">
                  <Home updateLastMinted={setSeeLastMinted} />
                </Route>
              </Switch>
            </Router>
          </Column>
        )}
      </Body>
    </Providers>
  );
}

const Providers: React.FC<any> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ActiveWalletProvider>
        <TransactionProvider>
          <ModalProvider>{children}</ModalProvider>
        </TransactionProvider>
      </ActiveWalletProvider>
    </ThemeProvider>
  );
};

const ResolutionNotSupported = styled(Column)<{ width: string }>`
  width: ${(props) => props.width};
  align-items: center;
  background-image: url(${lionBackGround});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 69% 100%;
`;

export default App;
