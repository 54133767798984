import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWalletProvider from "../../hooks/useWalletProvider";
import { formatAddress } from "../../utils/wallet";
import useWeb3Modal from "../../hooks/useWeb3Modal";

const WalletSelector: React.FC<any> = ({ width }) => {
  const { walletContext } = useWalletProvider();
  const [loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [address, setAddress] = useState("");
  const [text, setText] = useState("CONNECT");

  const setCorrectText = (hoveredIn: boolean) => {
    if (walletContext.activeWallet.address) {
      return setText(
        hoveredIn
          ? "DISCONNECT"
          : formatAddress(walletContext.activeWallet.address)
      );
    }
    if (!walletContext.activeWallet.address) {
      return setText("CONNECT");
    }
  };

  useEffect(() => {
    if (walletContext.activeWallet.address) {
      setAddress(walletContext.activeWallet.address);
      setCorrectText(false);
    }
  }, [walletContext.activeWallet.address]);

  return (
    <ConnectButton
      address={address}
      onClick={() => {
        if (address) {
          logoutOfWeb3Modal();
        }
        if (!address) {
          loadWeb3Modal();
        }
      }}
      onMouseEnter={() => setCorrectText(true)}
      onMouseLeave={() => setCorrectText(false)}
    >
      <ConnectButtonText>{text}</ConnectButtonText>
    </ConnectButton>
  );
};

const ConnectButton = styled.button<{
  address?: string;
}>`
  height: 32px;
  margin-left: 24px;
  margin-right: 80px;
  border: none;
  border-radius: 8px;
  color: #271b32;
  background-color: #f98c1f;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:after {
    content: ${(props) =>
      !props.address ? "CONNECT" : formatAddress(props.address)};
    transition: content 0.2s ease-in-out;
  }
  &:hover {
    background-color: #bf6e32;
  }
  &:after&:hover {
    content: ${(props) => (!props.address ? "CONNECT" : "DISCONNECT")};
  }
  transition: background-color 0.2s ease-in-out;
`;

const ConnectButtonText = styled.div<{
  address?: string;
}>`
  height: 16px;
  width: 96px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  value: ${(props) =>
    !props.address ? "CONNECT" : formatAddress(props.address)};
  transition: value 0.2s ease-in-out;
`;

export default WalletSelector;
