import React from "react";
import Column from "../../components/Column";
import Row from "../../components/Row";

const Vault: React.FC<any> = () => {
  return (
    <Column>
      <Row
        style={{
          width: "100%",
          justifyContent: "space-evenly",
          marginLeft: "20px",
          flexWrap: "wrap",
        }}
      ></Row>
    </Column>
  );
};

export default Vault;
