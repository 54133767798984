export const switchToChain = async (provider: any, chainId: number) => {
  console.log(chainId);
  const getNetworkDetails = () => {
    if (chainId === 1) {
      return {
        chainId: "0x1", // A 0x-prefixed hexadecimal string
        chainName: "Mainnetwerk",
        nativeCurrency: {
          name: "Ethereum",
          symbol: "ETH", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://mainnet.infura.io/v3/"],
        blockExplorerUrls: ["https://etherscan.io"],
      };
    }
    if (chainId === 250) {
      return {
        chainId: "0xfa", // A 0x-prefixed hexadecimal string
        chainName: "Fantom Opera",
        nativeCurrency: {
          name: "Fantom",
          symbol: "FTM", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://rpc.ftm.tools/"],
        blockExplorerUrls: ["https://ftmscan.com/"],
      };
    }
    if (chainId === 4002) {
      return {
        chainId: "0xfa2", // A 0x-prefixed hexadecimal string
        chainName: "Fantom Testnet",
        nativeCurrency: {
          name: "Fantom",
          symbol: "FTM", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://xapi.testnet.fantom.network/lachesis"],
        blockExplorerUrls: ["https://testnet.ftmscan.com/"],
      };
    }
    if (chainId === 11155111) {
      return {
        chainId: "0xAA36A7", // A 0x-prefixed hexadecimal string
        chainName: "Sepolia test network",
        nativeCurrency: {
          name: "Ethereum",
          symbol: "SepoliaETH", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://rpc.sepolia.org/"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"],
      };
    }
  };

  const networkDetails = getNetworkDetails();
  if (!networkDetails || !provider) {
    return;
  }

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkDetails.chainId }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === -32603) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkDetails],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkDetails],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
    console.error(switchError);
  }
};
