import React from "react";
import styled from "styled-components";
import Row from "../../components/Row";
import discordImg from "../../assets/logo/discord.png";
import twitterImg from "../../assets/logo/twitter.png";
import heartImg from "../../assets/img/shapes/Heart.png";
import psLogo from "../../assets/logo/icon_ps.png";
import NFTKeyLogo from "../../assets/logo/icon_nftkey.png";
import openSeaLogo from "../../assets/logo/openseaWhite.png";

const Footer: React.FC<any> = () => {
  return (
    <Row
      style={{
        justifyContent: "space-between",
        paddingBottom: "36px",
        marginTop: "auto",
      }}
    >
      <Row style={{ alignItems: "center" }}>
        <KatTxt>© 2023 BIG CAT KLUB</KatTxt>
        <a
          href="https://discord.gg/AyZUmG7Mkg"
          target="_blank"
          rel="noreferrer"
        >
          <SocialLink src={discordImg} />
        </a>

        <a
          href="https://twitter.com/bigcatklub"
          target="_blank"
          rel="noreferrer"
        >
          <SocialLink src={twitterImg} />
        </a>
        <a
          href="https://paintswap.finance/marketplace/collections/0x916320bba0c956181b7caca0fe01ef7635efd411"
          target="_blank"
          rel="noreferrer"
        >
          <SocialLink
            style={{ height: "18px", marginBottom: "1px" }}
            src={psLogo}
          />
        </a>
        <a
          href="https://nftkey.app/collections/bigcatklub/"
          target="_blank"
          rel="noreferrer"
        >
          <SocialLink
            style={{ height: "18px", marginBottom: "1px" }}
            src={NFTKeyLogo}
          />
        </a>
        <a
          href="https://opensea.io/collection/bigcatklub/"
          target="_blank"
          rel="noreferrer"
        >
          <SocialLink
            style={{ height: "18px", marginBottom: "1px" }}
            src={openSeaLogo}
          />
        </a>
      </Row>
      <Row style={{ alignItems: "center" }}>
        <img
          src={heartImg}
          alt="heart"
          style={{ height: "15px", marginRight: "8px" }}
        />
        <NftTxt>FTM NFTs</NftTxt>
      </Row>
    </Row>
  );
};

export const KatTxt = styled.div`
  font-family: "Catamaran", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  height: 16px;
  margin-left: 80px;
  margin-bottom: -8px;
  color: #ffffff;
  cursor: default;
`;

export const NftTxt = styled.div`
  font-family: "Catamaran", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  height: 16px;
  margin-right: 80px;
  color: #ffffff;
  cursor: default;
`;

export const SocialLink = styled.img`
  align-items: center;
  height: 16px;
  color: #ffffff;
  margin-bottom: 2px;
  margin-left: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &&:hover {
    opacity: 0.5;
  }
`;

export default Footer;
