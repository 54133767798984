import React, { useState, useEffect } from "react";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { unitToWei, weiToUnit } from "../../utils/conversion";
import { BigNumber } from "@ethersproject/bignumber";
import { send } from "../../utils/transactions";
import styled from "styled-components";
import dropdown from "./../../assets/bank/dropdown.png";
import config from "../../config/config";

const Withdraw: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [activeFarm, setActiveFarm] = useState(props.subMenu);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [wftmReturn, setWftmReturn] = useState(false);
  const [bckPerLp, setBckPerLp] = useState(0);
  const [ftmPerLp, setFtmPerLp] = useState(0);
  const [driveInBalance, setDriveInBalance] = useState(0);
  const [midTermBalance, setMidTermBalance] = useState(0);
  const [famFarmBalance, setFamFarmBalance] = useState(0);
  const [ratioDriveIn, setRatioDriveIn] = useState(1);
  const [ratioMidTerm, setRatioMidTerm] = useState(1);
  const [ratioFamFarm, setRatioFamFarm] = useState(1);
  const [stakedInDriveIn, setStakedInDriveIn] = useState(0);
  const [stakedInMidTerm, setStakedInMidTerm] = useState(0);
  const [stakedInFamFarm, setStakedInFamFarm] = useState(0);
  const [liquidityBalance, setLiquidityBalance] = useState(0);
  const [liqAllowance, setLiqAllowance] = useState(unitToWei("0"));
  const [liquidityInput, setLiquidityInput] = useState("");
  const [swapInput, setSwapInput] = useState("");
  const [stakeInput, setStakeInput] = useState("");
  const [txHash, setTxHash] = useState(null);
  const [txType, setTxType] = useState("");
  const tx = transaction[txHash];

  const setLiqBalances = async () => {
    const spookyLpContract = walletContext.activeWallet.contracts.get(
      "splpCall"
    );
    const routerContract = walletContext.activeWallet.contracts.get(
      "sproutCall"
    );

    const getLiqAllowance = await spookyLpContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );

    setLiqAllowance(getLiqAllowance);

    const driveInContract = walletContext.activeWallet.contracts.get(
      "driveInCall"
    );
    const midTermContract = walletContext.activeWallet.contracts.get(
      "midTermCall"
    );
    const famFarmContract = walletContext.activeWallet.contracts.get(
      "famFarmCall"
    );
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchefCall"
    );

    const liqBalance = await spookyLpContract.balanceOf(
      walletContext.activeWallet.address
    );
    const driveInBalance = await driveInContract.balanceOf(
      walletContext.activeWallet.address
    );
    const midTermBalance = await midTermContract.balanceOf(
      walletContext.activeWallet.address
    );
    const famFarmBalance = await famFarmContract.balanceOf(
      walletContext.activeWallet.address
    );

    const userInfoFF = await masterChefContract.userInfo(
      2,
      walletContext.activeWallet.address
    );
    setStakedInFamFarm(weiToUnit(userInfoFF.amount));
    const userInfoMT = await masterChefContract.userInfo(
      1,
      walletContext.activeWallet.address
    );
    setStakedInMidTerm(weiToUnit(userInfoMT.amount));
    const userInfoDI = await masterChefContract.userInfo(
      0,
      walletContext.activeWallet.address
    );
    setStakedInDriveIn(weiToUnit(userInfoDI.amount));

    const totalSupply = await spookyLpContract.totalSupply();
    const reserves = await spookyLpContract.getReserves();
    const getBckPerLp =
      reserves[1].mul(100000).div(totalSupply).toNumber() / 100000;
    const getFtmPerLp =
      reserves[0].mul(100000).div(totalSupply).toNumber() / 100000;

    setBckPerLp(getBckPerLp);
    setFtmPerLp(getFtmPerLp);

    const getTotalLpInDriveIn = await spookyLpContract.balanceOf(
      driveInContract.address
    );
    const getTotalDriveInTokens = await driveInContract.totalSupply();
    const ratioDriveIn = getTotalLpInDriveIn
      .mul(10000)
      .div(getTotalDriveInTokens);

    const getTotalLpInMidTerm = await spookyLpContract.balanceOf(
      midTermContract.address
    );
    const getTotalMidTermTokens = await midTermContract.totalSupply();
    const ratioMidTerm = getTotalLpInMidTerm
      .mul(10000)
      .div(getTotalMidTermTokens);

    const getTotalLpInFamFarm = await spookyLpContract.balanceOf(
      famFarmContract.address
    );
    const getTotalFamFarmTokens = await famFarmContract.totalSupply();
    const ratioFamFarm = getTotalLpInFamFarm
      .mul(10000)
      .div(getTotalFamFarmTokens);

    setRatioDriveIn(ratioDriveIn.toNumber() / 10000);
    setRatioMidTerm(ratioMidTerm.toNumber() / 11111);
    setRatioFamFarm(ratioFamFarm.toNumber() / 12500);
    setLiquidityBalance(weiToUnit(liqBalance));
    setDriveInBalance(weiToUnit(driveInBalance));
    setMidTermBalance(weiToUnit(midTermBalance));
    setFamFarmBalance(weiToUnit(famFarmBalance));
  };

  const updateApproveAndRemove = async () => {
    const spookyLpContract = walletContext.activeWallet.contracts.get("splp");
    const routerContract = walletContext.activeWallet.contracts.get("sprout");

    const getLiqAllowance = await spookyLpContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );
    const liqBalance = await spookyLpContract.balanceOf(
      walletContext.activeWallet.address
    );
    setLiqAllowance(getLiqAllowance);
    setLiquidityBalance(weiToUnit(liqBalance));
  };

  const updateUnstake = async () => {
    const driveInContract = walletContext.activeWallet.contracts.get("driveIn");
    const midTermContract = walletContext.activeWallet.contracts.get("midTerm");
    const famFarmContract = walletContext.activeWallet.contracts.get("famFarm");
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );
    if (activeFarm === "driveIn") {
      const driveInBalance = await driveInContract.balanceOf(
        walletContext.activeWallet.address
      );
      setDriveInBalance(weiToUnit(driveInBalance));
      const userInfoDI = await masterChefContract.userInfo(
        0,
        walletContext.activeWallet.address
      );
      return setStakedInDriveIn(weiToUnit(userInfoDI.amount));
    }
    if (activeFarm === "midTerm") {
      const userInfoMT = await masterChefContract.userInfo(
        1,
        walletContext.activeWallet.address
      );
      setStakedInMidTerm(weiToUnit(userInfoMT.amount));
      const midTermBalance = await midTermContract.balanceOf(
        walletContext.activeWallet.address
      );
      return setMidTermBalance(weiToUnit(midTermBalance));
    }
    if (activeFarm === "famFarm") {
      const famFarmBalance = await famFarmContract.balanceOf(
        walletContext.activeWallet.address
      );
      setFamFarmBalance(weiToUnit(famFarmBalance));
      const userInfoFF = await masterChefContract.userInfo(
        2,
        walletContext.activeWallet.address
      );
      setStakedInFamFarm(weiToUnit(userInfoFF.amount));
    }
  };

  const updateWithdraw = async () => {
    const spookyLpContract = walletContext.activeWallet.contracts.get("splp");
    const driveInContract = walletContext.activeWallet.contracts.get("driveIn");
    const midTermContract = walletContext.activeWallet.contracts.get("midTerm");
    const famFarmContract = walletContext.activeWallet.contracts.get("famFarm");

    const totalSupply = await spookyLpContract.totalSupply();
    const reserves = await spookyLpContract.getReserves();
    const getBckPerLp =
      reserves[1].mul(100000).div(totalSupply).toNumber() / 100000;
    const getFtmPerLp =
      reserves[0].mul(100000).div(totalSupply).toNumber() / 100000;

    setBckPerLp(getBckPerLp);
    setFtmPerLp(getFtmPerLp);

    const liqBalance = await spookyLpContract.balanceOf(
      walletContext.activeWallet.address
    );
    setLiquidityBalance(weiToUnit(liqBalance));

    if (activeFarm === "driveIn") {
      const driveInBalance = await driveInContract.balanceOf(
        walletContext.activeWallet.address
      );
      const getTotalLpInDriveIn = await spookyLpContract.balanceOf(
        driveInContract.address
      );
      const getTotalDriveInTokens = await driveInContract.totalSupply();
      const ratioDriveIn = getTotalLpInDriveIn
        .mul(10000)
        .div(getTotalDriveInTokens);
      setDriveInBalance(weiToUnit(driveInBalance));
      setRatioDriveIn(ratioDriveIn.toNumber() / 10000);
    }

    if (activeFarm === "midTerm") {
      const midTermBalance = await midTermContract.balanceOf(
        walletContext.activeWallet.address
      );
      const getTotalLpInMidTerm = await spookyLpContract.balanceOf(
        midTermContract.address
      );
      const getTotalMidTermTokens = await midTermContract.totalSupply();
      const ratioMidTerm = getTotalLpInMidTerm
        .mul(10000)
        .div(getTotalMidTermTokens);

      setMidTermBalance(weiToUnit(midTermBalance));
      setRatioMidTerm(ratioMidTerm.toNumber() / 11111);
    }

    if (activeFarm === "famFarm") {
      const famFarmBalance = await famFarmContract.balanceOf(
        walletContext.activeWallet.address
      );
      const getTotalLpInFamFarm = await spookyLpContract.balanceOf(
        famFarmContract.address
      );
      const getTotalFamFarmTokens = await famFarmContract.totalSupply();
      const ratioFamFarm = getTotalLpInFamFarm
        .mul(10000)
        .div(getTotalFamFarmTokens);
      setFamFarmBalance(weiToUnit(famFarmBalance));
      setRatioFamFarm(ratioFamFarm.toNumber() / 12500);
    }
  };

  const withdrawAndHarvest = async () => {
    if (stakeInput === "" || stakeInput === "0") {
      return;
    }
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );

    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        masterChefContract.withdrawAndHarvest(
          activeFarm === "driveIn" ? 0 : activeFarm === "midTerm" ? 1 : 2,
          unitToWei(stakeInput),
          walletContext.activeWallet.address
        ),
      dispatchTx
    );
    setStakeInput("");
    props.editTxStatus(true);
    setTxType("unstake");
    return setTxHash(hash);
  };

  const swapFarmForLiq = async () => {
    if (swapInput === "" || swapInput === "0") {
      return;
    }
    const farmContract = walletContext.activeWallet.contracts.get(activeFarm);
    const hash = await send(
      walletContext.activeWallet.provider,
      () => farmContract.withdraw(unitToWei(swapInput)),
      dispatchTx
    );
    setSwapInput("");
    props.editTxStatus(true);
    setTxType("withdraw");
    return setTxHash(hash);
  };

  const approveRemoveLiquidity = async (max: boolean) => {
    const pairContract = walletContext.activeWallet.contracts.get("splp");
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const maxAmount = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );
    if (max) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () => pairContract.approve(routerContract.address, maxAmount),
        dispatchTx
      );

      setTxType("approve");
      return setTxHash(hash);
    }
    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        pairContract.approve(routerContract.address, unitToWei(liquidityInput)),
      dispatchTx
    );
    props.editTxStatus(true);
    setTxType("approve");
    return setTxHash(hash);
  };

  const removeLiquidity = async () => {
    if (liquidityInput === "" || liquidityInput === "0") {
      return;
    }
    const pairContract = walletContext.activeWallet.contracts.get("splp");
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const totalSupply = await pairContract.totalSupply();
    const reserves = await pairContract.getReserves();
    const getBckPerLp =
      reserves[1].mul(100000).div(totalSupply).toNumber() / 100000;
    const getFtmPerLp =
      reserves[0].mul(100000).div(totalSupply).toNumber() / 100000;

    const tokenAAddress = bckContract.address;
    const tokenBAddress = wftmContract.address;
    const liquidityAmount = unitToWei(liquidityInput);
    const minimalBckReturn = unitToWei(
      (parseFloat(liquidityInput) * getBckPerLp).toString()
    )
      .mul(995)
      .div(1000);
    const minimalFtmReturn = unitToWei(
      (parseFloat(liquidityInput) * getFtmPerLp).toString()
    )
      .mul(995)
      .div(1000);
    const to = walletContext.activeWallet.address;
    const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

    if (wftmReturn) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () =>
          routerContract.removeLiquidity(
            tokenBAddress,
            tokenAAddress,
            liquidityAmount,
            minimalFtmReturn,
            minimalBckReturn,
            to,
            deadline
          ),
        dispatchTx
      );
      setLiquidityInput("");
      props.editTxStatus(true);
      setTxType("removeLp");
      return setTxHash(hash);
    } else {
      const hash = await send(
        walletContext.activeWallet.provider,
        () =>
          routerContract.removeLiquidityETH(
            tokenAAddress,
            liquidityAmount,
            minimalBckReturn,
            minimalFtmReturn,
            to,
            deadline
          ),
        dispatchTx
      );
      setLiquidityInput("");
      props.editTxStatus(true);
      setTxType("removeLp");
      return setTxHash(hash);
    }
  };

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      setLiqBalances();
      props.setTopBalances();
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (tx?.status === "completed") {
      props.editTxStatus(false);
    }
    if (tx?.status === "completed" && txType === "approve") {
      updateApproveAndRemove();
      setTxType("");
    }
    if (tx?.status === "completed" && txType === "withdraw") {
      updateWithdraw();
      setTxType("");
    }
    if (tx?.status === "completed" && txType === "unstake") {
      updateUnstake();
      setTxType("");
      props.calculateAPR();
    }
    if (tx?.status === "completed" && txType === "removeLp") {
      updateApproveAndRemove();
      setTxType("");
      props.setTopBalances();
    }
  }, [tx]);

  return (
    <LiquidityColumn>
      <BlockRow>
        <StakeColumn>
          <HeaderRow>
            <MenuName>WITHDRAW</MenuName>
            <FarmNameDropDownBox onClick={() => setDropdownOpen(!dropdownOpen)}>
              <DropDownFarmName>
                {activeFarm === "driveIn"
                  ? "BIG CAT EXPRESS FARM"
                  : activeFarm === "midTerm"
                  ? "BIG CAT MIDWAY FARM"
                  : "BIG CAT FAMILY FARM"}
              </DropDownFarmName>
              <DropDownV src={dropdown} />
              {dropdownOpen && (
                <DropDownOptionContainer>
                  <DropDownOptionBox
                    onClick={() => {
                      props.setView("withdraw", "driveIn");
                      setActiveFarm("driveIn");
                      setStakeInput("");
                      setSwapInput("");
                    }}
                  >
                    <DropDownOptionName>
                      BIG CAT EXPRESS FARM
                    </DropDownOptionName>
                  </DropDownOptionBox>
                  <DropDownOptionBox
                    onClick={() => {
                      props.setView("withdraw", "midTerm");
                      setActiveFarm("midTerm");
                      setStakeInput("");
                      setSwapInput("");
                    }}
                  >
                    <DropDownOptionName>BIG CAT MIDWAY FARM</DropDownOptionName>
                  </DropDownOptionBox>
                  <DropDownOptionBox
                    onClick={() => {
                      props.setView("withdraw", "famFarm");
                      setActiveFarm("famFarm");
                      setStakeInput("");
                      setSwapInput("");
                    }}
                  >
                    <DropDownOptionName>BIG CAT FAMILY FARM</DropDownOptionName>
                  </DropDownOptionBox>
                </DropDownOptionContainer>
              )}
            </FarmNameDropDownBox>
          </HeaderRow>
          <StakeBlock>
            <StakeBlockTitleRow>
              <StakeBlockTitle>
                UNSTAKE{" "}
                {activeFarm === "driveIn"
                  ? "bckEX"
                  : activeFarm === "midTerm"
                  ? "bckMW"
                  : "bckFF"}
              </StakeBlockTitle>
              <MaxButton
                onClick={() =>
                  setStakeInput(
                    activeFarm === "driveIn"
                      ? stakedInDriveIn.toString()
                      : activeFarm === "midTerm"
                      ? stakedInMidTerm.toString()
                      : stakedInFamFarm.toString()
                  )
                }
              >
                MAX
              </MaxButton>
            </StakeBlockTitleRow>
            <StakeRow>
              <StakeInputBackground>
                <StakeInput
                  placeholder="0"
                  value={stakeInput.substring(0, 9)}
                  onChange={(event) => {
                    setStakeInput(event.target.value.replace(",", "."));
                  }}
                />
              </StakeInputBackground>
              <StakeButton
                style={{ fontSize: "16px" }}
                disabled={
                  unitToWei(stakeInput).gt(
                    unitToWei(
                      activeFarm === "driveIn"
                        ? stakedInDriveIn.toString()
                        : activeFarm === "midTerm"
                        ? stakedInMidTerm.toString()
                        : stakedInFamFarm.toString()
                    )
                  ) || txType === "unstake"
                }
                onClick={() => withdrawAndHarvest()}
              >
                UNSTAKE
              </StakeButton>
            </StakeRow>
            <BalanceRow>
              <BalanceValue>
                STAKED:{" "}
                {activeFarm === "driveIn"
                  ? stakedInDriveIn.toString().substring(0, 9)
                  : activeFarm === "midTerm"
                  ? stakedInMidTerm.toString().substring(0, 9)
                  : stakedInFamFarm.toString().substring(0, 9)}
              </BalanceValue>
              <div></div>
            </BalanceRow>
          </StakeBlock>
          <StakeBlock>
            <StakeBlockTitleRow>
              <StakeBlockTitle>
                {activeFarm === "driveIn"
                  ? "bckEX"
                  : activeFarm === "midTerm"
                  ? "bckMW"
                  : "bckFF"}{" "}
                for bckLP
              </StakeBlockTitle>
              <MaxButton
                onClick={() =>
                  setSwapInput(
                    activeFarm === "driveIn"
                      ? driveInBalance.toString()
                      : activeFarm === "midTerm"
                      ? midTermBalance.toString()
                      : famFarmBalance.toString()
                  )
                }
              >
                MAX
              </MaxButton>
            </StakeBlockTitleRow>
            <StakeRow>
              <StakeInputBackground>
                <StakeInput
                  placeholder="0"
                  value={swapInput.substring(0, 9)}
                  onChange={(event) => {
                    setSwapInput(event.target.value.replace(",", "."));
                  }}
                />
              </StakeInputBackground>
              <StakeButton
                onClick={() => swapFarmForLiq()}
                disabled={
                  unitToWei(swapInput).gt(
                    unitToWei(
                      activeFarm === "driveIn"
                        ? driveInBalance.toString()
                        : activeFarm === "midTerm"
                        ? midTermBalance.toString()
                        : famFarmBalance.toString()
                    )
                  ) || txType === "withdraw"
                }
                style={{ fontSize: "14px" }}
              >
                WITHDRAW
              </StakeButton>
            </StakeRow>
            {activeFarm === "driveIn" && (
              <BalanceRow>
                <BalanceValue>
                  Balance: {driveInBalance.toString().substring(0, 9)}
                </BalanceValue>
                <FarmForLP>
                  1 bckEX = {ratioDriveIn.toString().substring(0, 6)} LP
                </FarmForLP>
              </BalanceRow>
            )}
            {activeFarm === "midTerm" && (
              <BalanceRow>
                <BalanceValue>
                  Balance: {midTermBalance.toString().substring(0, 9)}
                </BalanceValue>
                <FarmForLP>
                  1 bckMW = {ratioMidTerm.toString().substring(0, 6)} LP
                </FarmForLP>
              </BalanceRow>
            )}
            {activeFarm === "famFarm" && (
              <BalanceRow>
                <BalanceValue>
                  Balance: {famFarmBalance.toString().substring(0, 9)}
                </BalanceValue>
                <FarmForLP>
                  1 bckFF = {ratioFamFarm.toString().substring(0, 6)} LP
                </FarmForLP>
              </BalanceRow>
            )}
          </StakeBlock>
        </StakeColumn>
        <FarmInfoColumn>
          <LpBalanceRow>
            <LpBalanceText>
              BCK LP BALANCE: {liquidityBalance.toString().substring(0, 7)}
            </LpBalanceText>
          </LpBalanceRow>
          <InputRow>
            <LpMaxInputButton></LpMaxInputButton>
            <LpInputBackground>
              <LpInput
                placeholder="0"
                value={liquidityInput.substring(0, 7)}
                onChange={(event) => {
                  setLiquidityInput(event.target.value.replace(",", "."));
                }}
              />
            </LpInputBackground>
            <LpMaxInputButton
              onClick={() => setLiquidityInput(liquidityBalance.toString())}
            >
              MAX
            </LpMaxInputButton>
          </InputRow>

          <LpBalanceRow>
            <LpBalanceText>YOU WILL GET BACK</LpBalanceText>
          </LpBalanceRow>
          <TokenNameRow>
            <TokenNameValue>BCK</TokenNameValue>
            <TokenNameValue>{wftmReturn ? "w" : ""}FTM</TokenNameValue>
          </TokenNameRow>
          <ValueBlockRow>
            <ValueBackground>
              <ValueBlockValue>
                {liquidityInput
                  ? (parseFloat(liquidityInput) * bckPerLp)
                      .toString()
                      .substring(0, 7)
                  : 0}
              </ValueBlockValue>
            </ValueBackground>
            <ValueBackground>
              <ValueBlockValue>
                {liquidityInput
                  ? (parseFloat(liquidityInput) * ftmPerLp)
                      .toString()
                      .substring(0, 7)
                  : 0}
              </ValueBlockValue>
            </ValueBackground>
          </ValueBlockRow>
          {liqAllowance.gte(unitToWei(liquidityInput)) && (
            <ApproveRemoveLiquidityRow>
              <RemoveLiquidityButton
                onClick={() => removeLiquidity()}
                disabled={
                  unitToWei(liquidityInput).gt(
                    unitToWei(liquidityBalance.toString())
                  ) || txType === "removeLp"
                }
              >
                REMOVE LIQUIDITY
              </RemoveLiquidityButton>
            </ApproveRemoveLiquidityRow>
          )}
          {liqAllowance.lt(unitToWei(liquidityInput)) && (
            <ApproveRemoveLiquidityRow>
              <ApproveRemoveLiquidityButton
                onClick={() => approveRemoveLiquidity(false)}
                disabled={txType === "approve"}
              >
                APPROVE
              </ApproveRemoveLiquidityButton>
              <ApproveRemoveLiquidityButton
                onClick={() => approveRemoveLiquidity(true)}
                disabled={txType === "approve"}
              >
                MAX
              </ApproveRemoveLiquidityButton>
            </ApproveRemoveLiquidityRow>
          )}
          <GoToFarmRow>
            <GoToFarmButton onClick={() => props.setView("farm", activeFarm)}>
              GO TO FARM
            </GoToFarmButton>
            <GoToFarmButton onClick={() => setWftmReturn(!wftmReturn)}>
              {!wftmReturn ? "w" : ""}FTM
            </GoToFarmButton>
          </GoToFarmRow>
        </FarmInfoColumn>
      </BlockRow>
    </LiquidityColumn>
  );
};

const LiquidityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 262px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 18px 32px 32px 32px;
  background: rgba(51, 255, 136, 0.1);
  border-radius: 2px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MenuName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  color: #9affc4;
  text-align: left;
  cursor: default;
`;

const FarmNameDropDownBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 158px;
  height: 12px;
  padding: 5px 8px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  border: 1px solid rgba(51, 255, 136, 0.4);
  cursor: pointer;
`;

const DropDownFarmName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  width: 150px;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #33ff88;
  margin-top: 3px;
  text-align: left;
`;

const DropDownV = styled.img`
  width: 8px;
  height: 4px;
  margin-top: -1px;
`;

const DropDownOptionContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 0px;
  display: flex;
  flex-direction: column;
  width: 176px;
  height: 80px;
`;

const DropDownOptionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 158px;
  height: 14px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid rgba(51, 255, 136, 0.4);
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &&:hover {
    background-color: rgba(0, 48, 0, 0.85);
  }
`;
const DropDownOptionName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  width: 150px;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #33ff88;
  margin-top: 3px;
  text-align: left;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 608px;
  height: 262px;
`;

const StakeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
  height: 262px;
`;

const StakeBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  height: 84px;
  padding: 12px 20px 8px 20px;
  background: rgba(51, 255, 136, 0.1);
`;

const StakeBlockTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 18px;
  margin-bottom: 6px;
`;

const StakeBlockTitle = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: #9affc4;
  cursor: default;
`;

const MaxButton = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
  cursor: pointer;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const StakeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 36px;
  margin-bottom: 8px;
`;

const StakeInputBackground = styled.div`
  display: flex;
  flex-direction: row;
  width: 160px;
  height: 36px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const StakeInput = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 160px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #9affc4;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9affc4;
    opacity: 1; /* Firefox */
`;

const StakeButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.disabled ? "88px" : "86px")};
  height: ${(props) => (props.disabled ? "36px" : "34px")};
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  background: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border-radius: 2px;
  margin-bottom: 8px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
  }
`;

const BalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 16px;
`;

const BalanceValue = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #9affd0;
  cursor: default;
`;

const FarmForLP = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #9affd0;
  cursor: default;
`;

const FarmInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 242px;
  padding: 12px 20px 8px 20px;
  background: rgba(51, 255, 136, 0.1);
`;

const LpBalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 14px;
`;

const LpBalanceText = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #9affc4;
  cursor: default;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 40px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

const LpInputBackground = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 40px;
  margin: 0px 8px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const LpInput = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 118px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #9affc4;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9affc4;
    opacity: 1; /* Firefox */
`;

const LpMaxInputButton = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: 40px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
  cursor: pointer;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const RemoveLiquidityButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.disabled ? "256px" : "254px")};
  height: ${(props) => (props.disabled ? "36px" : "34px")};
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  font-family: Catamaran;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
  }
`;

const ApproveRemoveLiquidityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 256px;
  height: 36px;
  margin-top: 16px;
`;

const ApproveRemoveLiquidityButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 124px;
  height: 36px;
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  font-family: Catamaran;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border-radius: 2px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
  }
`;

const TokenNameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 16px;
  margin-top: 12px;
`;

const TokenNameValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 116px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
  cursor: default;
`;

const ValueBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 48px;
  margin-top: 4px;
`;

const ValueBackground = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 48px;
  background: rgba(0, 255, 216, 0.1);
  border-radius: 2px;
`;

const ValueBlockValue = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  color: #9affc4;
  cursor: default;
`;

const GoToFarmRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;
  height: 16px;
  margin-top: 8px;
`;

const GoToFarmButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #33ff88;
  cursor: pointer;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

export default Withdraw;
