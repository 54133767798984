const serializeNumber = (number: string | number) => {
  const stringify = number.toString();
  if (stringify.length < 2) {
    const filler = "000";
    return filler + stringify;
  }
  if (stringify.length < 3) {
    const filler = "00";
    return filler + stringify;
  }
  if (stringify.length < 4) {
    const filler = "0";
    return filler + stringify;
  }
  return stringify;
};

export default serializeNumber;
