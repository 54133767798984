import React, { useState, useEffect, useContext } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import styled from "styled-components";
import { Context } from "../context/ModalProvider";
import useModal from "../hooks/useModal";
import useTransaction from "../hooks/useTransaction";
import useWalletProvider from "../hooks/useWalletProvider";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { send } from "../utils/transactions";
import { switchToChain } from "../utils/web3";
import { unitToWei, weiToUnit } from "../utils/conversion";
import InfoModal from "../components/InfoModal";
import Scrollbar from "./../components/Scrollbar";
import Footer from "../containers/Footer/Footer";
import Welcome from "../containers/Bank/Welcome";
import BankHome from "../containers/Bank/BankHome";
import LiquiditySupply from "../containers/Bank/LiquiditySupply";
import Trade from "../containers/Bank/Trade";
import FarmOverview from "../containers/Bank/FarmOverview";
import DriveIn from "../containers/Bank/driveIn";
import MidTerm from "../containers/Bank/midTerm";
import FamFarm from "../containers/Bank/famFarm";
import Withdraw from "../containers/Bank/Withdraw";
import AtmBgMaterial from "./../assets/bank/ATM_BG_02.jpg";
import AtmBg from "./../assets/bank/ATM_BG.png";
import AtmBoxBg from "./../assets/bank/ATM_box_BG_02.jpg";
import famFarmCommercial from "./../assets/bank/famfarm.png";
import smallWindow from "./../assets/bank/small_window.png";
import bars from "./../assets/bank/bars.png";
import bankLogo from "./../assets/bank/bank_logo.png";
import bckCoin from "./../assets/bank/BCK.png";
import dot1 from "./../assets/bank/dot1.png";
import dot2 from "./../assets/bank/dot3.png";
import ftmCoin from "./../assets/logo/Fantom.png";
import teller from "./../assets/bank/Teller.jpg";
import guest from "./../assets/bank/guest.png";
import bckCrown from "./../assets/bank/BCK_small_green.png";
import EX_Logo from "./../assets/bank/EX_logo.png";
import MW_Logo from "./../assets/bank/MW_logo.png";
import FF_Logo from "./../assets/bank/FF_logo.png";
import config from "../config/config";
// @ts-ignore
import { abis } from "@space-tigers/contracts";

const Bank: React.FC<any> = (props) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const modalContext = useContext(Context);
  const [warning, setWarning] = useState(null);
  const [menu, setMenu] = useState("");
  const [subMenu, setSubMenu] = useState("");
  const [ftmBalance, setFtmBalance] = useState("0");
  const [bckBalance, setBckBalance] = useState("0");
  const [hovering, setHovering] = useState("");
  const [loadWeb3Modal] = useWeb3Modal();
  const [loginResult, setLoginResult] = useState("");
  const [loginClicked, setLoginClicked] = useState(false);
  const [lionIdNumber, setLionIdNumber] = useState("");
  const [lionImageAddress, setLionImageAddress] = useState("");
  const [totalPendingBck, setTotalPendingBck] = useState(BigNumber.from(0));
  const [driveInTvl, setDriveInTvl] = useState(0);
  const [midTermTvl, setMidTermTvl] = useState(0);
  const [famFarmTvl, setFamFarmTvl] = useState(0);
  const [driveInApr, setDriveInApr] = useState(0);
  const [midTermApr, setMidTermApr] = useState(0);
  const [famFarmApr, setFamFarmApr] = useState(0);
  const [ratioMid, setRatioMid] = useState(1);
  const [ratioFam, setRatioFam] = useState(1);
  const [animationActive, setAnimationActive] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [stakedValueInDriveIn, setStakedValueInDriveIn] = useState(0);
  const [stakedValueInMidTerm, setStakedValueInMidTerm] = useState(0);
  const [stakedValueInFamFarm, setStakedValueInFamFarm] = useState(0);
  const [txActive, setTxActive] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const rpc = new JsonRpcProvider(config.rpc);
  const [onPresentWrongChainSelected, onDismissWrongChainSelected] = useModal(
    <InfoModal
      message={`[web3] wrong network selected. Please change your network to Fantom ${
        parseInt(config.chainId) === 250 ? "Opera" : "Testnet"
      } to continue`}
      withCloseButton={false}
      handleActionButton={() =>
        switchToChain(
          walletContext.activeWallet.provider,
          parseInt(config.chainId)
        )
      }
      actionButtonText={`Switch to Fantom ${
        parseInt(config.chainId) === 250 ? "Opera" : "Testnet"
      }`}
    />,
    "metamask-wrong-network-modal",
    true
  );

  // TODO move chain check to other place (own hook preferably)
  // Present warning modal if wrong metamask account or network is selected
  useEffect(() => {
    if (
      walletContext.activeWallet.providerType === "metamask" &&
      walletContext.activeWallet.chainId !== parseInt(config.chainId)
    ) {
      return onPresentWrongChainSelected();
    }
    if (
      walletContext.activeWallet.chainId === parseInt(config.chainId) &&
      modalContext.modalKey === "metamask-wrong-network-modal"
    ) {
      onDismissWrongChainSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning, walletContext.activeWallet.chainId, modalContext.isOpen]);

  // const checkAmountNft = async (contract: string) => {
  //   const tokenContract = await walletContext.activeWallet.contracts.get(
  //     contract
  //   );
  //   const amountNft = await tokenContract
  //     .balanceOf(walletContext.activeWallet.address)
  //     .then((result: any) => (parseInt(result)));
  //     console.log(amountNft);
  //     return amountNft;
  // };

  const editTxStatus = async (active: boolean) => {
    setTxActive(active);
  };

  const getAllPendingBck = async () => {
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchefCall"
    );

    const getPendingBck0 = await masterChefContract.pendingBck(
      0,
      walletContext.activeWallet.address
    );
    const getPendingBck1 = await masterChefContract.pendingBck(
      1,
      walletContext.activeWallet.address
    );
    const getPendingBck2 = await masterChefContract.pendingBck(
      2,
      walletContext.activeWallet.address
    );

    const getTotalPendingBck = getPendingBck0
      .add(getPendingBck1)
      .add(getPendingBck2);
    setTotalPendingBck(getTotalPendingBck);
    // console.log(getTotalPendingBck);
    // console.log(weiToUnit(getTotalPendingBck));
  };

  const calculateAPR = async () => {
    const driveInContract = walletContext.activeWallet.contracts.get(
      "driveInCall"
    );
    const midTermContract = walletContext.activeWallet.contracts.get(
      "midTermCall"
    );
    const famFarmContract = walletContext.activeWallet.contracts.get(
      "famFarmCall"
    );
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchefCall"
    );
    const spookyLpContract = walletContext.activeWallet.contracts.get(
      "splpCall"
    );

    const getPendingBck0 = await masterChefContract.pendingBck(
      0,
      walletContext.activeWallet.address
    );
    const getPendingBck1 = await masterChefContract.pendingBck(
      1,
      walletContext.activeWallet.address
    );
    const getPendingBck2 = await masterChefContract.pendingBck(
      2,
      walletContext.activeWallet.address
    );
    const getTotalPendingBck = getPendingBck0
      .add(getPendingBck1)
      .add(getPendingBck2);
    setTotalPendingBck(getTotalPendingBck);

    const mainnetRpc = new JsonRpcProvider("https://rpc.ankr.com/fantom");
    const usdcFtmSpookyPairContract = new Contract(
      "0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c",
      abis.pair,
      mainnetRpc
    );
    const usdcFtmReserves = await usdcFtmSpookyPairContract.getReserves();
    const ftmPrize =
      usdcFtmReserves[0].toNumber() / 1000000 / weiToUnit(usdcFtmReserves[1]);
    // console.log(ftmPrize);
    // const rewarderReward = await rewarderContract.rewardPerSecond();
    const mchefReward = await masterChefContract.bckPerBlock();
    // console.log(weiToUnit(mchefReward));
    const poolPercentage = await masterChefContract.POOL_PERCENTAGE();
    const yearlySeconds = 31536000;
    const yearlyPoolTokens = mchefReward
      .mul(poolPercentage)
      .div(1000)
      .mul(yearlySeconds);
    // console.log(weiToUnit(yearlyPoolTokens));
    const totalAllocationPoints = await masterChefContract.totalAllocPoint();
    const allocationPointDriveIn = await masterChefContract
      .poolInfo(0)
      .then((result: any) => {
        return result.allocPoint;
      });
    const allocationPointMidTerm = await masterChefContract
      .poolInfo(1)
      .then((result: any) => {
        return result.allocPoint;
      });
    const allocationPointFamFarm = await masterChefContract
      .poolInfo(2)
      .then((result: any) => {
        return result.allocPoint;
      });
    const yearlyDistributionDriveIn = yearlyPoolTokens
      .mul(allocationPointDriveIn)
      .div(totalAllocationPoints);
    const yearlyDistributionMidTerm = yearlyPoolTokens
      .mul(allocationPointMidTerm)
      .div(totalAllocationPoints);
    const yearlyDistributionFamFarm = yearlyPoolTokens
      .mul(allocationPointFamFarm)
      .div(totalAllocationPoints);

    // console.log(weiToUnit(yearlyDistributionDriveIn));
    // console.log(weiToUnit(yearlyDistributionMidTerm));
    // console.log(weiToUnit(yearlyDistributionFamFarm));

    const getTotalLpInFamFarm = await spookyLpContract.balanceOf(
      famFarmContract.address
    );
    const getTotalFamFarmTokens = await famFarmContract.totalSupply();
    const ratioFam = getTotalLpInFamFarm.mul(10000).div(getTotalFamFarmTokens);

    setRatioFam(ratioFam.toNumber() / 10000);

    const getTotalLpInMidTerm = await spookyLpContract.balanceOf(
      midTermContract.address
    );
    const getTotalMidTermTokens = await midTermContract.totalSupply();
    const ratioMid = getTotalLpInMidTerm.mul(10000).div(getTotalMidTermTokens);

    setRatioMid(ratioMid.toNumber() / 10000);

    const totalSupply = await spookyLpContract.totalSupply();
    const reserves = await spookyLpContract.getReserves();
    const getBckPerLp = weiToUnit(reserves[1]) / weiToUnit(totalSupply);
    // console.log(getBckPerLp);
    const getFtmPerLp = weiToUnit(reserves[0]) / weiToUnit(totalSupply);
    // console.log(getFtmPerLp);

    const totalAmountLiquidityInDriveInFarm = await driveInContract
      .balanceOf(masterChefContract.address)
      .then((result: BigNumber) => {
        return weiToUnit(result);
      });
    const totalAmountLiquidityInMidTermFarm = await midTermContract
      .balanceOf(masterChefContract.address)
      .then((result: BigNumber) => {
        return weiToUnit(result);
      });
    const totalAmountLiquidityInFamFarmFarm = await famFarmContract
      .balanceOf(masterChefContract.address)
      .then((result: BigNumber) => {
        return weiToUnit(result);
      });

    // console.log(totalAmountLiquidityInFamFarmFarm);
    // console.log(ratioFam.toNumber() / 10000);
    // console.log(getFtmPerLp);
    // console.log(ftmPrize);

    const getDriveInTvl =
      totalAmountLiquidityInDriveInFarm * getFtmPerLp * 2 * ftmPrize;
    const getMidTermTvl =
      totalAmountLiquidityInMidTermFarm *
      (ratioMid.toNumber() / 10000) *
      getFtmPerLp *
      2 *
      ftmPrize;
    const getFamFarmTvl =
      totalAmountLiquidityInFamFarmFarm *
      (ratioFam.toNumber() / 10000) *
      getFtmPerLp *
      2 *
      ftmPrize;

    setDriveInTvl(Math.floor(getDriveInTvl));
    setMidTermTvl(Math.floor(getMidTermTvl));
    setFamFarmTvl(Math.floor(getFamFarmTvl));

    const totalBckInDriveInFarm = unitToWei(
      (totalAmountLiquidityInDriveInFarm * getBckPerLp * 2).toString()
    );
    const totalBckInMidTermFarm = unitToWei(
      (
        totalAmountLiquidityInMidTermFarm *
        (ratioMid.toNumber() / 10000) *
        getBckPerLp *
        2
      ).toString()
    );
    const totalBckInFamFarmFarm = unitToWei(
      (
        totalAmountLiquidityInFamFarmFarm *
        (ratioFam.toNumber() / 10000) *
        getBckPerLp *
        2
      ).toString()
    );

    const getDriveInApr =
      yearlyDistributionDriveIn
        .mul(10000)
        .div(totalBckInDriveInFarm)
        .toNumber() / 100;
    const getMidTermApr =
      yearlyDistributionMidTerm
        .mul(10000)
        .div(totalBckInMidTermFarm)
        .toNumber() / 100;
    const getFamFarmApr =
      yearlyDistributionFamFarm
        .mul(10000)
        .div(totalBckInFamFarmFarm)
        .toNumber() / 100;

    setDriveInApr(getDriveInApr);
    setMidTermApr(getMidTermApr);
    setFamFarmApr(getFamFarmApr);

    const userInfoFF = await masterChefContract
      .userInfo(2, walletContext.activeWallet.address)
      .then((result: any) => {
        return weiToUnit(result.amount);
      });
    const getStakedValueInFamFarm =
      userInfoFF * getFtmPerLp * (ratioFam.toNumber() / 10000) * 2 * ftmPrize;

    setStakedValueInFamFarm(getStakedValueInFamFarm);
    const userInfoMT = await masterChefContract
      .userInfo(1, walletContext.activeWallet.address)
      .then((result: any) => {
        return weiToUnit(result.amount);
      });
    const getStakedValueInMidTerm =
      userInfoMT * getFtmPerLp * (ratioMid.toNumber() / 10000) * 2 * ftmPrize;

    setStakedValueInMidTerm(getStakedValueInMidTerm);
    const userInfoDI = await masterChefContract
      .userInfo(0, walletContext.activeWallet.address)
      .then((result: any) => {
        return weiToUnit(result.amount);
      });
    const getStakedValueInDriveIn = userInfoDI * getFtmPerLp * 2 * ftmPrize;
    setStakedValueInDriveIn(getStakedValueInDriveIn);
  };

  const setBalances = async () => {
    const bckContract = walletContext.activeWallet.contracts.get("bckCall");
    const bckBalance = await bckContract.balanceOf(
      walletContext.activeWallet.address
    );
    const ftmBalance = await rpc.getBalance(walletContext.activeWallet.address);

    setFtmBalance(weiToUnit(ftmBalance).toFixed(2));
    setBckBalance(weiToUnit(bckBalance).toFixed(2));
    getAllPendingBck();
  };

  const setView = async (menu: string, subMenu: string) => {
    setMenu(menu);
    setSubMenu(subMenu);
  };

  const backButtonFunc = () => {
    if (subMenu === "") {
      return setMenu("landing");
    }
    if (subMenu !== "") {
      if (menu === "withdraw") {
        setSubMenu("");
        return setMenu("landing");
      }
      return setSubMenu("");
    }
  };

  const loginButtonFunc = async () => {
    const tokenContract = await walletContext.activeWallet.contracts.get(
      "bcklCall"
    );
    const amountNft = await tokenContract
      .balanceOf(walletContext.activeWallet.address)
      .then((result: any) => parseInt(result));
    if (amountNft > 0) {
      const tokenIndex = await tokenContract.tokenOfOwnerByIndex(
        walletContext.activeWallet.address,
        0
      );
      const data = await fetch(
        "https://heuristic-fermat-c9d2a8.netlify.app/api/attributes/" +
          tokenIndex
      )
        .then((res) => {
          return res.json();
        })
        .then((jsonResult) => {
          return jsonResult;
        });
      const smallLocalImage =
        "https://bigcatklub.com/assets/128/" + data.image.substring(34);
      setLionImageAddress(smallLocalImage);
      setLionIdNumber(data.id.toString());
      setLoginResult("approved");
      setSubMenu("");
      if (props.bankSubMenu === "") {
        setMenu("landing");
      } else {
        setMenu(props.bankSubMenu);
        props.updateBankMenu("");
      }
    } else {
      // setPlaceHolderLion
      setLionImageAddress("");
      setLionIdNumber("");
      setLoginResult("guest");
      setSubMenu("");
      if (props.bankSubMenu === "") {
        setMenu("landing");
      } else {
        setMenu(props.bankSubMenu);
        props.updateBankMenu("");
      }
    }
  };

  const harvestAll = async () => {
    const masterChefContract = walletContext.activeWallet.contracts.get(
      "mchef"
    );
    const hash = await send(
      walletContext.activeWallet.provider,
      () =>
        masterChefContract.harvestAll(
          [0, 1, 2],
          walletContext.activeWallet.address
        ),
      dispatchTx
    );
    console.log({ hash });
    setTxActive(true);
    return setTxHash(hash);
  };

  const startAnimation = async () => {
    setAnimationActive(true);
  };

  const handleAnimationEvent = async () => {
    setAnimationActive(false);
    setAnimationComplete(true);
  };

  useEffect(() => {
    setAnimationComplete(false);
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      setBalances();
      calculateAPR();
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (tx?.status === "completed") {
      setBalances();
      setTxActive(false);
    }
  }, [tx]);

  useEffect(() => {
    if (walletContext.activeWallet.chainId && loginClicked) {
      loginButtonFunc();
    }
    if (!walletContext.activeWallet.chainId && loginClicked) {
      loadWeb3Modal();
    }
  }, [
    walletContext.activeWallet.address,
    walletContext.activeWallet.chainId,
    loginClicked,
  ]);

  useEffect(() => {
    if (props.bankSubMenu !== "") {
      if (!walletContext.activeWallet.address) {
        loadWeb3Modal();
      } else {
        loginButtonFunc();
      }
    }
  }, [props.bankSubMenu, walletContext.activeWallet.address]);

  return (
    <Scrollbar style={{ width: "100%", marginBottom: "0px" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          height: "100%",
          backgroundColor: "#261B32",
        }}
      >
        <PlacementBox>
          <ATMBox>
            <ATMScreen>
              {menu === "" && <Welcome />}
              {menu !== "" && (
                <ATMContentBox>
                  <BalanceRow>
                    <SignalRow>
                      <Signal src={bars} />
                      {txActive && (
                        <Throbber
                          style={{
                            filter: "drop-shadow(0px 0px 10px #33ff88)",
                          }}
                          className="animate-flicker"
                          src={dot2}
                        />
                      )}
                      {!txActive && <Throbber src={dot1} />}
                    </SignalRow>
                    <Numbers>
                      <BalanceTexts style={{ marginRight: "16px" }}>
                        BALANCE:
                      </BalanceTexts>
                      <FtmLogo src={ftmCoin} />
                      <BalanceTexts>{ftmBalance}</BalanceTexts>
                      <BckLogo src={bckCoin} />
                      <BalanceTexts>{bckBalance}</BalanceTexts>
                    </Numbers>
                  </BalanceRow>
                  <TellerBox>
                    <TellerTextBlock>
                      {menu === "landing" && (
                        <TellerTextHeader>
                          WELCOME TO BIG BANK!
                        </TellerTextHeader>
                      )}
                      {menu === "trade" && (
                        <TellerTextHeader>TRADE</TellerTextHeader>
                      )}
                      {menu === "liquidity" && (
                        <TellerTextHeader>PROVIDE LIQUIDITY</TellerTextHeader>
                      )}
                      {menu === "farm" && subMenu === "" && (
                        <TellerTextHeader>FARM OVERVIEW</TellerTextHeader>
                      )}
                      {menu === "farm" && subMenu === "driveIn" && (
                        <TellerTextHeader>EXPRESS FARM</TellerTextHeader>
                      )}
                      {menu === "farm" && subMenu === "midTerm" && (
                        <TellerTextHeader>MIDWAY FARM</TellerTextHeader>
                      )}
                      {menu === "farm" && subMenu === "famFarm" && (
                        <TellerTextHeader>FAM FARM</TellerTextHeader>
                      )}
                      {menu === "withdraw" && (
                        <TellerTextHeader>WITHDRAW</TellerTextHeader>
                      )}
                      {menu === "landing" && (
                        <TellerTextDescription>
                          Here you can provide liquidity, invest in farms or
                          withdraw your profits. The world is your oyster, ser.{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            TRADE to get BCK &gt; PROVIDE LIQUIDITY with BCK to
                            get bckLP tokens &gt; ENTER your bckLP into farms
                            and FARM BCK tokens
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "trade" && (
                        <TellerTextDescription>
                          Here you can trade FTM/ BCK pair. If you have not
                          approved BCK token you need to do that before you are
                          able to trade. wFTM token will need approval as well.{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            Choosing MAX approve removes the need to approve a
                            token in the future. Recommended slippage 0.1 - 1 %.
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "liquidity" && (
                        <TellerTextDescription>
                          Here you can supply liquidity and get bckLP tokens for
                          it. Equal amount of FTM/BCK is needed. If you have not
                          approved BCK token then you need to do that before you
                          are able to supply liquidity.{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            Choosing "MAX APPROVE" removes the need to approve
                            BCK in the future.
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "farm" && subMenu === "" && (
                        <TellerTextDescription>
                          Pick a farm based on how long you want to have your
                          funds invested in it. Express Farm has no penalty on
                          exit and suitable for short period investors. Midway
                          Farms good for few months with -10% penalty and Family
                          Farm for the long haul with -20% penalty on exit.
                        </TellerTextDescription>
                      )}
                      {menu === "farm" && subMenu === "driveIn" && (
                        <TellerTextDescription>
                          Living in the fast lane and looking to get your
                          investments in and out quick? Express Farm was
                          established to serve the needs of the modern investors
                          with no penalty on exit!{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            SWAP bckLP for bckEX token &gt; STAKE bckEX tokens
                            to earn BCK &gt; Harvest BCK
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "farm" && subMenu === "midTerm" && (
                        <TellerTextDescription>
                          Midsized farm growing medium level crops. Even the
                          penalty for early exit is average -10%. If you are not
                          a person of extremes then this is the place for you
                          for sure!{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            SWAP bckLP for bckMW token &gt; STAKE bckMW tokens
                            to earn BCK &gt; Harvest BCK
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "farm" && subMenu === "famFarm" && (
                        <TellerTextDescription>
                          Official Farm of BCK operated by the Family. While the
                          gains are beautiful, one must take into account that
                          the Family does have a -20% penalty if you take your
                          investments out early.{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            SWAP bckLP for bckFF token &gt; STAKE bckFF tokens
                            to earn BCK &gt; Harvest BCK
                          </span>
                        </TellerTextDescription>
                      )}
                      {menu === "withdraw" && (
                        <TellerTextDescription>
                          Here you are able to withdraw all your investments
                          from one place. Pick a farm and the amount of
                          investments you want to remove. Applied penalties will
                          be distributed back to farms.{" "}
                          <span
                            style={{
                              color: "#00ffd8",
                            }}
                          >
                            UNSTAKE your farm tokens &gt; WITHDRAW farm tokens
                            into bckLP &gt; REMOVE LIQUIDITY
                          </span>
                        </TellerTextDescription>
                      )}
                    </TellerTextBlock>
                    <TellerPlaceHolder>
                      {subMenu === "" && <Teller src={teller} />}
                      {subMenu === "driveIn" && <FarmLogo src={EX_Logo} />}
                      {subMenu === "midTerm" && <FarmLogo src={MW_Logo} />}
                      {subMenu === "famFarm" && <FarmLogo src={FF_Logo} />}
                    </TellerPlaceHolder>
                  </TellerBox>

                  {menu === "landing" && <BankHome setView={setView} />}
                  {menu === "liquidity" && (
                    <LiquiditySupply
                      setView={setView}
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                    />
                  )}
                  {menu === "trade" && (
                    <Trade
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                    />
                  )}
                  {menu === "farm" && subMenu === "" && (
                    <FarmOverview
                      setView={setView}
                      setTopBalances={setBalances}
                      driveInApr={driveInApr}
                      midTermApr={midTermApr}
                      famFarmApr={famFarmApr}
                      driveInTvl={driveInTvl}
                      midTermTvl={midTermTvl}
                      famFarmTvl={famFarmTvl}
                      stakedValueInDriveIn={stakedValueInDriveIn}
                      stakedValueInMidTerm={stakedValueInMidTerm}
                      stakedValueInFamFarm={stakedValueInFamFarm}
                    />
                  )}
                  {menu === "farm" && subMenu === "driveIn" && (
                    <DriveIn
                      setView={setView}
                      driveInApr={driveInApr}
                      driveInTvl={driveInTvl}
                      ratio={1}
                      calculateAPR={calculateAPR}
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                    />
                  )}
                  {menu === "farm" && subMenu === "midTerm" && (
                    <MidTerm
                      setView={setView}
                      midTermApr={midTermApr}
                      midTermTvl={midTermTvl}
                      ratio={ratioMid}
                      calculateAPR={calculateAPR}
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                    />
                  )}
                  {menu === "farm" && subMenu === "famFarm" && (
                    <FamFarm
                      setView={setView}
                      famFarmApr={famFarmApr}
                      famFarmTvl={famFarmTvl}
                      ratio={ratioFam}
                      calculateAPR={calculateAPR}
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                    />
                  )}
                  {menu === "withdraw" && (
                    <Withdraw
                      setView={setView}
                      calculateAPR={calculateAPR}
                      setTopBalances={setBalances}
                      editTxStatus={editTxStatus}
                      subMenu={subMenu}
                    />
                  )}
                  <BottomRow>
                    <NavButtonsRow>
                      {menu !== "landing" && menu !== "" && (
                        <BackButton onClick={() => backButtonFunc()}>
                          &lt; BACK
                        </BackButton>
                      )}
                      {menu !== "landing" &&
                        menu !== "trade" &&
                        menu !== "" && (
                          <NavButton onClick={() => setView("trade", "")}>
                            TRADE
                          </NavButton>
                        )}
                      {menu !== "landing" &&
                        menu !== "liquidity" &&
                        menu !== "" && (
                          <NavButton onClick={() => setView("liquidity", "")}>
                            LIQUIDITY
                          </NavButton>
                        )}
                      {menu !== "landing" && menu !== "farm" && menu !== "" && (
                        <NavButton onClick={() => setView("farm", "")}>
                          FARM
                        </NavButton>
                      )}
                      {menu !== "landing" &&
                        menu !== "withdraw" &&
                        menu !== "" && (
                          <NavButton
                            onClick={() => setView("withdraw", "famFarm")}
                          >
                            WITHDRAW
                          </NavButton>
                        )}
                    </NavButtonsRow>
                    <BottomRightColumn>
                      <BottomRightCrown src={bckCrown} />
                      <BottomRightText>Smart ATM by Big Bank</BottomRightText>
                    </BottomRightColumn>
                  </BottomRow>
                </ATMContentBox>
              )}
            </ATMScreen>
            <SideMenu>
              <BankLogoBox>
                <BankLogo src={bankLogo} />
              </BankLogoBox>
              <InfoBox>
                <DarkBoxHarvest
                  disabled={!loginClicked || tx?.status === "pending"}
                  onMouseEnter={() => setHovering("harvestAll")}
                  onMouseLeave={() => setHovering("")}
                  onClick={() => harvestAll()}
                >
                  {loginClicked && (
                    <HarvestableAmount
                      disabled={
                        hovering === "harvestAll" && tx?.status === "pending"
                      }
                    >
                      {hovering === "harvestAll"
                        ? tx?.status === "pending"
                          ? "HARVESTING..."
                          : "HARVEST ALL"
                        : totalPendingBck
                        ? weiToUnit(totalPendingBck).toString().substring(0, 8)
                        : 0}
                    </HarvestableAmount>
                  )}
                </DarkBoxHarvest>
                <Commercial
                  src={famFarmCommercial}
                  disabled={
                    !walletContext.activeWallet.address || !loginClicked
                  }
                  onClick={() => setView("farm", "famFarm")}
                />
              </InfoBox>
              <InfoBox>
                <LoginHeader>LOGIN</LoginHeader>
                {!animationActive && !animationComplete && (
                  <DarkBox
                    style={{ cursor: "pointer" }}
                    onClick={() => setLoginClicked(true)}
                  >
                    {!animationActive && (
                      <LoginButtonText>CLICK HERE</LoginButtonText>
                    )}
                    {animationActive && (
                      <LoginButtonText>VERIFYING</LoginButtonText>
                    )}
                  </DarkBox>
                )}
                {animationActive && (
                  <DarkBox disabled={true} style={{ cursor: "cursor" }}>
                    <LoginButtonText>VERIFYING</LoginButtonText>
                  </DarkBox>
                )}
                {animationComplete && (
                  <DarkBox disabled={true} style={{ cursor: "cursor" }}>
                    <LoginButtonText>
                      {loginResult === "approved" ? "APPROVED" : "GUEST"}
                    </LoginButtonText>
                  </DarkBox>
                )}
                <LionImagePlaceholder>
                  {animationActive && (
                    <div
                      className="overlay"
                      onAnimationIteration={handleAnimationEvent}
                    ></div>
                  )}

                  {lionImageAddress !== "" && loginResult === "approved" && (
                    <LionImage
                      src={lionImageAddress}
                      animation={animationActive}
                      onLoad={() => startAnimation()}
                    />
                  )}
                  {lionImageAddress === "" && loginResult === "guest" && (
                    <LionImage
                      style={{ opacity: "0.1" }}
                      src={guest}
                      onLoad={() => handleAnimationEvent()}
                    />
                  )}
                  {animationComplete &&
                    lionIdNumber !== "" &&
                    loginResult === "approved" && (
                      <LionNumber>#{lionIdNumber}</LionNumber>
                    )}
                </LionImagePlaceholder>
              </InfoBox>
            </SideMenu>
          </ATMBox>
        </PlacementBox>
        <Footer />
      </div>
    </Scrollbar>
  );
};

const PlacementBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  margin-top: 96px;
  margin-bottom: 96px;
`;

const ATMBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 656px;
  width: 1024px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  background-image: url(${AtmBgMaterial});
  background-size: 1024px 656px;
`;

const ATMScreen = styled.div`
  display: flex;
  flex-direction: column;
  height: 592px;
  width: 768px;
  margin: 32px;
  border-radius: 8px;
  background-image: url(${AtmBg});
  background-size: 768px 592px;
`;

const ATMContentBox = styled.div`
  position: relative;
  height: 544px;
  width: 672px;
  margin: 24px 48px;
`;

const BalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-bottom: 16px;
  justify-content: space-between;
`;

const SignalRow = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Signal = styled.img`
  height: 24px;
  width: 24px;
`;

const Throbber = styled.img`
  height: 16px;
  width: 16px;
  margin-top: 2px;
  margin-left: 8px;
`;

const Numbers = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
`;

const BalanceTexts = styled.div`
  font-family: Catamaran;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 8px;
  color: #33ff88;
  text-shadow: 0px 0px 8px #33ff88;
  cursor: default;
`;

const FtmLogo = styled.img`
  height: 24px;
  widht: 24px;
  margin-left: 8px;
`;

const BckLogo = styled.img`
  height: 26.55px;
  widht: 26.55px;
  margin-left: 8px;
`;

const TellerBox = styled.div`
  background: rgba(51, 255, 136, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 672px;
  height: 120px;
  border-radius: 2px;
`;

const TellerTextBlock = styled.div`
  width: 504px;
  height: 100px;
  margin: 10px 24px;
  text-align: left;
`;

const TellerTextDescription = styled.div`
  font-family: Catamaran;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  cursor: default;
`;

const TellerTextHeader = styled.div`
  font-family: Catamaran;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  cursor: default;
`;

const TellerPlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background: rgba(51, 255, 136, 0.1);
`;

const Teller = styled.img`
  width: 120px;
  height: 120px;
  opacity: 0.7;
`;

const FarmLogo = styled.img`
  width: 60px;
  height: 60px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: 672px;
  margin-top: auto;
`;

const NavButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(51, 255, 136, 0.25);
  border-radius: 8px;
  padding: 8px 16px;
  font-family: Catamaran;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #33ff88;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &&:hover {
    background-color: rgba(51, 255, 136, 0.125);
    color: #9affc4;
  }
`;

const NavButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: rgba(51, 255, 136, 0.1);
  font-family: Catamaran;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &&:hover {
    background-color: rgba(51, 255, 136, 0.2);
    color: #33ff88;
  }
`;

const BottomRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const BottomRightCrown = styled.img`
  height: 24px;
  width: 24px;
`;

const BottomRightText = styled.div`
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #33ff88;
  opacity: 0.5;
  cursor: default;
`;

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 592px;
  width: 160px;
  margin-right: 32px;
`;
const BankLogoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 78px;
`;

const BankLogo = styled.img`
  height: 78px;
  width: 78px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 208px;
  width: 128px;
  padding: 16px;
  background-image: url(${AtmBoxBg});
  background-size: 160px 240px;
  border-radius: 8px;
  margin-top: 16px;
`;

const DarkBoxHarvest = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  height: 32px;
  width: 128px;
  background-color: #271b32;
  border-radius: 4px;
  margin-bottom: 8px;
  border: none;
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  font-size: 20px;
  line-height: 20px;
  transition: border 0.2s ease-in-out;

  &&:hover {
    font-size: 16px;
    line-height: 16px;
    border: ${(props) => (!props.disabled ? "1px solid #ffd864" : "none")};
  }
`;

const HarvestableAmount = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  color: rgba(255, 216, 100, 1);
  margin-top: 2px;
`;

const Commercial = styled.img<{
  disabled?: boolean;
}>`
  height: 168px;
  width: 128px;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const LoginHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 128px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const DarkBox = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
  width: 126px;
  background-color: rgba(39, 27, 50, 1);
  border: 1px solid #271b32;
  border-radius: 4px;
  margin-bottom: 8px;
  transition: border 0.3s ease-in-out;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  &&:hover {
    border: ${(props) =>
      props.disabled ? "1px solid #271b32" : "1px solid #33ff88"};
  }
`;

const LoginButtonText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #33ff88;
`;

const LionImagePlaceholder = styled.div`
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 4px;
  background-image: url(${smallWindow});
  background-size: 128px 128px;
`;

const LionImage = styled.img<{
  animation?: boolean;
}>`
  height: 128px;
  width: 128px;
  border-radius: 4px;
  opacity: ${(props) => (props.animation ? 0.1 : 0.5)};
`;

const LionNumber = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 8px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
`;

export default Bank;
