import { black, white } from "./colors";

const theme = {
  fontFamily: '"proxima-nova", sans-serif',
  borderRadius: 12,
  breakpoints: {
    mobile: 576,
    tablet: 768,
    desktop: 992,
    ultra: 1200,
  },
  fontColor: {
    primary: black,
    secondary: "#757575",
  },
  color: {
    white,
    black,
    primary: {
      first: (opacity: number = 1) => `#ffffff`, // #white
      second: (opacity: number = 1) => `#0c1727`, // #black
      third: (opacity: number = 1) => `#183557`, // #dark
      fourth: (opacity: number = 1) => `#FF6700`, // #orange
      fifth: (opacity: number = 1) => `#ffffff`,
      sixth: (opacity: number = 1) => `rgba(105, 226, 220, ${opacity})`,
    },
    greys: {
      first: (opacity: number = 1) => `#FAFAFB`,
      second: (opacity: number = 1) => `#EAEAEB`, // #B7BECB
      third: (opacity: number = 1) => `#EAEAEB`, // #B7BECB
      fourth: (opacity: number = 1) => `#9A9A9B`, // #202F49; // #707B8F
    },
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: "20vh",
};

export default theme;
