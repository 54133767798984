import React, { useState, useEffect } from "react";
import useWalletProvider from "../../hooks/useWalletProvider";
import styled from "styled-components";
import farmLogoEX from "./../../assets/bank/EX_logo.png";
import farmLogoMW from "./../../assets/bank/MW_logo.png";
import farmLogoFF from "./../../assets/bank/FF_logo.png";
import config from "../../config/config";

const FarmOverview: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();

  const numberWithDots = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithDotsAndDecimals = (x: number) => {
    return (
      Math.floor(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + x.toFixed(2).slice(-3)
    );
  };

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      props.setTopBalances();
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  return (
    <LiquidityColumn>
      <DescriptionRow>
        <DescriptionText>APR</DescriptionText>
        <DescriptionText style={{ marginRight: "-10px" }}>
          TVL<br></br>(USD)
        </DescriptionText>
        <DescriptionText>
          STAKED<br></br>(USD)
        </DescriptionText>
        <DescriptionText>PENALTY</DescriptionText>
      </DescriptionRow>
      <FarmRow>
        <NameRow>
          <FarmLogo src={farmLogoFF} />
          <FarmName>BIG CAT FAMILY FARM</FarmName>
        </NameRow>
        <FarmInfoRow>
          <ValueNumber>
            {numberWithDots(
              props.famFarmApr >= 1000
                ? Math.floor(props.famFarmApr)
                : props.famFarmApr
            )}
            %
          </ValueNumber>
          <ValueNumber>{numberWithDots(props.famFarmTvl)}</ValueNumber>
          <ValueNumber>
            {props.stakedValueInFamFarm >= 1000
              ? numberWithDots(Math.floor(props.stakedValueInFamFarm))
              : numberWithDotsAndDecimals(props.stakedValueInFamFarm)}
          </ValueNumber>
          <PercentageNumber>-20%</PercentageNumber>
          <EnterButton onClick={() => props.setView("farm", "famFarm")}>
            ENTER
          </EnterButton>
        </FarmInfoRow>
      </FarmRow>
      <FarmRow>
        <NameRow>
          <FarmLogo src={farmLogoMW} />
          <FarmName>MIDWAY FARM</FarmName>
        </NameRow>
        <FarmInfoRow>
          <ValueNumber>
            {numberWithDots(
              props.midTermApr >= 1000
                ? Math.floor(props.midTermApr)
                : props.midTermApr
            )}
            %
          </ValueNumber>
          <ValueNumber>{numberWithDots(props.midTermTvl)}</ValueNumber>
          <ValueNumber>
            {props.stakedValueInMidTerm >= 1000
              ? numberWithDots(Math.floor(props.stakedValueInMidTerm))
              : numberWithDotsAndDecimals(props.stakedValueInMidTerm)}
          </ValueNumber>
          <PercentageNumber>-10%</PercentageNumber>
          <EnterButton onClick={() => props.setView("farm", "midTerm")}>
            ENTER
          </EnterButton>
        </FarmInfoRow>
      </FarmRow>
      <FarmRow>
        <NameRow>
          <FarmLogo src={farmLogoEX} />
          <FarmName>EXPRESS FARM</FarmName>
        </NameRow>
        <FarmInfoRow>
          <ValueNumber>
            {numberWithDots(
              props.driveInApr >= 1000
                ? Math.floor(props.driveInApr)
                : props.driveInApr
            )}
            %
          </ValueNumber>
          <ValueNumber>{numberWithDots(props.driveInTvl)}</ValueNumber>
          <ValueNumber>
            {props.stakedValueInDriveIn >= 1000
              ? numberWithDots(Math.floor(props.stakedValueInDriveIn))
              : numberWithDotsAndDecimals(props.stakedValueInDriveIn)}
          </ValueNumber>
          <PercentageNumber>-0%</PercentageNumber>
          <EnterButton onClick={() => props.setView("farm", "driveIn")}>
            ENTER
          </EnterButton>
        </FarmInfoRow>
      </FarmRow>
    </LiquidityColumn>
  );
};

const LiquidityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 280px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 32px;
  background: rgba(51, 255, 136, 0.1);
  border-radius: 2px;
`;

const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 80px;
  margin-bottom: 18px;
`;

const DescriptionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 64px;
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #9affc4;
  cursor: default;
`;

const FarmRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 576px;
  height: 46px;
  padding: 14px 16px;
  background: rgba(51, 255, 136, 0.1);
  margin-bottom: 16px;
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const FarmLogo = styled.img`
  height: 24px;
  width: 24px;
`;
const FarmName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  font-family: Catamaran;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  margin-top: 2px;
  margin-left: 8px;
  cursor: default;
`;

const FarmInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const ValueNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 64px;
  height: 16px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #33ff88;
  cursor: default;
`;
const PercentageNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 64px;
  height: 16px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #00ffd8;
  cursor: default;
`;

const EnterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 34px;
  background-color: rgba(51, 255, 136, 0.15);
  border-radius: 2px;
  border: 1px solid rgba(51, 255, 136, 0.4);
  font-family: Catamaran;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #33ff88;
  cursor: pointer;
  box-shadow: none;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &&:hover {
    background: rgba(51, 255, 136, 0.25);
    box-shadow: 0px 0px 8px #33ff88;
  }
`;

export default FarmOverview;
