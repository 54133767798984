// eslint-disable-next-line import/no-anonymous-default-export

const configEth = {
  supportedChains: [1],
  // Opera chain id
  chainId: "0x1",
  // JSON-RPC endpoint
  rpc: "https://mainnet.infura.io/v3/fcf70945a451492cb8f10f988cc505c2",
  // used in links pointing to fantom explorer
  explorerUrl: "https://etherscan.io",
  // used in links pointing to fantom explorer's transaction detail
  explorerTransactionPath: "transactions",
};

if (process.env.REACT_APP_USE === "testnet") {
  console.warn("BCK app is using Sepolia TESTNET!");
  configEth.supportedChains = [11155111];
  configEth.chainId = "0xAA36A7";
  configEth.rpc =
    "https://sepolia.infura.io/v3/fcf70945a451492cb8f10f988cc505c2";
  configEth.explorerUrl = "https://sepolia.etherscan.io";
}

export default configEth;
