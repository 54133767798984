import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Row from "../../components/Row";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { send } from "../../utils/transactions";
import airdrop from "./../../assets/bank/airdrop.png";
import scissors from "./../../assets/img/icons/purfectImg.png";
import bckLogo from "./../../assets/logo/bck.png";
import logo222 from "./../../assets/mint222/222logo.png";
import logo111 from "./../../assets/ethmint/goldenFangsLogo.png";
import background111 from "../../assets/catoverview/BG_111.jpg";
import serializeNumber from "../../utils/serializeNumber";

const CatOverviewElement: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [loaded, setLoaded] = useState(false);
  const [editActive, setEditActive] = useState(true);
  const [hasClaimed, setHasClaimed] = useState(true);
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const claimAirdropFunc = async (nftId: number) => {
    const airdropContract = walletContext.activeWallet.contracts.get("airdrop");
    const hash = await send(
      walletContext.activeWallet.provider,
      () => airdropContract.claimAirdrop(nftId),
      dispatchTx
    );
    setTxHash(hash);
  };

  const claimStatus = async () => {
    const airdropContract = walletContext.activeWallet.contracts.get(
      "airdropCall"
    );
    const getHasClaimed = await airdropContract.hasClaimed(props.attributes.id);
    setHasClaimed(getHasClaimed);
  };

  const getEditActive = async () => {
    const mint222Contract = walletContext.activeWallet.contracts.get(
      "bck222Call"
    );
    const options: any = await fetch(
      "https://heuristic-fermat-c9d2a8.netlify.app/api/options/twohundredtwentytwo/" +
        props.attributes.id
    )
      .then((res) => {
        return res.json();
      })
      .then((jsonResult) => {
        return jsonResult;
      });
    const getRarity = await mint222Contract.getRarityNumber(
      props.attributes.id
    );

    // maybe edit in 420 setEditActive(false)
    // if (props.attributes.id === 420) {
    //   return setEditActive(false);
    // }
    if (getRarity.toNumber() === 6 && options.currentLeft !== "D") {
      return setEditActive(false);
    }
    if (getRarity.toNumber() < 3 && options.currentLeft !== "D") {
      return setEditActive(false);
    }
    if (options.currentRight !== "D" && options.currentLeft !== "D") {
      return setEditActive(false);
    }
  };

  useEffect(() => {
    if (props.series === "222") {
      getEditActive();
    }

    if (props.series === "genesis") {
      if (tx?.status === "pending") {
        props.claimingActive(true);
      }
      if (["completed", "failed"].includes(tx?.status)) {
        claimStatus();
        props.claimingActive(false);

        if (tx.status === "completed") {
          props.updateClaimable();
        }
      }
    }
  }, [tx]);

  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "center",
        marginBottom: "40px",
        height: "360px",
      }}
      key={props.keyValue}
    >
      <ImageContainer>
        {!hasClaimed && props.series === "genesis" && (
          <AirdropLink
            src={airdrop}
            onClick={() => claimAirdropFunc(props.attributes.id)}
            disabled={tx?.status === "pending" || props.claimAllActive}
          />
        )}

        {props.series === "222" && editActive && (
          <TailorLink
            src={scissors}
            onClick={() => props.openCustomize(props.attributes.id)}
            disabled={tx?.status === "pending" || props.claimAllActive}
          />
        )}
        <CatNumber>
          BIG CAT <span style={{ color: "white" }}>#{props.attributes.id}</span>
        </CatNumber>
        <BlurredImg
          image={
            props.series === "eth222"
              ? `https://${
                  process.env.REACT_APP_USE === "testnet"
                    ? "splendorous-figolla-904393.netlify.app"
                    : "bigcatklub.com/"
                }/assets/goldenfangs/128/bck${serializeNumber(
                  props.attributes.id
                )}.png`
              : "https://bigcatklub.com/assets/128/" +
                props.attributes.image?.substring(34)
          }
          loaded={loaded}
        ></BlurredImg>

        <StyledImg
          src={
            props.series === "eth222"
              ? `https://${
                  process.env.REACT_APP_USE === "testnet"
                    ? "splendorous-figolla-904393.netlify.app"
                    : "bigcatklub.com/"
                }/assets/goldenfangs/512/bck${serializeNumber(
                  props.attributes.id
                )}.png`
              : "https://bigcatklub.com/assets/512/" +
                props.attributes.image?.substring(34)
          }
          loaded={loaded}
          onLoad={() => setLoaded(true)}
        />
      </ImageContainer>

      <EmptyBox loaded={loaded}>
        <EmptyBox111BackGround />
        {props.series === "eth222" && <Logo111 src={logo111} />}
        {props.series === "222" && <Logo222 src={logo222} />}
        {props.series === "genesis" && <LogoBck src={bckLogo} />}
        <Row style={{ height: "30%", marginBottom: "5%" }}>
          {props.attributes.attributes.map((trait: any, index: any) => {
            if (index < 4) {
              return (
                <AttributeColumn>
                  <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                  <AttVal>{trait.value}</AttVal>
                  <RarityValue>
                    {(trait as any)?.frequency
                      ? "Rarity Score: " + (trait as any).frequency
                      : "Rarity Score: ?"}
                  </RarityValue>
                </AttributeColumn>
              );
            }
          })}
        </Row>
        <Row style={{ height: "30%", marginBottom: "5%" }}>
          {props.attributes.attributes.map((trait: any, index: any) => {
            if (index > 3 && index < 8) {
              return (
                <AttributeColumn>
                  <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                  <AttVal>{trait.value}</AttVal>
                  <RarityValue>
                    {(trait as any)?.frequency
                      ? "Rarity Score: " + (trait as any).frequency
                      : "Rarity Score: ?"}
                  </RarityValue>
                </AttributeColumn>
              );
            }
          })}
        </Row>
        <Row style={{ height: "30%" }}>
          {props.attributes.attributes.map((trait: any, index: any) => {
            const getColor = (value: string) => {
              if (value === "Common" || value === "?") return;
              if (value === "Uncommon") return "#00ef81";
              if (value === "Rare") return "#01d8ff";
              if (value === "Legendary") return "#ffe401";
              if (value === "Exotic") return "#f001ff";
              if (value === "Honorary") return "#f001ff";
              if (value === "Exotic/Honorary") return "#f001ff";
            };
            if (index >= 8) {
              return (
                <AttributeColumn>
                  <AttInd>{trait.trait_type.toUpperCase()}</AttInd>
                  <AttVal
                    style={{
                      color:
                        trait.trait_type === "Rarity Type" &&
                        getColor(trait.value),
                    }}
                  >
                    {trait.value}
                  </AttVal>
                  <RarityValue>
                    {(trait as any)?.frequency
                      ? "Rarity Score: " + (trait as any).frequency
                      : "Rarity Score: ?"}
                  </RarityValue>
                </AttributeColumn>
              );
            }
          })}
        </Row>
      </EmptyBox>
    </Row>
  );
};

const CatNumber = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
  z-index: 1001;
`;

const AirdropLink = styled.img<{
  disabled?: boolean;
}>`
  position: absolute;
  left: 12px;
  bottom: 12px;
  text-align: center;
  height: 50px;
  border: ${(props) =>
    props.disabled
      ? "2px solid rgba(249, 140, 31, 0.5)"
      : "2px solid rgba(249, 140, 31, 1)"};
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  z-index: 1001;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0px 0px 20px #f98c1f"};
  }
`;

const TailorLink = styled.img<{
  disabled?: boolean;
}>`
  position: absolute;
  left: 12px;
  bottom: 12px;
  text-align: center;
  height: 48px;
  padding: 1px;
  border: ${(props) =>
    props.disabled
      ? "2px solid rgba(249, 140, 31, 0.5)"
      : "2px solid rgba(249, 140, 31, 1)"};
  border-radius: 16px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  z-index: 1001;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0px 0px 20px #f98c1f"};
  }
`;

const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;
  width: 23%;
  margin-left: 2%;
`;

const AttInd = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #f98c1f;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-bottom: 2px;
`;

const AttVal = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
`;

const RarityValue = styled.div`
  font-family: "Catamaran", sans-serif;
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const EmptyBox = styled.div<{
  loaded: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45.5vw;
  height: 360px;
  border-radius: 16px;
  box-sizing: border-box;
  padding-top: 2.5vh;
  padding-bottom: 1.5vh;
  padding-left: 3.75vw;
  padding-right: 0.75vw;
  filter: ${(props) => (props.loaded ? "none" : "blur(4px)")};
  transition: filter 750ms ease-in-out;
`;

const EmptyBox111BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45.5vw;
  height: 360px;
  background-image: url(${background111});
  background-size: cover;
  border: 1px solid;
  border-radius: 16px;
  box-sizing: border-box;
  padding-left: 3.75vw;
  padding-right: 0.75vw;
  opacity: 0.1;
`;

const Logo111 = styled.img`
  position: absolute;
  top: 5%;
  left: 14vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22.5vw;
  height: 90%;
  border-radius: 16px;
  opacity: 0.075;
`;

const Logo222 = styled.img`
  position: absolute;
  top: 25%;
  left: 7.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35vw;
  height: 50%;
  border-radius: 16px;
  opacity: 0.1;
`;

const LogoBck = styled.img`
  position: absolute;
  top: 30%;
  left: 19.25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 40%;
  border-radius: 16px;
  opacity: 0.175;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 360px;
  height: 360px;
  margin-right: 40px;
`;

const StyledImg = styled.img<{
  loaded: boolean;
}>`
  width: 360px;
  height: 360px;
  border-radius: 16px;
  position: absolute;
  offset: 0;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 750ms ease-in-out;
  z-index: 100;
`;

const BlurredImg = styled.div<{
  image: string;
  loaded: boolean;
}>`
  width: 360px;
  height: 360px;
  border-radius: 16px;
  position: absolute;
  offset: 0;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  transition: opacity 750ms ease-in-out;
  z-index: 1000;
`;

export default CatOverviewElement;
