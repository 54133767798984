import React from "react";
import styled from "styled-components";
import bckCrown from "./../../assets/bank/BCK_small_green.png";
import tellerBg from "./../../assets/bank/Teller_no_bg.png";

const Welcome: React.FC<any> = (props: any) => {
  return (
    <BackgroundBox>
      <TextColumn>
        <WelcomeText>Welcome to BIG BANK Smart ATM!</WelcomeText>
        <LoginText>Login to access the services.</LoginText>
      </TextColumn>
      <BottomRightColumn>
        <BottomRightCrown src={bckCrown} />
        <BottomRightText>Smart ATM by Big Bank</BottomRightText>
      </BottomRightColumn>
    </BackgroundBox>
  );
};

const BackgroundBox = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${tellerBg});
  background-size: 620px 620px;
  background-repeat: no-repeat;
  opacity: 0.7;
  background-position: 140px 0px;
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 116px;
  margin-left: 48px;
`;

const WelcomeText = styled.div`
  display: flex;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  color: #33ff88;
  margin-bottom: 16px;
  cursor: default;
`;

const LoginText = styled.div`
  display: flex;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  cursor: default;
`;

const BottomRightColumn = styled.div`
  position: absolute;
  bottom: 24px;
  right: 48px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const BottomRightCrown = styled.img`
  height: 24px;
  width: 24px;
`;

const BottomRightText = styled.div`
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #33ff88;
  opacity: 0.7;
  cursor: default;
`;

export default Welcome;
