import { Web3Provider, JsonRpcProvider } from "@ethersproject/providers";
import { isAddress } from "@ethersproject/address";
import { Signer } from "@ethersproject/abstract-signer";
import { Contract } from "@ethersproject/contracts";

// @ts-ignore
import { addresses, abis } from "@space-tigers/contracts";
import config from "../config/config";
import configEth from "../config/configEth";

export const isValidAddress = (address: string): boolean => {
  return isAddress(address);
};

export const isSameAddress = (address1: string, address2: string): boolean => {
  if (!address1 || !address2) {
    return false;
  }
  return address1.toLowerCase() === address2.toLowerCase();
};

export const formatAddress = (address: string) => {
  return `${address.substr(0, 5)}...${address.substr(-5, 5)}`;
};

export const createWeb3Provider = (provider: any) => {
  return new Web3Provider(provider, "any");
};

export const createWalletContext = async (provider: Web3Provider) => {
  if (!provider) {
    console.error("provider missing");
    return;
  }

  const userNetwork = await provider.getNetwork();
  const chainId = userNetwork.chainId;
  const ethSupportedChainId = configEth.supportedChains[0];
  const ftmSupportedChainId = config.supportedChains[0];
  const accounts = await provider.listAccounts();
  const signer = provider.getSigner();
  const contracts = await loadContracts(
    signer,
    ethSupportedChainId,
    ftmSupportedChainId
  );

  return {
    contracts,
    chainId,
    address: accounts[0],
    provider,
    signer,
  };
};

export const loadContracts = async (
  signer: Signer,
  ethChainId: number,
  ftmChainId: number
) => {
  if (!signer) {
    return;
  }

  if (ftmChainId !== parseInt(config.chainId)) {
    return;
  }
  const rpc = new JsonRpcProvider(config.rpc);

  if (ethChainId !== parseInt(configEth.chainId)) {
    return;
  }
  const ethRpc = new JsonRpcProvider(configEth.rpc);

  return new Map([
    [
      "airdrop",
      new Contract(
        addresses[ftmChainId].contracts.airdrop,
        abis.airdrop,
        signer
      ),
    ],
    [
      "airdropCall",
      new Contract(addresses[ftmChainId].contracts.airdrop, abis.airdrop, rpc),
    ],
    [
      "bckl",
      new Contract(addresses[ftmChainId].tokens.BCKL, abis.bcklions, signer),
    ],
    [
      "bcklCall",
      new Contract(addresses[ftmChainId].tokens.BCKL, abis.bcklions, rpc),
    ],
    ["bck", new Contract(addresses[ftmChainId].tokens.BCK, abis.bck, signer)],
    ["bckCall", new Contract(addresses[ftmChainId].tokens.BCK, abis.bck, rpc)],

    [
      "bckFactory",
      new Contract(
        addresses[ftmChainId].contracts.bcklionfactory,
        abis.bcklionfactory.abi,
        signer
      ),
    ],
    [
      "bckFactoryCall",
      new Contract(
        addresses[ftmChainId].contracts.bcklionfactory,
        abis.bcklionfactory.abi,
        rpc
      ),
    ],
    [
      "bck222",
      new Contract(
        addresses[ftmChainId].contracts.bck222,
        abis.bck222.abi,
        signer
      ),
    ],
    [
      "bck222Call",
      new Contract(
        addresses[ftmChainId].contracts.bck222,
        abis.bck222.abi,
        rpc
      ),
    ],
    [
      "bckEthFactory",
      new Contract(
        addresses[ethChainId].contracts.ethfactory,
        abis.bckethfactory.abi,
        signer
      ),
    ],
    [
      "bckEthFactoryCall",
      new Contract(
        addresses[ethChainId].contracts.ethfactory,
        abis.bckethfactory.abi,
        ethRpc
      ),
    ],
    [
      "bckEth222Minter",
      new Contract(
        addresses[ethChainId].contracts.tigerminter,
        abis.bcketh222.abi,
        signer
      ),
    ],
    [
      "bckEth222MinterCall",
      new Contract(
        addresses[ethChainId].contracts.tigerminter,
        abis.bcketh222.abi,
        ethRpc
      ),
    ],
    [
      "bckWhitelist",
      new Contract(
        addresses[ftmChainId].contracts.whitelist,
        abis.whitelist.abi,
        signer
      ),
    ],
    [
      "bckWhitelistCall",
      new Contract(
        addresses[ftmChainId].contracts.whitelist,
        abis.whitelist.abi,
        rpc
      ),
    ],
    [
      "bckm",
      new Contract(
        addresses[ftmChainId].tokens.BCKM,
        abis.bckmocktoken.abi,
        signer
      ),
    ],
    [
      "bckmCall",
      new Contract(
        addresses[ftmChainId].tokens.BCKM,
        abis.bckmocktoken.abi,
        rpc
      ),
    ],
    [
      "driveIn",
      new Contract(addresses[ftmChainId].contracts.drivein, abis.xfarm, signer),
    ],
    [
      "driveInCall",
      new Contract(addresses[ftmChainId].contracts.drivein, abis.xfarm, rpc),
    ],
    [
      "midTerm",
      new Contract(addresses[ftmChainId].contracts.midterm, abis.xfarm, signer),
    ],
    [
      "midTermCall",
      new Contract(addresses[ftmChainId].contracts.midterm, abis.xfarm, rpc),
    ],
    [
      "famFarm",
      new Contract(addresses[ftmChainId].contracts.famfarm, abis.xfarm, signer),
    ],
    [
      "famFarmCall",
      new Contract(addresses[ftmChainId].contracts.famfarm, abis.xfarm, rpc),
    ],
    [
      "mchef",
      new Contract(addresses[ftmChainId].contracts.mchef, abis.mchef, signer),
    ],
    [
      "mchefCall",
      new Contract(addresses[ftmChainId].contracts.mchef, abis.mchef, rpc),
    ],
    [
      "spfac",
      new Contract(
        addresses[ftmChainId].contracts.spookyfactory,
        abis.spookyfactory,
        signer
      ),
    ],
    [
      "spfacCall",
      new Contract(
        addresses[ftmChainId].contracts.spookyfactory,
        abis.spookyfactory,
        rpc
      ),
    ],
    [
      "splp",
      new Contract(addresses[ftmChainId].contracts.splp, abis.pair, signer),
    ],
    [
      "splpCall",
      new Contract(addresses[ftmChainId].contracts.splp, abis.pair, rpc),
    ],
    [
      "sprout",
      new Contract(
        addresses[ftmChainId].contracts.spookyrouter,
        abis.spookyrouter,
        signer
      ),
    ],
    [
      "sproutCall",
      new Contract(
        addresses[ftmChainId].contracts.spookyrouter,
        abis.spookyrouter,
        rpc
      ),
    ],
    [
      "wftm",
      new Contract(addresses[ftmChainId].tokens.WFTM, abis.wftm, signer),
    ],
    [
      "wftmCall",
      new Contract(addresses[ftmChainId].tokens.WFTM, abis.wftm, rpc),
    ],
    [
      "xbck",
      new Contract(addresses[ftmChainId].contracts.xbck, abis.xbck, signer),
    ],
  ]);
};

export const loadERC20Contract = async (
  contractAddress: string,
  signer: Signer
) => {
  if (!signer) {
    return;
  }

  return new Contract(contractAddress, abis.erc20.abi, signer);
};

export const loadTestMintContract = async (
  contractAddress: string,
  signer: Signer
) => {
  if (!signer) {
    return;
  }

  return new Contract(contractAddress, abis.Testminust.abi, signer);
};
