import React, { useState } from "react";
import styled from "styled-components";

const BankHome: React.FC<any> = (props: any) => {
  return (
    <MenuLinkColumn>
      <MenuLink onClick={() => props.setView("trade", "")}>&gt; TRADE</MenuLink>
      <MenuLink onClick={() => props.setView("liquidity", "")}>
        &gt; PROVIDE LIQUIDITY
      </MenuLink>
      <MenuLink onClick={() => props.setView("farm", "")}>&gt; FARM</MenuLink>
      <MenuLink onClick={() => props.setView("withdraw", "famFarm")}>
        &gt; WITHDRAW
      </MenuLink>
    </MenuLinkColumn>
  );
};

const MenuLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 672px;
  height: 280px;
  margin-top: 48px;
  margin-bottom: 16px;
`;

const MenuLink = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(51, 255, 136, 0.85);
  margin-bottom: 8px;
  cursor: pointer;
  text-shadow: none;
  transition: text-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
    color: rgba(51, 255, 136, 1);
  }
`;

export default BankHome;
