import React from "react";
import styled from "styled-components";
import Check from "../../assets/ethmint/Check.png";
import Gear from "../../assets/ethmint/Gear.png";
import { useHistory } from "react-router-dom";

const InfoBlock: React.FC<any> = (props) => {
  const history = useHistory();
  const complete = () => {
    if (props.complete === 100) {
      return true;
    } else {
      return false;
    }
  };
  const isComplete = complete();

  return (
    <InfoBlockBox complete={isComplete}>
      <ImageBlock>
        <StyledImg src={props.img} />
      </ImageBlock>
      <InformationBlock style={{ width: "calc(70vw - 276px" }}>
        <UpperInfoBlock>
          {props.title && <Title>{props.title}</Title>}
          {props.info && (
            <Text>
              INFO:{" "}
              <span style={{ fontWeight: 500, opacity: 0.5 }}>
                {props.info}
              </span>
            </Text>
          )}
          {props.quantity && (
            <Text>
              QUANTITY:{" "}
              <span style={{ fontWeight: 500, opacity: 0.5 }}>
                {props.quantity}
              </span>
            </Text>
          )}

          {props.releaseDate && (
            <Text>
              RELEASE DATE:{" "}
              <span style={{ fontWeight: 500, opacity: 0.5 }}>
                {props.releaseDate}
              </span>
            </Text>
          )}
        </UpperInfoBlock>
        <UnderInfoBlock>
          <MarketPlaceLinksRow>
            {props.linkType !== "none" && (
              <Text>
                {props.linkType === "marketplace" ? "MARKETPLACE " : ""}LINKS:
              </Text>
            )}
            {props.linkOne && (
              <a
                href={props.linkOne[1]}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <LinkElement>{props.linkOne[0]}</LinkElement>
              </a>
            )}
            {props.linkTwo && <Text>|</Text>}
            {props.linkTwo && (
              <a
                href={props.linkTwo[1]}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <LinkElement>{props.linkTwo[0]}</LinkElement>
              </a>
            )}
            {props.internalLink && <Text>|</Text>}
            {props.internalLink && (
              <LinkElement
                style={{ cursor: "pointer" }}
                onClick={() => history.push(props.internalLink[1])}
              >
                {props.internalLink[0]}
              </LinkElement>
            )}
            {props.tokenLink && <Text>|</Text>}
            {props.tokenLink && (
              <LinkElement
                style={{ cursor: "pointer" }}
                onClick={() => props.tokenLink()}
              >
                TOKENPAGE
              </LinkElement>
            )}
          </MarketPlaceLinksRow>
          <Percentage complete={isComplete}>
            <img
              src={isComplete ? Check : Gear}
              alt={isComplete ? "Check" : "Gear"}
            />
            <div style={{ marginLeft: "8px" }}>{props.complete}%</div>
          </Percentage>
        </UnderInfoBlock>
      </InformationBlock>
    </InfoBlockBox>
  );
};

const LinkElement = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-left: 8px;
  margin-right: 8px;
  /* identical to box height, or 122% */
  color: #f98c1f;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s ease-in-out;
`;

const Text = styled.div`
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
`;

const ImageBlock = styled.div`
  height: 192px;
  width: 192px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 28px;
`;

const StyledImg = styled.img`
  width: 192px;
  border-radius: 8px;
`;

const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 192px;
`;

const UpperInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Title = styled.div`
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  /* identical to box height, or 100% */

  color: #01fd88;
  margin-bottom: 8px;
`;

const UnderInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MarketPlaceLinksRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const Percentage = styled.div<{
  complete: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) =>
    props.complete ? "#33FF88" : "rgba(255, 255, 255, 0.5)"};
`;

const InfoBlockBox = styled.div<{
  complete: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 248px;
  width: 70vw;
  margin-top: 16px;
  margin-bottom: 16px;
  background: rgba(64, 54, 74, 0.5);
  border: ${(props) =>
    props.complete ? "1px solid rgba(51, 255, 136, 0.5)" : "none"};
  border-radius: 8px;
`;

export default InfoBlock;
