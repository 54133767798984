import React, { useState, useEffect } from "react";
import { JsonRpcProvider } from "@ethersproject/providers";
import { unitToWei, weiToUnit } from "../../utils/conversion";
import { BigNumber } from "@ethersproject/bignumber";
import useWalletProvider from "../../hooks/useWalletProvider";
import useTransaction from "../../hooks/useTransaction";
import { send } from "../../utils/transactions";
import styled from "styled-components";
import bckCoin from "./../../assets/bank/BCK.png";
import ftmCoin from "./../../assets/logo/Fantom.png";
import config from "../../config/config";

const LiquiditySupply: React.FC<any> = (props: any) => {
  const { walletContext } = useWalletProvider();
  const { transaction, dispatchTx } = useTransaction();
  const [wftm, setWftm] = useState(false);
  const [bckBalance, setBckBalance] = useState(0);
  const [ftmBalance, setFtmBalance] = useState(0);
  const [wftmBalance, setWftmBalance] = useState(0);
  const [bckAllowance, setBckAllowance] = useState(unitToWei("0"));
  const [ftmAllowance, setFtmAllowance] = useState(unitToWei("0"));
  const [bckLiqAmount, setBckLiqAmount] = useState("");
  const [ftmLiqAmount, setFtmLiqAmount] = useState("");
  const [ftmToBckValue, setFtmToBckValue] = useState(0);
  const [bckToFtmValue, setBckToFtmValue] = useState(0);
  const [liquidityBalance, setLiquidityBalance] = useState(0);
  const [slippage, setSlippage] = useState("0.5");
  const [txHash, setTxHash] = useState(null);
  const tx = transaction[txHash];

  const rpc = new JsonRpcProvider(config.rpc);

  const calculateFtmLiquidity = async (amount: string) => {
    const pairContract = walletContext.activeWallet.contracts.get("splpCall");
    const reserves = await pairContract.getReserves();
    const weiAmount = unitToWei(amount);
    const multiplier = reserves[1].gte(reserves[0])
      ? reserves[1].mul(10000).div(reserves[0])
      : reserves[0].mul(10000).div(reserves[1]);
    const totalAmount = reserves[1].gte(reserves[0])
      ? weiAmount.div(multiplier)
      : weiAmount.mul(multiplier);
    const returnAmount = reserves[1].gte(reserves[0])
      ? totalAmount.mul(10000)
      : totalAmount.div(10000);
    return weiToUnit(returnAmount);
  };

  const calculateBckLiquidity = async (amount: string) => {
    const pairContract = walletContext.activeWallet.contracts.get("splpCall");
    const reserves = await pairContract.getReserves();
    const weiAmount = unitToWei(amount);
    const multiplier = reserves[1].gte(reserves[0])
      ? reserves[1].mul(10000).div(reserves[0])
      : reserves[0].mul(10000).div(reserves[1]);
    const totalAmount = reserves[1].gte(reserves[0])
      ? weiAmount.mul(multiplier)
      : weiAmount.div(multiplier);
    const returnAmount = reserves[1].gte(reserves[0])
      ? totalAmount.div(10000)
      : totalAmount.mul(10000);
    return weiToUnit(returnAmount);
  };

  const setOtherSide = async (amount: string, inputToken: string) => {
    if (inputToken === "ftm") {
      const bckReturn = await calculateBckLiquidity(amount);
      return setBckLiqAmount(bckReturn.toString());
    }
    if (inputToken === "bck") {
      const ftmReturn = await calculateFtmLiquidity(amount);
      return setFtmLiqAmount(ftmReturn.toString());
    }
  };

  const approveAddLiquidity = async (amount1: string, amount2: string) => {
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const amountADesired = unitToWei(amount1);
    const amountBDesired = unitToWei(amount2);

    if (wftm) {
      if (amountADesired.gt(ftmAllowance)) {
        const hash = await send(
          walletContext.activeWallet.provider,
          () => wftmContract.approve(routerContract.address, amountADesired),
          dispatchTx
        );
        props.editTxStatus(true);
        setTxHash(hash);
      }
    }
    if (amountBDesired.gt(bckAllowance)) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () => bckContract.approve(routerContract.address, amountBDesired),
        dispatchTx
      );
      props.editTxStatus(true);
      setTxHash(hash);
    }
  };

  const approveMaxLiquidity = async () => {
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const amountADesired = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );
    const amountBDesired = BigNumber.from(
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );

    if (wftm) {
      await wftmContract.approve(routerContract.address, amountADesired);
    }
    const hash = await send(
      walletContext.activeWallet.provider,
      () => bckContract.approve(routerContract.address, amountBDesired),
      dispatchTx
    );
    props.editTxStatus(true);
    setTxHash(hash);
  };

  const addLiquidity = async (amount1: string, amount2: string) => {
    if (amount1 === "" || amount1 === "0") {
      return;
    }
    const routerContract = walletContext.activeWallet.contracts.get("sprout");
    const bckContract = walletContext.activeWallet.contracts.get("bck");
    const wftmContract = walletContext.activeWallet.contracts.get("wftm");

    const slipUse = parseFloat(slippage ? slippage : "0") * 100;

    // const pairAddress = "0x91CB3c88f0f7d5BE8a00608171Fd52020748C9e9";
    const amountADesired = unitToWei(amount1);
    const amountBDesired = unitToWei(amount2);
    const amountAMinimum = unitToWei(amount1)
      .mul(10000 - slipUse)
      .div(10000);
    const amountBMinimum = unitToWei(amount2)
      .mul(10000 - slipUse)
      .div(10000);
    const to = walletContext.activeWallet.address;
    const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

    if (!wftm) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () =>
          routerContract.addLiquidityETH(
            bckContract.address,
            amountADesired,
            amountAMinimum,
            amountBMinimum,
            to,
            deadline,
            { value: amountBDesired }
          ),
        dispatchTx
      );
      props.editTxStatus(true);
      setTxHash(hash);
    }
    if (wftm) {
      const hash = await send(
        walletContext.activeWallet.provider,
        () =>
          routerContract.addLiquidity(
            wftmContract.address,
            bckContract.address,
            amountBDesired,
            amountADesired,
            amountBMinimum,
            amountAMinimum,
            to,
            deadline
          ),
        dispatchTx
      );
      props.editTxStatus(true);
      setTxHash(hash);
    }
    setBckLiqAmount("");
    setFtmLiqAmount("");
  };

  const setBalances = async () => {
    const bckContract = walletContext.activeWallet.contracts.get("bckCall");
    const ftmContract = walletContext.activeWallet.contracts.get("wftmCall");
    const pairContract = walletContext.activeWallet.contracts.get("splpCall");
    const routerContract = walletContext.activeWallet.contracts.get(
      "sproutCall"
    );

    const getBckBalance = await bckContract.balanceOf(
      walletContext.activeWallet.address
    );
    const getFtmBalance = await rpc.getBalance(
      walletContext.activeWallet.address
    );
    const getWftmBalance = await ftmContract.balanceOf(
      walletContext.activeWallet.address
    );
    const getLiquidityBalance = await pairContract.balanceOf(
      walletContext.activeWallet.address
    );

    const bckFtm = await calculateFtmLiquidity("1");
    const ftmBck = await calculateBckLiquidity("1");

    const getFtmAllowance = await ftmContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );
    const getBckAllowance = await bckContract.allowance(
      walletContext.activeWallet.address,
      routerContract.address
    );

    setFtmAllowance(getFtmAllowance);
    setBckAllowance(getBckAllowance);
    setBckToFtmValue(bckFtm);
    setFtmToBckValue(ftmBck);
    setFtmBalance(weiToUnit(getFtmBalance));
    setBckBalance(weiToUnit(getBckBalance));
    setWftmBalance(weiToUnit(getWftmBalance));
    setLiquidityBalance(weiToUnit(getLiquidityBalance));
  };

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (walletContext.activeWallet.address) {
      props.setTopBalances();
      setBalances();
    }
  }, [walletContext.activeWallet.address, walletContext.activeWallet.chainId]);

  useEffect(() => {
    if (!config.supportedChains.includes(walletContext.activeWallet.chainId)) {
      return;
    }
    if (tx?.status === "completed") {
      props.setTopBalances();
      props.editTxStatus(false);
      setBalances();
    }
  }, [tx]);

  return (
    <LiquidityColumn>
      <Header>provide liquidity</Header>
      <BoxRow>
        <Box>
          <TopRow>
            <TokenRow>
              <FtmLogo src={ftmCoin} />
              <TokenName>{wftm ? "w" : ""}FTM</TokenName>
            </TokenRow>
            <PercentageRow>
              <Percentages
                onClick={() => {
                  setFtmLiqAmount(
                    !wftm
                      ? (ftmBalance * 0.25).toString()
                      : (wftmBalance * 0.25).toString()
                  );
                  setOtherSide(
                    !wftm
                      ? (ftmBalance * 0.25).toString()
                      : (wftmBalance * 0.25).toString(),
                    "ftm"
                  );
                }}
              >
                25%
              </Percentages>
              <Percentages
                onClick={() => {
                  setFtmLiqAmount(
                    !wftm
                      ? (ftmBalance * 0.5).toString()
                      : (wftmBalance * 0.5).toString()
                  );
                  setOtherSide(
                    !wftm
                      ? (ftmBalance * 0.5).toString()
                      : (wftmBalance * 0.5).toString(),
                    "ftm"
                  );
                }}
              >
                50%
              </Percentages>
              <Percentages
                onClick={() => {
                  setFtmLiqAmount(
                    !wftm ? ftmBalance.toString() : wftmBalance.toString()
                  );
                  setOtherSide(
                    !wftm ? ftmBalance.toString() : wftmBalance.toString(),
                    "ftm"
                  );
                }}
              >
                MAX
              </Percentages>
            </PercentageRow>
          </TopRow>
          <InputBox>
            <AmountToken
              placeholder="0"
              value={ftmLiqAmount.substring(0, 9)}
              onChange={(event) => {
                setFtmLiqAmount(event.target.value.replace(",", "."));

                setOtherSide(event.target.value.replace(",", "."), "ftm");
              }}
            />
          </InputBox>
          <ToggleBalanceRow>
            <ToggleBox onClick={() => setWftm(!wftm)}>
              {!wftm ? "w" : ""}FTM
            </ToggleBox>
            <BalanceBox>
              BALANCE:{" "}
              {(wftm ? wftmBalance : ftmBalance).toString().substring(0, 9)}
            </BalanceBox>
          </ToggleBalanceRow>
        </Box>
        <Box>
          <TopRow>
            <TokenRow>
              <BckLogo src={bckCoin} />
              <TokenName>BCK</TokenName>
            </TokenRow>
            <PercentageRow>
              <Percentages
                onClick={() => {
                  setBckLiqAmount(
                    bckBalance ? (bckBalance * 0.25).toString() : "0"
                  );
                  setOtherSide((bckBalance * 0.25).toString(), "bck");
                }}
              >
                25%
              </Percentages>
              <Percentages
                onClick={() => {
                  setBckLiqAmount(
                    bckBalance ? (bckBalance * 0.5).toString() : "0"
                  );
                  setOtherSide((bckBalance * 0.5).toString(), "bck");
                }}
              >
                50%
              </Percentages>
              <Percentages
                onClick={() => {
                  setBckLiqAmount(bckBalance ? bckBalance.toString() : "0");
                  setOtherSide(bckBalance.toString(), "bck");
                }}
              >
                MAX
              </Percentages>
            </PercentageRow>
          </TopRow>
          <InputBox>
            <AmountToken
              placeholder="0"
              value={bckLiqAmount.substring(0, 9)}
              onChange={(event) => {
                setBckLiqAmount(event.target.value.replace(",", "."));

                setOtherSide(event.target.value.replace(",", "."), "bck");
              }}
            />
          </InputBox>
          <ToggleBalanceRow>
            <ToggleBox onClick={() => props.setView("trade", "")}>
              BUY
            </ToggleBox>
            <BalanceBox>
              BALANCE: {bckBalance.toString().substring(0, 9)}
            </BalanceBox>
          </ToggleBalanceRow>
        </Box>
      </BoxRow>
      <BottomRow>
        <PriceInfo>
          bckLP BALANCE = {liquidityBalance.toString().substring(0, 7)}
          <br />1 FTM = {ftmToBckValue.toString().substring(0, 7)} BCK
          <br />1 BCK = {bckToFtmValue.toString().substring(0, 7)} FTM
        </PriceInfo>
        {(!wftm || ftmAllowance.gte(unitToWei(ftmLiqAmount))) &&
          bckAllowance.gte(unitToWei(bckLiqAmount)) && (
            <ApproveColumn>
              <ApproveButton
                onClick={() => addLiquidity(bckLiqAmount, ftmLiqAmount)}
                disabled={
                  tx?.status === "pending" ||
                  parseFloat(bckLiqAmount) > bckBalance ||
                  (wftm
                    ? parseFloat(ftmLiqAmount) > wftmBalance
                    : parseFloat(ftmLiqAmount) > ftmBalance)
                }
              >
                SUPPLY{tx?.status === "pending" ? "ING..." : ""}
              </ApproveButton>
            </ApproveColumn>
          )}
        {((wftm && ftmAllowance.lt(unitToWei(ftmLiqAmount))) ||
          bckAllowance.lt(unitToWei(bckLiqAmount))) && (
          <ApproveColumn>
            <ApproveButton
              onClick={() => approveAddLiquidity(ftmLiqAmount, bckLiqAmount)}
              disabled={tx?.status === "pending"}
            >
              APPROV{tx?.status === "pending" ? "ING..." : "E"}
            </ApproveButton>
            <ApproveMax
              onClick={() => approveMaxLiquidity()}
              disabled={tx?.status === "pending"}
            >
              MAX
            </ApproveMax>
          </ApproveColumn>
        )}

        <SlippageRow>
          <SlippageText>Slippage tolerance</SlippageText>
          <SlippageInputBox>
            <SlippageInput
              value={slippage}
              onChange={(event) => {
                setSlippage(event.target.value.replace(",", "."));
              }}
            />
          </SlippageInputBox>
          <SlippageText>%</SlippageText>
        </SlippageRow>
      </BottomRow>
    </LiquidityColumn>
  );
};

const LiquidityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 280px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 32px;
  background: rgba(51, 255, 136, 0.1);
  border-radius: 2px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #9affc4;
  cursor: default;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 144px;
  margin-bottom: 24px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 232px;
  height: 120px;
  padding: 12px 16px 11px 16px;
  background: rgba(51, 255, 136, 0.1);
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;
`;

const TokenRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
`;

const FtmLogo = styled.img`
  height: 24px;
  widht: 24px;
  margin-right: 8px;
`;

const BckLogo = styled.img`
  height: 26.55px;
  widht: 26.55px;
  margin-right: 8px;
`;

const TokenName = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #9affc4;
  cursor: default;
`;

const PercentageRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
`;

const Percentages = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  text-transform: uppercase;
  color: #33ff88;
  cursor: pointer;
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 196px;
  padding: 18px;
  margin-bottom: 11px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const AmountToken = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 26px;
  width: 196px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  text-transform: uppercase;
  color: #9affc4;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9affc4;
    opacity: 1; /* Firefox */
  }
`;

const ToggleBalanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  color: #33ff88;
  cursor: pointer;
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

const BalanceBox = styled.div`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #9affd0;
  cursor: default;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9affc4;
  width: 210px;
  cursor: default;
`;

const SlippageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 14px;
  width: 210px;
`;

const SlippageText = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Catamaran;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
  color: #9affc4;
  cursor: default;
`;

const SlippageInputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 16px;
  margin: 0px 8px;
  background: rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(51, 255, 136, 0.25);
  border-radius: 4px;
`;

const SlippageInput = styled.input`
  width: 24px;
  height: 14px;
  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #33ff88;
`;

const ApproveColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApproveButton = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.disabled ? "48px" : "46px")};
  width: ${(props) => (props.disabled ? "208px" : "206px")};
  font-family: Catamaran;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  background-color: ${(props) =>
    props.disabled ? "rgba(51, 255, 136, 0.075)" : "rgba(51, 255, 136, 0.15)"};
  border: ${(props) =>
    !props.disabled ? "1px solid rgba(51, 255, 136, 0.4)" : "none"};
  border-radius: 2px;
  margin-bottom: 8px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &&:hover {
    box-shadow: 0px 0px 8px #33ff88;
    background-color: ${(props) =>
      props.disabled
        ? "rgba(51, 255, 136, 0.075)"
        : "rgba(51, 255, 136, 0.25)"};
`;

const ApproveMax = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  font-family: Catamaran;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => (props.disabled ? "#84c59f" : "#33ff88")};
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  text-shadow: none;
  transition: text-shadow 0.2s ease-in-out;
  &&:hover {
    text-shadow: 0px 0px 8px #33ff88;
  }
`;

export default LiquiditySupply;
